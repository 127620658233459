import React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import authheader from "../../services/authheader";
import { deleteRecord } from "../../util/deleteRecord";
import { fetchData } from "../../util/fetchData";
import { checkToken } from "../../services/checkToken";
import FetchDataComponent from "../../components/tables/fetchTableData";
import Pagination from "../../components/tables/ShowTableData";
import Breadcums from "../../common/Breadcums";

export default function AccountHeads() {
  try {
    checkToken();
  } catch (error) {
    alert("Session logged out.Please logged in again");
    localStorage.clear();
    // history.push('/');
    window.location.href = "/";
  }
  const headers = { Authorization: authheader().access_token };
  const [data, setData] = useState([]);
  const [errors, setErrors] = useState({});
  const [transaction, settransaction] = useState([]);
  const [formdata, setFormData] = useState({
    brand_name: "",
    description: "",
  });
  const handleChange = (e) => {
    setFormData({ ...formdata, [e.target.id]: e.target.value });
  };
  //handle add record
  const handleAddRecord = (newRecord) => {
    setData([...data, newRecord]);
    fetchData("accounthead", setData,"id");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formdata1 = e.target;
    axios
      .post(
        "/insertdata/accounthead",
        {
          head_name: formdata1.brand_name.value,
          credit: formdata1.ob.value,
          debit: '0',
          trans_type: formdata1.mode.value,
          remark:"Opening Balance Addedd"
          
        },
        { headers }
      )
      .then((res) => {
        fetchData("accounthead", setData, "id");

        const newRecord = res;
        handleAddRecord(newRecord);
        setFormData({
          //brand_name: "",
          ob: "",
         
        });
        //navigate("/");
      })
      .catch((err) => {
        console.log(err.message);
      });
    // handleAddRecord(formdata);
    setFormData({});
    setErrors({});
  };

  //Table data record
  // const [data, setData] = useState([]);
  const [itemsperpage, setitemsperpage] = useState([10]);
  const [columns, setColumns] = useState([]);

  const changeItem = (e) => {
    setitemsperpage(parseInt(e.target.value, 10));
    //console.log(itemsperpage.items);
  };
  const handleDelete = async (id) => {
    deleteRecord("accounthead", id, "id");
    fetchData("accounthead", setData, "id");
  };

  useEffect(() => {
  fetchData("transactions", settransaction, "id");
  }
)
  return (
    <>
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <Breadcums heading="Master" paglink="accounthead"/>
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              {/* left column */}
              <div className="col-md-6">
                {/* general form elements */}
                <div className="card card-dark">
                  <div className="card-header">
                    <h3 className="card-title">Account Head Creation</h3>
                  </div>
                  {/* /.card-header */}
                  {/* form start */}
                  <form onSubmit={handleSubmit}>
                    <div className="card-body">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Account Name</label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          id="brand_name"
                          onChange={(e) => handleChange(e)}
                          value={formdata.brand_name}
                          placeholder="Enter Name"
                        />
                      </div>
                      <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Payment Mode
                            </label>
                            <select
                              className="form-control form-control-sm select2"
                              id="mode"
                              onChange={(e) => handleChange(e)}
                              value={formdata.mode}
                            >
                              <option selected="NA">--Select--</option>
                              {transaction &&
                                transaction.map((seldata, index) => {
                                  return (
                                    <option value={seldata.mode}>
                                      {seldata.mode}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                     
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Opening Balance</label>
                        <input
                          type="number"
                          className="form-control form-control-sm"
                          id="ob"
                          onChange={(e) => handleChange(e)}
                          value={formdata.ob}
                          placeholder="Enter Name"
                        />
                      </div>
                    </div>
                    {/* /.card-body */}
                    <div className="card-footer">
                      <button type="submit" class="btn btn-dark float-right btn-sm">
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-md-6">
                {/* general form elements */}
                <div className="card card-dark">
                  <FetchDataComponent
                    tblname="accounthead"
                    setData={setData}
                    setColumns={setColumns}
                    id="id"
                  />

                  {/* This component fetches the data and determines columns */}
                  <Pagination
                    data={data}
                    columns={columns}
                    handleDelete={handleDelete}
                    itemsPerPage={itemsperpage}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}
    </>
  );
}
