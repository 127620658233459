
import { json } from "react-router-dom";
import axios from "axios";
import authheader from "../services/authheader";
import { checkToken } from "../services/checkToken";

export const getLastId = async (tblname, setData,max) => {
    checkToken();
    const headers = { Authorization: authheader().access_token };
    // try {
         axios
         .get("/lastid/"+tblname+"/"+max, { headers })
         .then((res) => {
          //  alert(val1);
            // console.log(res.data.data);
            const jsonData =res.data.data[0].orderno;
            console.log("id:"+jsonData);
            setData(jsonData);

         })
         .catch((err) => {
             console.error('Error fetching data:', err);
         });
   
   };
