import React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import authheader from "../../services/authheader";
import { deleteRecord } from "../../util/deleteRecord";
import { fetchData } from "../../util/fetchData";
import { fetchData_singlePara } from "../../util/fetchDataby_singlePara";
import { checkToken } from "../../services/checkToken";
import FetchDataComponent from "../../components/tables/fetchTableData";
import Pagination from "../../components/tables/ShowTableData";
import Breadcums from "../../common/Breadcums";
import Transport_bar from "../../common/Transport_bar";

export default function VehicleInOut() {
  try {
    checkToken();
  } catch (error) {
    alert("Session logged out.Please logged in again");
    localStorage.clear();
    // history.push('/');
    window.location.href = "/";
  }
  const headers = { Authorization: authheader().access_token };
  const [data, setData] = useState([]);
  const [errors, setErrors] = useState({});
  const [Reading, setReading] = useState(0);
  const [Selectdatagroup, setSelectDatagroup] = useState([]);
  const [Selectdatasubgroup, setSelectDatasubgroup] = useState([]);
  const [formdata, setFormData] = useState({
    company_name: "",
    customer_name: "",
    contact: "",
    street1: "",
    street2: "",
    city: "",
    pincode: "",
    gst: "",
    ob: "",
  });
  const handleChange = (e) => {
    setFormData({ ...formdata, [e.target.id]: e.target.value });
    cal();
  };
  //handle add record
  const handleAddRecord = (newRecord) => {
    setData([...data, newRecord]);
    fetchData("vehicle_logs", setData, "id");
  };
  
   const cal = (e) => {
       let startmeter1 = parseInt(formdata.startmeter);
       let endmeter1 = parseInt(formdata.endmeter);
       let totalkm= endmeter1-startmeter1;
       setReading(totalkm);

   };
  const handleSubmit = (e) => {
    e.preventDefault();
    const formdata1 = e.target;
    axios
      .post(
        "/insertdata/vehicle_logs",
        {
          log_date: formdata1.dte.value,
          vehicle: formdata1.vehicle.value,
          startmeter: formdata1.startmeter.value,
          loading: formdata1.loading.value,
          unloading: formdata1.unloading.value,
          endmeter: formdata1.endmeter.value,
          fuel: formdata1.fuel.value,
          km: formdata1.km.value,
          drivername: formdata1.drivername.value,
          work: formdata1.desc.value,
          
        },
        { headers }
      )
      .then((res) => {
        fetchData("vehicle_logs", setData, "id");

        const newRecord = res;
        handleAddRecord(newRecord);
        setFormData({
         
          log_date: "",
          vehicle: "",
          startmeter: "",
          loading: "",
          unloading: "",
          endmeter: "",
          fuel: "",
          km: "",
          drivername: "",
          work: "",
         
        });
        //navigate("/");
      })
      .catch((err) => {
        console.log(err.message);
      });
    // handleAddRecord(formdata);
    setFormData({});
    setErrors({});
  };

  //Table data record
  // const [data, setData] = useState([]);
  const [itemsperpage, setitemsperpage] = useState([10]);
  const [columns, setColumns] = useState([]);


  const handleDelete = async (id) => {
    deleteRecord("vehicle_logs", id, "id");
    fetchData("vehicle_logs", setData, "id");
  };
  useEffect(() => {
    fetchData("vehicles", setSelectDatagroup,"id");
    
  }, []);
  return (
    <>
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <Breadcums heading="Booking & Scheduling" paglink="Vehicle In Out"/>
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              {/* left column */}
              <div className="col-md-12">
                   <Transport_bar />
              </div>
              
            </div>
            <div className="row">
              {/* left column */}
              <div className="col-md-12">
                  <h5>Vehicle Log</h5>
              </div>
              
              
            </div>
            <div className="dropdown-divider" />
            <div className="row">
              {/* left column */}
              <div className="col-md-12">
              <form onSubmit={handleSubmit}>
                   
                      <div className="row">
                      <div className="col-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Date
                            </label>
                            <input
                              type="date"
                              className="form-control form-control-sm"
                              id="dte"
                              onChange={(e) => handleChange(e)}
                              value={formdata.dte}
                              
                            />
                          </div>
                        </div>
                      <div className="col-2">
                        <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Choose Vehicle</label>
                        <select 
                        className="form-control form-control-sm select2"
                        id="vehicle"
                        onChange={(e) => handleChange(e)}
                        value={formdata.vehicle}
                        >
                          
                        <option selected="NA">--Select--</option>
                        {Selectdatagroup &&
                          Selectdatagroup.map((seldata, index) => {
                            return (
                              <option value={seldata.regno}>{seldata.owner}({seldata.regno})</option>
                            );
                          })}
                  </select>
                      </div>
                        </div>
                       
                      <div className="col-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Start Meter Reading
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              id="startmeter"
                              onChange={(e) => handleChange(e)}
                              onKeyUp={(e) => cal(e)}
                              value={formdata.startmeter}
                              
                            />
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Loading Place
                            </label>
                            <input
                              type="text"
                              required
                              className="form-control form-control-sm"
                              id="loading"
                              onChange={(e) => handleChange(e)}
                              value={formdata.loading}
                              
                            />
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Unloading Place
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              id="unloading"
                              onChange={(e) => handleChange(e)}
                              value={formdata.unloading}
                              
                            />
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              End Mtr. Reading
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              id="endmeter"
                              onChange={(e) => handleChange(e)}
                              onKeyUp={(e) => cal(e)}
                              value={formdata.endmeter}
                              
                            />
                          </div>
                        </div>
                      
                       
                        
                        
                      </div>
                      <div className="row">
                      <div className="col-1">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Fuel
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              id="fuel"
                              onChange={(e) => handleChange(e)}
                              value={formdata.fuel}
                              
                            />
                          </div>
                        </div>
                        <div className="col-1">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                             KM
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              id="km"
                              onChange={(e) => handleChange(e)}
                              value={Reading}
                              
                            />
                          </div>
                        </div>
                      <div className="col-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Driver Name
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              id="drivername"
                              onChange={(e) => handleChange(e)}
                              value={formdata.drivername}
                              
                            />
                          </div>
                        </div>
                      <div className="col-6">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Description of Work
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              id="desc"
                              onChange={(e) => handleChange(e)}
                              value={formdata.desc}
                              
                            />
                          </div>
                        </div>

                        <div className="col-1">
                          <div className="form-group">
                          <button type="submit" class="btn mt-4 btn-dark float-right">
                        Save
                      </button>
                          </div>
                          </div>
                       
                      </div>
                   
                    {/* /.card-body */}
                    
                  </form>

                </div>
                </div>
            <div className="row">
              <div className="col-md-12">
                {/* general form elements */}
                <div className="card card-dark">
                  <FetchDataComponent
                    tblname="vehicle_logs"
                    setData={setData}
                    setColumns={setColumns}
                    id="id"
                  />

                  {/* This component fetches the data and determines columns */}
                  <Pagination
                    data={data}
                    columns={columns}
                    handleDelete={handleDelete}
                    itemsPerPage={itemsperpage}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}
    </>
  );
}
