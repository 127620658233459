import { BrowserRouter, Route, Routes } from 'react-router-dom'
import React, { useState } from 'react'

import Admin from './pages/dashboard/Admin'
import UnauthorizedPage from './unAuthorized'

import Login from './Login'
import Layout from './common/Layout'
import ProtectedRoute from './components/ProtectedRoutes'

import Groups from './pages/master/Groups'
import SubGroups from './pages/master/Subgroups'
import Heads from './pages/master/Heads'
import SubHeads from './pages/master/Subheads'
import AccountHeads from './pages/master/AccountHead'

import Category from './pages/products/Category'
import Subcategory from './pages/products/SubCategory'
import NewProduct from './pages/products/NewProduct'

import New_purchase from './pages/purchase/new_purchase'
import Inventory from './pages/purchase/Inventory'
import StockOut from './pages/purchase/Stockout'
import PurchaseDetails from './pages/purchase/PurchaseDetails'
import BelowStock from './pages/purchase/BelowStock'

import Qrcode from './pages/Qrcode'

import Payment from './pages/vouchers/payment'
import JournalReports from './pages/reports/JournalReports'
import Reciept from './pages/vouchers/reciept'
import Journal from './pages/vouchers/journal'
import IssueBalance from './pages/vouchers/IssueBalance'

import NewCustomer from './pages/Customers/NewCustomer'

import Newvendor from './pages/vendors/NewVendor'

import MakeInvoice from './pages/sale/MakeInvoice'
import InvoiceDetails from './pages/sale/InvoiceDetails'
import PrintInvoice from './pages/sale/PrintInvoice'

import Contracts from './pages/transport/Contracts'
import Viewcontracts from './pages/transport/Viewcontracts'
import VehicleManagement from './pages/transport/Vehicles_management'
import Scheduling from './pages/transport/Scheduling'
import Maintainence from './pages/transport/Maintanence'
import Availability from './pages/transport/Availability'
import VehicleInOut from './pages/transport/Vehicleinout'

import NewStaff from './pages/payroll/Newstaff'
import Labour from './pages/payroll/Labour'
import LabourAttendance from './pages/payroll/LabourAttendance'
import Advance from './pages/payroll/Advance'
import StaffSalary from './pages/payroll/Staff_salary'

import Expenses from './pages/reports/Expenses'
import Income from './pages/reports/Income'
import Salereport from './pages/reports/Salereport'
import MainAccount from './pages/reports/MainAccount'
import VehicleMileage from './pages/reports/VehicleMileage'
import CombinedDetails from './pages/reports/CombinedDetails'
import AcquitanceSummary from './pages/reports/Aquitance_Summary'
import Acquitance from './pages/reports/acquitance'


import Tax from './pages/settings/tax'
import Uom from './pages/settings/Uom'
import Companyinfo from './pages/settings/companyinfo'

import Newuser from './pages/users/NewUser'


function App () {
  const myStyle = {
    backgroundImage: "url('%public%/dist/img/bg.jpeg')",

    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  }
  return (
    <div class='wrapper' style={myStyle}>
      
        <Routes>
          <Route path='/' element={<Login />} />

          <Route path='/' element={<Layout />}>
            <Route path='/dashboard/admin' element= {<ProtectedRoute allowedRoles={[ 'admin', 'account']}>
                  <Admin />
                </ProtectedRoute>} />
            <Route
              path='/master/groups'
              element={
                <ProtectedRoute allowedRoles={['cashier', 'admin', 'account']}>
                  <Groups />
                </ProtectedRoute>
              }
            />

            {/* <Route path='/master/groups' element={<Groups />} /> */}
            <Route
              path='/master/subgroups'
              element={
                <ProtectedRoute allowedRoles={['admin', 'account']}>
                  <SubGroups />
                </ProtectedRoute>
              }
            />
            <Route
              path='/master/heads'
              element={
                <ProtectedRoute allowedRoles={['admin', 'account']}>
                  <Heads />
                </ProtectedRoute>
              }
            />
            <Route
              path='/master/subheads'
              element={
                <ProtectedRoute allowedRoles={['admin', 'account']}>
                  <SubHeads />
                </ProtectedRoute>
              }
            />
            <Route
              path='/master/acchead'
              element={
                <ProtectedRoute allowedRoles={['admin', 'account']}>
                  <AccountHeads />
                </ProtectedRoute>
              }
            />

            <Route path='/products/category' element={<Category />} />
            <Route path='/products/subcategory' element={<Subcategory />} />
            <Route path='/products/newproduct' element={<NewProduct />} />

            <Route
              path='/vouchers/payment'
              element={
                <ProtectedRoute allowedRoles={['admin', 'account']}>
                  <Payment />
                </ProtectedRoute>
              }
            />
            <Route path='/vouchers/reciept' element={<Reciept />} />
            <Route path='/vouchers/journal' element={<Journal />} />
            <Route path='/vouchers/issuebalance' element={<IssueBalance />} />

            <Route path='/purchase/newpurchase' element={<New_purchase />} />
            <Route path='/purchase/inventory' element={<Inventory />} />
            <Route path='/purchase/belowstock' element={<BelowStock />} />
            <Route path='/purchase/stockout' element={<StockOut />} />
            <Route
              path='/purchase/purchasedetails'
              element={<PurchaseDetails />}
            />

            <Route
              path='/sale/makeinvoice'
              element={<MakeInvoice />  }
            />
             <Route
              path='/sale/printinvoice/:invoiceNumber'
              element={<PrintInvoice />}
            />
            <Route
              path='/sale/invoicedetails'
              element={
                <ProtectedRoute allowedRoles={['admin', 'cashier']}>
                  <InvoiceDetails />
                </ProtectedRoute>
              }
            />

            <Route path='/customers/newcustomer' element={<NewCustomer />} />
            <Route path='/vendors/newvendor' element={<Newvendor />} />

            <Route path='/transports/newcontract' element={<Contracts />} />
            <Route
              path='/transports/viewcontract/:id'
              element={<Viewcontracts />}
            />
            <Route
              path='/transports/newvehicle'
              element={<VehicleManagement />}
            />
            <Route
              path='/transports/booking/scheduling'
              element={<Scheduling />}
            />
            <Route
              path='/transports/booking/maintainence'
              element={<Maintainence />}
            />
            <Route
              path='/transports/booking/availability'
              element={<Availability />}
            />
            <Route
              path='/transports/booking/vehicleinout'
              element={<VehicleInOut />}
            />

            <Route path='/payroll/newstaff' element={<NewStaff />} />
            <Route path='/payroll/labour' element={<Labour />} />
            <Route
              path='/payroll/labourattendance'
              element={<LabourAttendance />}
            />
            <Route path='/payroll/advance' element={<Advance />} />
            <Route path='/payroll/staffsalary' element={<StaffSalary />} />

            <Route path='/reports/expenses' element={<Expenses />} />
            <Route path='/reports/journal' element={<JournalReports />} />
            <Route path='/reports/income' element={<Income />} />
            <Route path='/reports/salereport' element={<Salereport />} />
            <Route path='/reports/combined' element={<CombinedDetails />} />

            <Route path='/reports/acquitance' element={<Acquitance />} />
            <Route path='/reports/acquitancesummary' element={<AcquitanceSummary />} />

            <Route path='/reports/mainaccount' element={<MainAccount />} />
            <Route path='/reports/mileage' element={<VehicleMileage />} />

            <Route path='/settings/newtax' element={<Tax />} />
            <Route path='/settings/unit' element={<Uom />} />
            <Route path='/settings/companyinfo' element={<Companyinfo />} />

            <Route path='/settings/qrcode' element={<Qrcode />} />

            <Route path='/users/newuser' element={<Newuser />} />
          </Route>
          <Route path='/unauthorized' element={<UnauthorizedPage />} />
        </Routes>
     
    </div>
  )
}

export default App
