import React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import authheader from "../../services/authheader";
import { deleteRecord } from "../../util/deleteRecord";
import { fetchData } from "../../util/fetchData";
import { fetchData_singlePara } from "../../util/fetchDataby_singlePara";
import { checkToken } from "../../services/checkToken";
import FetchDataComponent from "../../components/tables/fetchTableData";
import Pagination from "../../components/tables/ShowTableData";
import Breadcums from "../../common/Breadcums";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Labour() {
  try {
    checkToken();
  } catch (error) {
    alert("Session logged out.Please logged in again");
    localStorage.clear();
    // history.push('/');
    window.location.href = "/";
  }
  const headers = { Authorization: authheader().access_token };
  const [data, setData] = useState([]);
  const [errors, setErrors] = useState({});
  const [Selectdatagroup, setSelectDatagroup] = useState([]);
  const [JobTitle, setJobTitle] = useState([]);
  const [formdata, setFormData] = useState({
    first_name: "",
    last_name: "NA",
    date_of_birth: "1990-01-01",
    gender:"M",
    email: "labour@gmail.com",
    contact: "00",
    address: " Dummy address",
    city: "Rampur",
    state: "HP",
    pincode: "00",
    country: "IN",
    jobtile: "Labour",
    department: "Labour",
    hire_date: "2024-08-01",
    employment_status: "Active",
    salary: "0",
    payment_terms:"Monthly",
    bholder: "Test Bank",
    bname: "Test Bank",
    baccount: "00",
    ifsc: "ABC002",
  });
  const handleChange = (e) => {
    setFormData({ ...formdata, [e.target.id]: e.target.value });
  };
  //handle add record
  const handleAddRecord = (newRecord) => {
    setData([...data, newRecord]);
    fetchData("labour_profiles", setData, "id");
  };
  const handleChange1 = (e) => {
    // console.log(e.target.value);
    setFormData({ ...formdata, [e.target.id]: e.target.value });

   
    // console.log(setSelectDatagroup);
  };
  const handleSubmit = async(e) => {
    e.preventDefault();
    const formdata1 = e.target;
   const post1 = axios
      .post("/insertdata/labour_profiles",
        {
          first_name: formdata1.fname.value,
          last_name: "",
          date_of_birth: formdata1.dob.value,
          gender: formdata1.gender.value,
          email: formdata1.email.value,
          phone_number: formdata1.contact.value,
          address: formdata1.address.value,
          city: formdata1.city.value,
          state: formdata1.state.value,
          zip_code: formdata1.pincode.value,
          country: formdata1.country.value,
          job_title: formdata1.jobtile.value,
          department: formdata1.department.value,
          hire_date: formdata1.hire.value,
          employment_status: "Active",
          salary: formdata1.salary.value,
          payment_terms:formdata1.terms.value,
          bank_holder: formdata1.bholder.value,
          bank_name: formdata1.bname.value,
          bank_account_number: formdata1.baccount.value,
          ifsc: formdata1.ifsc.value,
        },
        { headers }
      );
      const post2 = axios
      .post(
        "/insertdata/subheads",
        {
          head_name: "Salary",
          subhead_name: formdata1.fname.value
         
        },
        { headers }
      );
      try {
        await Promise.all([post1,post2]);
        fetchData("labour_profiles", setData, "id");
        setFormData({
          first_name: "",
          last_name: "NA",
          date_of_birth: "1990-01-01",
          gender:"M",
          email: "labour@gmail.com",
          contact: "00",
          address: " Dummy address",
          city: "Rampur",
          state: "HP",
          pincode: "172001",
          country: "IN",
          jobtile: "Labour",
          department: "Labour",
          hire_date: "2024-08-01",
          employment_status: "Active",
          salary: "0",
          payment_terms:"Monthly",
          bholder: "Test Bank",
          bname: "Test Bank",
          baccount: "00",
          ifsc: "ABC002",
         
        });
        toast.success('Data saved successfully!');
      } catch (err) {
        // Handle errors
        //setError('An error occurred while making the POST requests');
        toast.error("An error occurred while making the POST requests");
        console.error("Error:", err);
      } finally {
      //  setLoading(false);
      }
     
    // handleAddRecord(formdata);
  
  };

  //Table data record

  const [itemsperpage, setitemsperpage] = useState([10]);
  const [columns, setColumns] = useState([]);

  const handleDelete = async (id) => {
    deleteRecord("labour_profiles", id, "id");
    fetchData("labour_profiles", setData, "id");
  };
  const location = useLocation();
  useEffect(() => {
   
    fetchData("contract_type", setSelectDatagroup, "id");
    fetchData("job_roles", setJobTitle, "id");
    
  }, []);
  return (
    <>
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <Breadcums heading="Payroll System-Labour Record" paglink="New Labour" />
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              {/* left column */}
              <div className="col-md-12">
                {/* general form elements */}

                <div className="card card-dark card-tabs">
                  <div className="card-header p-0 pt-1">
                    <ul
                      className="nav nav-tabs"
                      id="custom-tabs-one-tab"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          id="custom-tabs-one-home-tab"
                          data-toggle="pill"
                          href="#custom-tabs-one-home"
                          role="tab"
                          aria-controls="custom-tabs-one-home"
                          aria-selected="true"
                        >
                          Personal Details
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="custom-tabs-one-profile-tab"
                          data-toggle="pill"
                          href="#custom-tabs-one-profile"
                          role="tab"
                          aria-controls="custom-tabs-one-profile"
                          aria-selected="false"
                        >
                          Contact Details
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="custom-tabs-one-messages-tab"
                          data-toggle="pill"
                          href="#custom-tabs-one-messages"
                          role="tab"
                          aria-controls="custom-tabs-one-messages"
                          aria-selected="false"
                        >
                          Job Description 
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="custom-tabs-one-settings-tab"
                          data-toggle="pill"
                          href="#custom-tabs-one-settings"
                          role="tab"
                          aria-controls="custom-tabs-one-settings"
                          aria-selected="false"
                        >
                          Bank Details
                        </a>
                      </li>
                    </ul>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div className="card-body">
                      <div
                        className="tab-content"
                        id="custom-tabs-one-tabContent"
                      >
                        <div
                          className="tab-pane fade show active"
                          id="custom-tabs-one-home"
                          role="tabpanel"
                          aria-labelledby="custom-tabs-one-home-tab"
                        >
                          <div className="row">
                            <div className="col-2">
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Full Name</label>
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  id="fname"
                                  onChange={(e) => handleChange(e)}
                                  value={formdata.fname}
                                />
                              </div>
                            </div>
                           
                            <div className="col-2">
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail1">
                                  D.O.B
                                </label>
                                <input
                                  type="date"
                                  className="form-control form-control-sm"
                                  id="dob"
                                  required
                                  onChange={(e) => handleChange(e)}
                                  value={formdata.dob}
                                />
                              </div>
                            </div>
                            <div className="col-3">
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail1">
                                  Gender
                                </label>
                                <select
                          className="form-control form-control-sm select2"
                          id="gender"
                          onChange={(e) => handleChange(e)}
                          value={formdata.gender}
                        >
                          <option selected value="M">M</option>
                          <option  value="F">F</option>
                          </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="custom-tabs-one-profile"
                          role="tabpanel"
                          aria-labelledby="custom-tabs-one-profile-tab"
                        >
                          <div className="row">
                            <div className="col-2">
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail1">
                                  Email
                                </label>
                                <input
                                  type="email"
                                  className="form-control form-control-sm"
                                  id="email"
                                  onChange={(e) => handleChange(e)}
                                  value={formdata.email}
                                />
                              </div>
                            </div>
                            <div className="col-2">
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail1">
                                  Contact
                                </label>

                                <input
                                  type="number"
                                  className="form-control form-control-sm datetimepicker-input"
                                  id="contact"
                                  onChange={(e) => handleChange(e)}
                                  value={formdata.contact}
                                />
                              </div>
                            </div>
                            <div className="col-3">
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail1">
                                Address
                                </label>
                                <input
                                  type="text"
                                  className="form-control form-control-sm "
                                  id="address"
                                  onChange={(e) => handleChange(e)}
                                  value={formdata.address}
                                />

                              </div>
                            </div>
                            <div className="col-1">
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail1">
                                City
                                </label>
                                <input
                                  type="text"
                                  className="form-control form-control-sm "
                                  id="city"
                                  onChange={(e) => handleChange(e)}
                                  value={formdata.city}
                                />

                              </div>
                            </div>
                            <div className="col-1">
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail1">
                                State
                                </label>
                                <input
                                  type="text"
                                  className="form-control form-control-sm "
                                  id="state"
                                  onChange={(e) => handleChange(e)}
                                  value={formdata.state}
                                />

                              </div>
                            </div>
                            <div className="col-1">
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail1">
                                Pin Code
                                </label>
                                <input
                                  type="text"
                                  className="form-control form-control-sm "
                                  id="pincode"
                                  onChange={(e) => handleChange(e)}
                                  value={formdata.pincode}
                                />

                              </div>
                            </div>
                            <div className="col-1">
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail1">
                                Country
                                </label>
                                <input
                                  type="text"
                                  className="form-control form-control-sm "
                                  id="country"
                                  onChange={(e) => handleChange(e)}
                                  value={formdata.country}
                                />

                              </div>
                            </div>
                            


                          </div>
                      
                        </div>
                        <div
                          className="tab-pane fade"
                          id="custom-tabs-one-messages"
                          role="tabpanel"
                          aria-labelledby="custom-tabs-one-messages-tab"
                        >
                      <div className="row">
                            <div className="col-2">
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Job Title</label>
                                <select 
                          className="form-control form-control-sm select2"
                          id="jobtile"
                          required
                          onChange={(e) => handleChange1(e)}
                          value={formdata.jobtile}
                          >
                            
                          <option selected="NA">--Select--</option>
                          {JobTitle &&
                            JobTitle.map((seldata, index) => {
                              return (
                                <option value={seldata.job_title}>{seldata.job_title}</option>
                              );
                            })}
                    </select>

                              </div>
                            </div>
                            <div className="col-2">
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail1">
                                  Department
                                </label>
                                <select 
                          className="form-control form-control-sm select2"
                          id="department"
                          required
                          onChange={(e) => handleChange1(e)}
                          value={formdata.department}
                          >
                            
                          <option selected="NA">--Select--</option>
                          {JobTitle &&
                            JobTitle.map((seldata, index) => {
                              return (
                                <option value={seldata.department}>{seldata.department}</option>
                              );
                            })}
                    </select>
                              </div>
                            </div>
                            <div className="col-2">
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail1">
                                  Hire Date
                                </label>
                                <input
                                  type="date"
                                  className="form-control form-control-sm"
                                  id="hire"
                                  onChange={(e) => handleChange(e)}
                                  value={formdata.hire}
                                />
                              </div>
                            </div>
                            <div className="col-2">
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail1">
                                  Wages per Day
                                </label>
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  id="salary"
                                  onChange={(e) => handleChange(e)}
                                  value={formdata.salary}
                                />
                              </div>
                            </div>
                            <div className="col-2">
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail1">
                                Payment Terms
                                </label>
                                <select 
                          className="form-control select2"
                          id="terms"
                          onChange={(e) => handleChange(e)}
                          value={formdata.terms}
                          >
                            
                          <option selected value="NA">--Select--</option>
                          <option value="Daily">Daily</option>
                          <option value="Monthly">Monthly</option>
                          <option value="Quarterly">Quarterly</option>
                          <option value="Half_Yearly">Half Yearly</option>
                          <option value="Yearly"> Yearly</option>
                      
                    </select>

                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="custom-tabs-one-settings"
                          role="tabpanel"
                          aria-labelledby="custom-tabs-one-settings-tab"
                        >
                          <div className="row">
                            <div className="col-3">
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail1"> Bank Holder Name</label>
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  id="bholder"
                                  onChange={(e) => handleChange(e)}
                                  value={formdata.bholder}
                                />
                              </div>
                            </div>
                            <div className="col-3">
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail1"> Bank Name</label>
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  id="bname"
                                  onChange={(e) => handleChange(e)}
                                  value={formdata.bname}
                                />
                              </div>
                            </div>
                            <div className="col-2">
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail1">
                                  Account No.
                                </label>
                                <input
                                  type="number"
                                  className="form-control form-control-sm"
                                  id="baccount"
                                  onChange={(e) => handleChange(e)}
                                  value={formdata.baccount}
                                />
                              </div>
                            </div>
                            <div className="col-2">
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail1">
                                  IFSC
                                </label>
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  id="ifsc"
                                  onChange={(e) => handleChange(e)}
                                  value={formdata.ifsc}
                                />
                              </div>
                            </div>
                            <div className="col-2">
                              <div className="form-group">
                              <button type="submit" class="btn mt-4 btn-dark float-right">
                          Save Data
                        </button>
                        <ToastContainer/>
                              </div>
                            </div>
                          
                          </div>
                        </div>
                      </div>
                    </div>
                </form>
                  {/* /.card */}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                {/* general form elements */}
                <div className="card card-dark">
                  <FetchDataComponent
                    tblname="labour_profiles"
                    setData={setData}
                    setColumns={setColumns}
                    id="id"
                  />

                  {/* This component fetches the data and determines columns */}
                  <Pagination
                    data={data}
                    columns={columns}
                    handleDelete={handleDelete}
                    itemsPerPage={itemsperpage}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}
    </>
  );
}
