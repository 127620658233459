
import {  } from "react-router-dom";
import axios from "axios";
import authheader from "../services/authheader";
import { checkToken } from "../services/checkToken";

export const acquitance_byname = async (tblname, productdatachange1,col1,val1,groupby) => {
    checkToken();
    const headers = { Authorization: authheader().access_token };
    // try {
         axios
         .get("/acqfilterdatabyname/"+tblname+"/"+col1+"/"+val1+"/"+groupby, { headers })
         .then((res) => {
           // alert("fetch data1");
            //console.log(res.data.data);
            const jsonData =res.data.data;
           // console.log(jsonData);
            productdatachange1(jsonData);

         })
         .catch((err) => {
             console.error('Error fetching data:', err);
         });
   
   };
