import React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import authheader from "../../services/authheader";
import { deleteRecord } from "../../util/deleteRecord";
import { fetchData } from "../../util/fetchData";
import { fetchcombolist } from "../../util/fetch_combolist";
import { fetchData_singlePara } from "../../util/fetchDataby_singlePara";
import { fetchData_twoPara } from "../../util/filterDataby_twoparam";
import { filterdatabydate } from "../../util/filterdatabydate";
import { filterdatabydatename } from "../../util/filterbydatename";
import { account_head_ledger_summary } from "../../util/account_head_ledgersummary";
import { account_head_ledgerSummary_2param } from "../../util/account_head_ledger_summary2param";
import { account_head_ledgerSummarybydatename } from "../../util/account_head_ledgersummarybydatename";
import { ledgerSummarybydate } from "../../util/ledgersummarybydate";
import { checkToken } from "../../services/checkToken";
import FetchDataComponent from "../../components/tables/fetchTableData";
import ShowLedgerReports from "../../components/tables/ShowLedgerReports1";

import Breadcums from "../../common/Breadcums";

export default function MainAccount() {
  try {
    checkToken();
  } catch (error) {
    alert("Session logged out.Please logged in again");
    localStorage.clear();
    // history.push('/');
    window.location.href = "/";
  }
  const headers = { Authorization: authheader().access_token };
  const [data, setData] = useState([]);
  const [errors, setErrors] = useState({});
  const [VendorName, setVendorname] = useState([]);
  const [labourName, setlabourname] = useState([]);
  const [myname, setmyname] = useState([]);
  const [SelectDatagroup, setSelectDatagroup] = useState([]);
  
  const [SummaryData, setSummaryData] = useState([]);
  const [Acchead, setAcchead] = useState([])
  const [transaction, settransaction] = useState([]);
  const [formdata, setFormData] = useState({
    company_name: "",
    customer_name: "",
    contact: "",
    street1: "",
    street2: "",
    city: "",
    pincode: "",
    gst: "",
    ob: "",
  });
  const handleChange = (e) => {
    setFormData({ ...formdata, [e.target.id]: e.target.value });
  };
  
  const filterbydate = (e) => {
    e.preventDefault();
    const formdata1 = e.target;
    //alert(formdata.frm);
   
    filterdatabydate("expenses", setData, "date",formdata.frm,"date",formdata.to, "id");
    ledgerSummarybydate(
      "expenses",
      setSummaryData,
      "date",
      formdata.frm,
      "date",
      formdata.to,
      "credit",
      "debit",
      "tax",
      "disc",
    )
  
  };
  const filterbydatename = (e) => {
    e.preventDefault();
    
    filterdatabydatename("accounthead", setData, "date",formdata.frm,"date",formdata.to,"trans_type",formdata.imode, "id");
    account_head_ledgerSummarybydatename(
      "accounthead",
      setSummaryData,
      "date",
      formdata.frm,
      "date",
      formdata.to,
      "trans_type",
      formdata.imode,
      "credit",
      "debit",
      
    )
  
  };
  
  const handleChange1 = (e) => {
    setFormData({ ...formdata, [e.target.id]: e.target.value });
    setmyname(e.target.value);
    fetchData_singlePara("expenses", setData, "subhead", e.target.value, "id");
    account_head_ledger_summary(
      "expenses",
      setSummaryData,
      "subhead",
      e.target.value,
      "credit",
      "debit",
      "tax",
      "disc",
      "id"
    );
  };
  const handleChange2 = (e) => {
    setFormData({ ...formdata, [e.target.id]: e.target.value });
    setmyname(e.target.value);
    fetchData_singlePara("accounthead", setData, "head_name", e.target.value, "id");
    account_head_ledger_summary(
      "accounthead",
      setSummaryData,
      "head_name",
      e.target.value,
      "credit",
      "debit",
      
      "id"
    );
  };
  const changewith_head_mode = (e) => {
    //setFormData({ ...formdata, [e.target.id]: e.target.value });
  // alert(formdata.imode);
  // alert(formdata.account_name);
    fetchData_twoPara("accounthead", setData, "head_name",formdata.account_name ,"trans_type",formdata.imode, "id");
    account_head_ledgerSummary_2param(
      "accounthead",
      setSummaryData,
      "head_name",
      formdata.account_name,
      "trans_type",
      formdata.imode,
      "credit",
      "debit",
      "id"
    );
  };
  //Table data record
  // const [data, setData] = useState([]);
  const [itemsperpage, setitemsperpage] = useState([10]);
  const [columns, setColumns] = useState([]);

  const handleDelete = async (id) => {
    deleteRecord("expenses", id, "id");
    fetchData("expenses", setData, "id");
  };
  useEffect(() => {
    fetchcombolist('accounthead', setAcchead, 'head_name');
    fetchData("transactions", settransaction, "id");
    fetchData("accounthead", setSelectDatagroup, "id");
    account_head_ledger_summary(
      "accounthead",
      setSummaryData,
      "head_name",
      "all",
      "credit",
      "debit",
      "id"
    );
  }, []);
  return (
    <>
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <Breadcums heading="Account Head Expenses" paglink="Expenses-Account Head" />
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              {/* left column */}
              <div className="col-md-12">{/* <Transport_bar /> */}</div>
            </div>

            <div className="row">
              <div className="col-md-12">
                {/* general form elements */}
                
                <div className="row">
                  <div className="col-3">
                    <div className="card">
                      <div className="card-body">
                      
                        <div className="row">
                          <div className="col-12">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                Search by main Account Head
                              </label>
                              <select
                                className="form-control form-control-sm select2"
                                id="account_name"
                                onChange={(e) => handleChange2(e)}
                                value={formdata.account_name}
                              >
                                <option selected="all">All</option>
                                {Acchead &&
                                  Acchead.map((seldata, index) => {
                                    return (
                                      <option value={seldata.head_name}>
                                        {seldata.head_name}
                                      </option>
                                    );
                                  })}
                                 
                              </select>
                            </div>
                          </div>
                          <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Payment Mode
                            </label>
                            <select
                              className="form-control form-control-sm select2"
                              id="imode"
                              onChange={(e) => handleChange2(e)}
                              value={formdata.imode}
                            >
                              <option selected="NA">--Select--</option>
                              {transaction &&
                                transaction.map((seldata, index) => {
                                  return (
                                    <option value={seldata.mode}>
                                      {seldata.mode}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                          </div>
                          <div className="col-6">
                            <div className="form-group">
                            <Link type="submit" onClick={changewith_head_mode} class="btn btn-dark ">
                            Search
                      </Link>
                           
                            </div>
                            </div>
                        </div>
                    
                      </div>
                    </div>
                  </div>
                  <div className="col-8">
                    <div className="card">
                      <div className="card-body">
                      
                        <div className="row">
                          <div className="col-sm-3">
                          <div className="form-group">
                              <label htmlFor="exampleInputEmail1">From</label>
                              <input
                                type="date"
                                className="form-control form-control-sm"
                                id="frm"
                                onChange={(e) => handleChange(e)}
                                value={formdata.frm}
                              />
                            </div>
                            </div>
                            <div className="col-sm-3">
                          <div className="form-group">
                              <label htmlFor="exampleInputEmail1">To</label>
                              <input
                                type="date"
                                className="form-control form-control-sm"
                                id="to"
                                onChange={(e) => handleChange(e)}
                                value={formdata.to}
                              />
                            </div>
                          </div>
                          <div className="col-sm-3">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Payment Mode
                            </label>
                            <select
                              className="form-control form-control-sm select2"
                              id="imode"
                              onChange={(e) => handleChange2(e)}
                              value={formdata.imode}
                            >
                              <option selected="NA">--Select--</option>
                              {transaction &&
                                transaction.map((seldata, index) => {
                                  return (
                                    <option value={seldata.mode}>
                                      {seldata.mode}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                          </div>
                          <div className="col-sm-3">
                            <div className="form-group">
                            <Link type="submit" onClick={filterbydatename} class="btn btn-dark mt-4">
                            Search
                      </Link>
                           
                            </div>
                            </div>
                        </div>
                    
                      </div>
                    </div>
                  </div>
                  </div>
                <div className="card card-dark">
                  <FetchDataComponent
                    tblname="accounthead"
                    setData={setData}
                    setColumns={setColumns}
                    id="id"
                  />

                  {/* This component fetches the data and determines columns */}
                  <ShowLedgerReports
                    data={data}
                    columns={columns}
                    handleDelete={handleDelete}
                    itemsPerPage={itemsperpage}
                    credit={SummaryData.credit}
                    debit={SummaryData.debit}
                    
                    type="expense"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}
    </>
  );
}
