import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";

export default function Breadcums(props) {
  return (
    <>
      <section className="content-header">
    <div className="container-fluid">
      <div className="row ">
        <div className="col-sm-6 mb-40">
          <h5 >{props.heading}</h5>
        </div>
        <div className="col-sm-6">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
            <li className="breadcrumb-item active">{props.paglink}</li>
          </ol>
        </div>
      </div>
    </div>{/* /.container-fluid */}
  </section>
    </>
  )
}
