import React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import authheader from "../../services/authheader";
import { deleteRecord } from "../../util/deleteRecord";
import { fetchData } from "../../util/fetchData";
import { fetchData_singlePara } from "../../util/fetchDataby_singlePara";
import { checkToken } from "../../services/checkToken";
import FetchDataComponent from "../../components/tables/fetchTableData";
import Pagination from "../../components/tables/ShowTableData";
import Breadcums from "../../common/Breadcums";

export default function Heads() {
  try {
    checkToken();
  } catch (error) {
    alert("Session logged out.Please logged in again");
    localStorage.clear();
    // history.push('/');
    window.location.href = "/";
  }
  const headers = { Authorization: authheader().access_token };
  const [data, setData] = useState([]);
  const [Selectdatagroup, setSelectDatagroup] = useState([]);
  const [Selectdatasubgroup, setSelectDatasubgroup] = useState([]);
  const [errors, setErrors] = useState({});
  const [formdata, setFormData] = useState({
    brand_name: "",
    group_name: "",
    sub_group_name: "",
    
  });
  const handleChange1 = (e) => {
    
    setFormData({ ...formdata, [e.target.id]: e.target.value });
     
    fetchData_singlePara("subgroups", setSelectDatasubgroup,"group_name",e.target.value, "id");
  };
  const handleChange = (e) => {
    
    setFormData({ ...formdata, [e.target.id]: e.target.value });
    
  };
  //handle add record
  const handleAddRecord = (newRecord) => {
    setData([...data, newRecord]);
    fetchData("heads", setData,"id");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formdata1 = e.target;
    axios
      .post(
        "/insertdata/heads",
        {
          brand_name: formdata1.brand_name.value,
          groupname: formdata1.group.value,
          subgroup: formdata1.subgroup.value,
          
        },
        { headers }
      )
      .then((res) => {
        fetchData("heads", setData, "id");

        const newRecord = res;
        handleAddRecord(newRecord);
        setFormData({
          brand_name: "",
         
        });
        //navigate("/");
      })
      .catch((err) => {
        console.log(err.message);
      });
    // handleAddRecord(formdata);
    setFormData({});
    setErrors({});
  };

  //Table data record
  // const [data, setData] = useState([]);
  const [itemsperpage, setitemsperpage] = useState([10]);
  const [columns, setColumns] = useState([]);

  const handleDelete = async (id) => {
    deleteRecord("heads", id, "id");
    fetchData("heads", setData, "id");
  };
  useEffect(() => {
    fetchData("groups", setSelectDatagroup,"id");
    
  }, []);
  return (
    <>
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <Breadcums heading="Master" paglink="Heads" />
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              {/* left column */}
              <div className="col-md-12">
                {/* general form elements */}
                <div className="card card-dark">
                  <div className="card-header">
                    <h3 className="card-title">Ledger Head Creation</h3>
                  </div>
                  {/* /.card-header */}
                  {/* form start */}
                  <form onSubmit={handleSubmit}>
                    <div className="card-body">
                    <div className="row">
                    <div className="col-3">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Head Name</label>
                        <input
                          type="text"
                          className="form-control "
                          id="brand_name"
                          onChange={(e) => handleChange(e)}
                          value={formdata.brand_name}
                          placeholder="Enter Name"
                        />
                      </div>
                      </div>
                      <div className="col-3">
                        <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Under Group</label>
                        <select 
                        className="form-control select2"
                        id="group"
                        onChange={(e) => handleChange1(e)}
                        onClick={(e) => handleChange1(e)}
                        onKeyUp={(e) => handleChange1(e)}
                        value={Selectdatagroup.group}
                        >
                          
                        <option selected="NA">--Select--</option>
                        {Selectdatagroup &&
                          Selectdatagroup.map((seldata, index) => {
                            return (
                              <option value={seldata.brand_name}>{seldata.brand_name}</option>
                            );
                          })}
                  </select>
                      </div>
                        </div>
                        <div className="col-3">
                        <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Under Sub Group</label>
                        <select 
                        className="form-control select2"
                        id="subgroup"
                        onChange={(e) => handleChange(e)}
                        value={formdata.subgroup}
                        >
                          
                        <option selected="NA">--Select--</option>
                        {Selectdatasubgroup &&
                          Selectdatasubgroup.map((seldata, index) => {
                            return (
                              <option value={seldata.subgroup_name}>{seldata.subgroup_name}</option>
                            );
                          })}
                  </select>
                      </div>
                        </div>
                        <div className="col-3">
                        <button type="submit" class="btn btn-dark mt-4 float-right  ">
                        Save
                      </button>
                          </div>
                    </div>
                    </div>
                    {/* /.card-body */}
                   
                  </form>
                </div>
              </div>
              </div>
              <div className="row">
              <div className="col-md-12">
                {/* general form elements */}
                <div className="card card-dark">
                  <FetchDataComponent
                    tblname="heads"
                    setData={setData}
                    setColumns={setColumns}
                    id="id"
                  />

                  {/* This component fetches the data and determines columns */}
                  <Pagination
                    data={data}
                    columns={columns}
                    handleDelete={handleDelete}
                    itemsPerPage={itemsperpage}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}
    </>
  );
}
