import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const role= localStorage.getItem('usertype');
    const username= localStorage.getItem('uname');
  const [user, setUser] = useState({
    name:username,
    role:role,
  }); // Set your initial user state
//const userRole = localStorage.getItem("usertype"); // This should come from your user state
  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
