
import {  } from "react-router-dom";
import axios from "axios";
import authheader from "../services/authheader";
import { checkToken } from "../services/checkToken";
import 'react-toastify/dist/ReactToastify.css';
export const twoparawithlimit = async (tblname, productdatachange1,col1,val1,col2,val2,orderby,limit) => {
    checkToken();
    const headers = { Authorization: authheader().access_token };
    // try {
         axios
         .get("/filterdata2withlimit/"+tblname+"/"+col1+"/"+val1+"/"+col2+"/"+val2+"/"+orderby+"/"+limit, { headers })
         .then((res) => {
           // alert(res.data.data.length);
            // console.log(res.data.data);
            if(res.data.data.length>0)
            {
              const jsonData =res.data.data[0];
              console.log(jsonData);
              productdatachange1(jsonData);
            }
            else{
              const jsonData ={balance:0};
              console.log(jsonData);
              productdatachange1(jsonData);
            }
           
           

         })
         .catch((err) => {
          //  productdatachange1("Error fetching data:");
           // toast.error('An error occurred while making the POST requests');
           
             console.error('Error fetching data:', err);
         });
   
   };
