
import {  } from "react-router-dom";
import axios from "axios";
import authheader from "../services/authheader";


export const deleteRecord  = (tablename,index,colid) => {
    const headers = { Authorization: authheader().access_token };  // Create a new array without the record at the specified index
    axios
    .delete(
      "/deletebyid/"+tablename+"/"+index+"/"+colid,
      { headers }
    )
    .then((res) => {
      console.log(res.data);
     // alert("Data Deleted Successfully");
   
    })
    .catch((err) => {
      console.log(err.message);
    });
   
  };
