import React from "react";
import { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import authheader from "../../services/authheader";
import { format } from "date-fns";
import { fetch_data_with_limit } from "../../util/fetch_Data_with_limit";
import { fetchData_singlePara } from "../../util/fetchDataby_singlePara";
import { getOrder } from "../../util/getOrder";
import { getLastId } from "../../util/lastid";
import { deleteRecord } from "../../util/deleteRecord";
import { fetchData } from "../../util/fetchData";
import { fetchDatainvoiceitem } from "../../util/fetchData_invoiceitem";
import { checkToken } from "../../services/checkToken";
import FetchDataComponent from "../../components/tables/fetchTableData";
import Pagination from "../../components/tables/ShowTableData";
import Breadcums from "../../common/Breadcums";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function MakeInvoice() {
  try {
    checkToken();
  } catch (error) {
    toast.error("Session logged out.Please logged in again");
    //alert("Session logged out.Please logged in again");
    localStorage.clear();
    // history.push('/');
    window.location.href = "/";
  }
  const today = new Date();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const date = today.getDate();
  const currentDate = year + "/" + month + "/" + date;

  const headers = { Authorization: authheader().access_token };
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const inputRef = useRef(null);
  const [VendorName, setVendorname] = useState([]);
  const [changeVendorName, setChangeVendorname] = useState("");
  const [VenderData, setVenderData] = useState([]);
  const [Customerdata, setCustomerData] = useState([]);
  const [placeofsupply, setplaceofsupply] = useState("NA");

  const [Ordernum, setOrdernum] = useState(0);
  const [LastID, setLastID] = useState(0);

  const [InventoryData, setInventoryData] = useState({});
  const [headdata, setheaddata] = useState([]);
  const [myplace, setmyplace] = useState([]);

  const [ProductName, setProductName] = useState([]);
  const [transaction, settransaction] = useState([]);
  const [ProductNamedetails, setProductNamedetails] = useState({});

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formdata, setFormData] = useState({
    vendor_id: "",
    vendor_name: "",
    product_id: "",
    prod_name: "",
    category: "",
    invoice_date: currentDate,
    ref: "",
    qty: "0",
    rate: "0",
    disc: "0",
    subtotal: "0",
    tax_percent: "",
    tax_value: "",
    total_cost: "",
    wsale_price: "",
    rsale_price: "",
    description: "",
  });

  const handleChange1 = (e) => {
    setFormData({ ...formdata, [e.target.id]: e.target.value });
    //alert(e.target.value);
    // Get Vendor Prev. Balance
    fetch_data_with_limit(
      "income",
      setVenderData,
      "vendor",
      e.target.value,
      "id",
      "limit 1"
    );
    //Get Customer Details
    fetch_data_with_limit(
      "customers",
      setCustomerData,
      "company_name",
      e.target.value,
      "id",
      "limit 1"
    );
    // Get head details
    fetch_data_with_limit(
      "subheads",
      setheaddata,
      "subhead_name",
      e.target.value,
      "id",
      "limit 1"
    );
    //alert(VenderData.id);
  };

  const handleChange = (e) => {
    setFormData({ ...formdata, [e.target.id]: e.target.value });
    setmybalance(formdata.prev_balance);
    setmyvendor(formdata.vendor_name);
    setmyref(formdata.ref);
    setmyinvdate(formdata.dte);
   // alert(e.target.value);
    setplaceofsupply(e.target.value);
    checksupply(e.target.value);
  };
  const getproductvalue = (e) => {
    //alert(e.target.value);
    fetch_data_with_limit(
      "products",
      setProductNamedetails,
      "prod_name",
      e.target.value,
      "id",
      "limit 1"
    );
    fetch_data_with_limit(
      "inventory",
      setInventoryData,
      "prod_name",
      e.target.value,
      "id",
      "limit 1"
    );
  };
  const [ptotal_cost, ptotal_costChange] = useState(0);
  const [subtotal_cost, subtotal_costChange] = useState(0);
  const [ptaxvalue, ptaxvalueChange] = useState(0);
  const cal = (e) => {
    const formdata1 = e.target;
    let unitprice = Number(ProductNamedetails.rsale);
    let qty1 = parseInt(formdata.qty);
    // alert(formdata.qty1);
    let disc1 = parseInt(formdata.disc);
    //alert(formdata.disc1);
    let tax1 = Number(formdata.tax_percent);
    let subtotal = unitprice * qty1 - disc1;
    subtotal_costChange(subtotal);

    let taxvalue = (subtotal * (ProductNamedetails.tax / 100)).toFixed(2);
    let total_cost = parseFloat(subtotal) + parseFloat(taxvalue);
    ptaxvalueChange(taxvalue);
    ptotal_costChange(total_cost);
  };

  const [summary_subtotal, summary_subtotal_change] = useState([]);
  const [currentDate1, setCurrentDate] = useState('');
  const [summary_tax, summary_taxchange] = useState(0);
  const [summary_disc, summary_dischange] = useState(0);
  const [totalpay, totalpayChange] = useState(0);
  const [invoicedate, setinvoicedate] = useState(0);
  const [myref, setmyref] = useState(0);
  const [myvendor, setmyvendor] = useState(0);
  const [mybalance, setmybalance] = useState(0);
  const [myinvdate, setmyinvdate] = useState(0);
  const BillTotal = (e) => {
    axios
      .get("/customer_bill_summary/invoiceitems/inv/0/userid/'" + e + "'", {
        headers,
      })
      .then((res) => {
        totalpayChange(res.data.data[0].total_cost);
        summary_subtotal_change(res.data.data[0].subtotal);
        summary_taxchange(res.data.data[0].total_tax_value);
        summary_dischange(res.data.data[0].disc);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const saveExpense = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    const formdata1 = e.target;
    setmybalance(formdata1.prev_balance.value);
    setmyvendor(formdata1.vendor_name.value);
    // setmyref(formdata1.ref.value);
    setmyinvdate(formdata1.dte.value);
    let total =
      parseFloat(formdata1.prev_balance.value) +
      parseFloat(formdata1.summary_total_value.value);
    getOrder("invoices", setOrdernum, "inv");
    // setOrdernum(Invnum);
    let creditvalue = 0;
    if (formdata1.mode.value === "Credit") {
      creditvalue = 0;
    } else {
      creditvalue = formdata1.summary_total_value.value;
    }
    //alert(creditvalue);
    const post1 = axios.post(
      "/insertdata/income",
      {
        sale_id: Ordernum,
        date: currentDate,
        invdte: currentDate,
        userid: localStorage.getItem("uname"),
        vendor: formdata1.vendor_name.value,
        head: headdata.head_name,
        subhead: formdata1.vendor_name.value,
        ref: "Sale Invoice",
        payment_mode: formdata1.mode.value,
        bank: "NA",
        debit: formdata1.summary_total_value.value,
        // cgst: formdata1.cgst.value,
        // sgst: formdata1.sgst.value,
        // igst: formdata1.igst.value,
        tax: formdata1.summary_tax_value.value,
        disc: "0",
        credit: creditvalue,
        //balance: total,
        balance: "0",
        remark: "Sale voucher",
      },
      { headers }
    );
    const post2 = axios.post(
      "/insertdata/invoices",
      {
        customer_id: Customerdata.id,
        inv: Ordernum,
        customer_name: formdata1.vendor_name.value,
        invoice_date: formdata1.dte.value,
        disc: summary_disc,
        subtotal: formdata1.summary_subtotal.value,
        taxvalue: formdata1.summary_tax_value.value,
        shipping: formdata1.shipping.value,
        total_amount: formdata1.summary_total_value.value,
        status: formdata1.status.value,
      },
      { headers }
    );
    //Add data in inventory

    const post3 = axios.put(
      "/updatedata1/invoiceitems/inv/0",
      {
        inv: Ordernum,
      },

      { headers }
    );
    const post4 = axios.post(
      "/insertdata/customer_bill_details",
      {
        invoice: Ordernum,
        name:formdata1.vendor_name.value,
        contact: Customerdata.contact,
        gst: Customerdata.gst,
        place: formdata1.place.value,
        address: Customerdata.street1+","+Customerdata.street2+","+Customerdata.city+"-"+Customerdata.pincode,
        payment_mode: formdata1.mode.value,
       
      },
      { headers }
    );

//alert(formdata1.mode.value);
    try {
      if(formdata1.mode.value==="Credit")
        {
          await Promise.all([post1, post2, post3,post4]);
        }
        else{
          const post5 =  axios.post("/insertdata/accounthead",
          {
            date: currentDate,
            head_name: "JD",
            credit: formdata1.summary_total_value.value,
            debit: "0",
            trans_type: formdata1.mode.value,
            remark: "Sale VCHR-against Bill no."+Ordernum+",PYMT RCVD from-"+formdata1.vendor_name.value,
         
          },
          { headers }
        );
          await Promise.all([post1, post2, post3,post4,post5]);
        }
      
      // All requests are successful
      BillTotal(localStorage.getItem("uname"));
      fetch_data_with_limit(
        "income",
        setVenderData,
        "vendor",
        myvendor,
        "id",
        "limit 1"
      );
      fetchDatainvoiceitem(
        "invoiceitems",
        setData,
        "userid",
        "inv",
        localStorage.getItem("uname"),
        "0",
        "id"
      );
      toast.success("Data saved successfully!");
    } catch (err) {
      // Handle errors
      toast.error("An error occurred while making the POST requests" + err);
      setError("An error occurred while making the POST requests");
      console.error("Error:", err);
    } finally {
      setLoading(false);
    }
    // handleAddRecord(formdata);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formdata1 = e.target;

    const post1 = axios.post(
      "/insertdata/invoiceitems",
      {
        userid: localStorage.getItem("uname"),
        category: ProductNamedetails.category,
        subcategory: ProductNamedetails.subcategory,
        invdate: currentDate,
        product_id: ProductNamedetails.prod_code,
        product_name: ProductNamedetails.prod_name,
        quantity: formdata1.qty.value,
        uom: ProductNamedetails.unit,
        unit_price: formdata1.rate.value,
        disc: formdata1.disc.value,
        subtotal: subtotal_cost,
        cgst: formdata1.cgst.value,
        sgst: formdata1.sgst.value,
        igst: formdata1.igst.value,
        tax: ptaxvalue,
        total_price: ptotal_cost,
      },
      { headers }
    );
    //get previous balance inventory by item
    let total =
      parseFloat(InventoryData.balance) - parseFloat(formdata1.qty.value);
    //let saleid=parseInt(data1[0].id)+1;
    const post2 = axios.post(
      "/insertdata/inventory",
      {
        userid: localStorage.getItem("uname"),
        pdate: currentDate,
        category: ProductNamedetails.category,
        purchase_id: LastID,
        prod_id: ProductNamedetails.prod_code,
        prod_name: ProductNamedetails.prod_name,
        dept: "Sale",
        stockin: "0",
        stockout: formdata1.qty.value,
        balance: total,
        // balance: "0"
      },
      { headers }
    );
    
    try {
      await Promise.all([post1, post2]);

      BillTotal(localStorage.getItem("uname"));
      toast.success("Item added successfully!");
      //getLastId("invoiceitems",setLastID , "id");

      // toast.success('Inventory Deducted successfully!');
      fetchDatainvoiceitem(
        "invoiceitems",
        setData,
        "userid",
        "inv",
        localStorage.getItem("uname"),
        "0",
        "id"
      );

      inputRef.current.focus();
      setFormData({
        product_id: "",
        prod_name: "",
        category: "",
        qty: "0",
        rate: "0",
        disc: "0",
        subtotal: "",
        tax_percent: "",
        tax_value: "",
        total_cost: "",
      });
    } catch (err) {
      // Handle errors
      //setError('An error occurred while making the POST requests');
      toast.error("Error while adding items.");
      console.error("Error:", err);
    } finally {
      setLoading(false);
    }
  };

  //Table data record
  const [itemsperpage, setitemsperpage] = useState([10]);
  const [columns, setColumns] = useState([]);

  const handleDelete = async (id) => {
    deleteRecord("invoiceitems", id, "id");
    fetchDatainvoiceitem(
      "invoiceitems",
      setData,
      "userid",
      "inv",
      localStorage.getItem("uname"),
      "0",
      "id"
    );
    BillTotal(localStorage.getItem("uname"));

    // fetchData("invoiceitems", setData, "id");
  };
  const checksupply = (e) => {
   
    if (e !== "NA") {
      const form = document.getElementById("prod_name");
      form.disabled = false;
    } 
    else 
    {
      const form = document.getElementById("prod_name");
      form.disabled = true;
    }
  };
  const cancelOrder = async (id) => {
    data &&
      data.map((deldata, index) => {
        deleteRecord("inventory", deldata.id, "purchase_id");
        deleteRecord("invoiceitems", deldata.id, "id");
      });
    fetchDatainvoiceitem(
      "invoiceitems",
      setData,
      "userid",
      "inv",
      localStorage.getItem("uname"),
      "0",
      "id"
    );
    BillTotal(localStorage.getItem("uname"));

    // fetchData("invoiceitems", setData, "id");
  };
  useEffect(() => {
    //fetchData("store", setstorename, "id");
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
    const day = String(today.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    setCurrentDate(formattedDate);
    const form = document.getElementById("prod_name");
    form.disabled = true;

    fetchData("customers", setVendorname, "id");
    fetchData("transactions", settransaction, "id");

    fetchData("products", setProductName, "id");
    BillTotal(localStorage.getItem("uname"));
    //get Last Insert
    getLastId("invoiceitems", setLastID, "id");
    getOrder("invoices", setOrdernum, "inv");
    fetchData_singlePara("invoiceitems", setData1, "inv", "0", "id");
    // checkorder();
    fetchDatainvoiceitem(
      "invoiceitems",
      setData,
      "userid",
      "inv",
      localStorage.getItem("uname"),
      "0",
      "id"
    );

    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <>
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <Breadcums heading="Sale Invoice" paglink="Tax Invoice" />
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              {/* left column */}
              <div className="col-md-12">
                <div className="card">
                  <form id="cart" onSubmit={handleSubmit}>
                    <div className="card-body  ">
                      <div className="row">
                        <div className="col-4">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Choose Product
                            </label>
                            <select
                              className="form-control form-control-sm select2"
                              id="prod_name"
                              ref={inputRef}
                              onChange={(e) => getproductvalue(e)}
                              value={ProductNamedetails.prod_name}
                            >
                              <option selected="NA">--Select--</option>
                              {ProductName &&
                                ProductName.map((seldata, index) => {
                                  return (
                                    <option value={seldata.prod_name}>
                                      {seldata.prod_name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>

                        {/* <div className="col-2">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1"> Category</label>
                        <input
                          type="text"
                          disabled
                          className="form-control form-control-sm"
                          id="category"
                          onChange={(e) => handleChange(e)}
                          value={ProductNamedetails.category}
                        />
                      </div>
                    </div>
                    <div className="col-2">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          {" "}
                          Sub Category
                        </label>
                        <input
                          type="text"
                          disabled
                          className="form-control form-control-sm"
                          id="subcategory"
                          onChange={(e) => handleChange(e)}
                          value={ProductNamedetails.subcategory}
                        />
                      </div>
                    </div> */}
                        <div className="col-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1"> Qty</label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              id="qty"
                              onChange={(e) => handleChange(e)}
                              onKeyUp={(e) => cal(e)}
                              value={formdata.qty}
                            />
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1"> Rate</label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              id="rate"
                              onChange={(e) => handleChange(e)}
                              value={ProductNamedetails.rsale}
                            />
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1"> Disc</label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              id="disc"
                              onChange={(e) => handleChange(e)}
                              onKeyUp={(e) => cal(e)}
                              value={formdata.disc}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-3">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              {" "}
                              Sub Total
                            </label>
                            <input
                              type="text"
                              disabled
                              className="form-control form-control-sm"
                              id="subtotal"
                              onChange={(e) => handleChange(e)}
                              value={subtotal_cost}
                            />
                          </div>
                        </div>
                        <div className="col-1">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1"> CGST%</label>
                            <input
                              type="text"
                              disabled
                              className="form-control form-control-sm"
                              id="cgst"
                              onChange={(e) => handleChange(e)}
                              value={
                                placeofsupply &&
                                placeofsupply === "himachal-pradesh"
                                  ? ProductNamedetails.tax / 2
                                  : 0
                              }
                            />
                          </div>
                        </div>
                        <div className="col-1">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1"> SGST%</label>
                            <input
                              type="text"
                              disabled
                              className="form-control form-control-sm"
                              id="sgst"
                              onChange={(e) => handleChange(e)}
                              value={
                                placeofsupply &&
                                placeofsupply === "himachal-pradesh"
                                  ? ProductNamedetails.tax / 2
                                  : 0
                              }
                            />
                          </div>
                        </div>
                        <div className="col-1">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1"> IGST%</label>
                            <input
                              type="text"
                              disabled
                              className="form-control form-control-sm"
                              id="igst"
                              onChange={(e) => handleChange(e)}
                              value={
                                placeofsupply &&
                                placeofsupply === "himachal-pradesh"
                                  ? 0
                                  : ProductNamedetails.tax
                              }
                            />
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              {" "}
                              Tax Value
                            </label>
                            <input
                              type="text"
                              disabled
                              className="form-control form-control-sm"
                              id="taxvalue"
                              onChange={(e) => handleChange(e)}
                              value={ptaxvalue}
                            />
                          </div>
                        </div>

                        <div className="col-3">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1"> Amount</label>
                            <input
                              type="text"
                              disabled
                              className="form-control form-control-sm"
                              id="netamount"
                              onChange={(e) => handleChange(e)}
                              value={ptotal_cost}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-8"> </div>
                        <div className="col-2">
                          <div className="form-group">
                            <button
                              type="submit"
                              className="btn btn-dark mt-4 float-right"
                            >
                              Add to Cart
                            </button>
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="form-group">
                            <Link
                              type="button"
                              className="btn btn-danger mt-4 float-right"
                              onClick={cancelOrder}
                            >
                              Cancel Order
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {/* general form elements */}

            <div className="row">
              <div className="col-md-7">
                {/* general form elements */}
                <div className="card card-dark">
                  <FetchDataComponent
                    tblname="invoiceitems"
                    setData={setData}
                    setColumns={setColumns}
                    id="id"
                  />

                  {/* This component fetches the data and determines columns */}
                  <Pagination
                    data={data}
                    columns={columns}
                    handleDelete={handleDelete}
                    itemsPerPage={itemsperpage}
                  />
                </div>
              </div>
              <div className="col-md-5">
                <div className="card card-dark">
                  <div className="card-header">
                    <h3 className="card-title">Summary</h3>
                  </div>
                  <form onSubmit={saveExpense}>
                    <div className="card-body">
                      <div className="row">
                      <div className="col-6">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Place of Supply
                            </label>
                            <select
                              className="form-control form-control-sm select2"
                              id="place"
                              onChange={(e) => handleChange(e)}
                              value={formdata.place}
                            >
                              <option selected value="NA">
                                --Select--
                              </option>
                              <option value="andhra-pradesh">
                                Andhra Pradesh
                              </option>
                              <option value="arunachal-pradesh">
                                Arunachal Pradesh
                              </option>
                              <option value="assam">Assam</option>
                              <option value="bihar">Bihar</option>
                              <option value="chhattisgarh">Chhattisgarh</option>
                              <option value="goa">Goa</option>
                              <option value="gujarat">Gujarat</option>
                              <option value="haryana">Haryana</option>
                              <option value="himachal-pradesh">
                                Himachal Pradesh
                              </option>
                              <option value="jharkhand">Jharkhand</option>
                              <option value="karnataka">Karnataka</option>
                              <option value="kerala">Kerala</option>
                              <option value="madhya-pradesh">
                                Madhya Pradesh
                              </option>
                              <option value="maharashtra">Maharashtra</option>
                              <option value="manipur">Manipur</option>
                              <option value="meghalaya">Meghalaya</option>
                              <option value="mizoram">Mizoram</option>
                              <option value="nagaland">Nagaland</option>
                              <option value="odisha">Odisha</option>
                              <option value="punjab">Punjab</option>
                              <option value="rajasthan">Rajasthan</option>
                              <option value="sikkim">Sikkim</option>
                              <option value="tamil-nadu">Tamil Nadu</option>
                              <option value="telangana">Telangana</option>
                              <option value="tripura">Tripura</option>
                              <option value="uttar-pradesh">
                                Uttar Pradesh
                              </option>
                              <option value="uttarakhand">Uttarakhand</option>
                              <option value="west-bengal">West Bengal</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Payment Mode
                            </label>
                            <select
                              className="form-control form-control-sm select2"
                              id="mode"
                              onChange={(e) => handleChange(e)}
                              value={formdata.mode}
                            >
                              <option selected="NA">--Select--</option>
                              {transaction &&
                                transaction.map((seldata, index) => {
                                  return (
                                    <option value={seldata.mode}>
                                      {seldata.mode}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Inv. Date
                              <i
                                className="fa fa-calendar ml-2"
                                data-bs-toggle="tooltip"
                                title=""
                                data-bs-original-title="fa fa-barcode"
                                aria-label="fa fa-barcode"
                              ></i>
                            </label>
                            <input
                              type="date"
                              className="form-control form-control-sm"
                              id="dte"
                            
                              value={currentDate1}
                              onChange={(e) => setCurrentDate(e.target.value)}
                            />
                            <input
                              type="hidden"
                              className="form-control form-control-sm"
                              id="invv"
                              onChange={(e) => handleChange(e)}
                              value={LastID}
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Select Customer
                            </label>
                            <select
                              className="form-control form-control-sm select2"
                              id="vendor_name"
                              
                              onChange={(e) => handleChange1(e)}
                              value={formdata.vendor_name}
                            >
                              <option selected="NA">--Select--</option>
                              {VendorName &&
                                VendorName.map((seldata, index) => {
                                  return (
                                    <option value={seldata.company_name}>
                                      {seldata.company_name}/{seldata.gst}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                        {/* <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          Order No.
                          <i
                            className="fa fa-barcode ml-2"
                            data-bs-toggle="tooltip"
                            title=""
                            data-bs-original-title="fa fa-barcode"
                            aria-label="fa fa-barcode"
                          ></i>
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          id="ref"
                          onChange={(e) => handleChange(e)}
                          onKeyUp={(e) => BillTotal(e)}
                          value={myorder}
                        />
                      </div>
                    </div> */}
                        <div className="col-6">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Prev. Balance
                              <i
                                className="fa fa-money-bill ml-2"
                                data-bs-toggle="tooltip"
                                title=""
                                // onClick={handleClick}
                                data-bs-original-title="fa fa-barcode"
                                aria-label="fa fa-barcode"
                              ></i>
                            </label>
                            <input
                              type="text"
                              required
                              disabled
                              className="form-control form-control-sm"
                              id="prev_balance"
                              onChange={(e) => handleChange(e)}
                              value={
                                VenderData && VenderData
                                  ? VenderData.balance
                                  : "0"
                              }
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1"> Status</label>
                            <select
                              className="form-control form-control-sm select2"
                              id="status"
                              onChange={(e) => handleChange(e)}
                              value={formdata.status}
                            >
                              <option selected value="unpaid">
                                UnPaid
                              </option>
                              <option value="paid">Paid</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Disc</label>
                            <input
                              type="text"
                              disabled
                              className="form-control form-control-sm"
                              id="summary_disc"
                              value={summary_disc}
                              placeholder="Enter Name"
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Sub Total
                            </label>
                            <input
                              type="text"
                              disabled
                              className="form-control form-control-sm"
                              id="summary_subtotal"
                              value={summary_subtotal}
                              placeholder="Enter Name"
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Tax value
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              id="summary_tax_value"
                              disabled
                              onChange={(e) => handleChange(e)}
                              value={summary_tax}
                              placeholder="Enter Name"
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              {" "}
                              Shipping
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              id="shipping"
                              onChange={(e) => handleChange(e)}
                              //onKeyUp={(e) =>cal(e)}
                              value={formdata.shipping}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Net Amount
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              id="summary_total_value"
                              disabled
                              onChange={(e) => handleChange(e)}
                              value={totalpay}
                              placeholder=""
                            />
                          </div>
                        </div>
                       
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <button
                              type="submit"
                              disabled={loading}
                              className="btn btn-dark mt-4 float-right"
                            >
                              Save Invoice
                            </button>
                            <ToastContainer />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <Link
                              to={`/sale/printinvoice/${Ordernum-1}`}
                              className="btn btn-success mt-4 float-right"
                            >
                              Print Invoice
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}
    </>
  );
}
