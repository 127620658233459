import { useState } from "react";

const Pagination = ({ postsPerPage, totalPosts, paginate }) => {
  const pageNumber = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumber.push(i);
  }
  const [activePage, setActivePage] = useState(1);

  const handleCLick = (number) => {
    setActivePage(number);
    paginate(number);
  };
  return (
    <>
      <nav>
        <ul className="pagination pagination-sm">
          {pageNumber.map((number) => (
            <li
              key={number}
              className={`page-item ${activePage === number ? "active" : ""}`}
            >
              <a
                className="page-link "
                onClick={() => {
                  handleCLick(number);
                  paginate(number);
                }}
              >
                {number}
              </a>
            </li>
          ))}
        </ul>
      </nav>
    </>
  );
};

export default Pagination;
