import { Navigate } from "react-router-dom";

      export const checkToken = async () => {
    
        const token = localStorage.getItem('token');
      const expirationTime = localStorage.getItem('expirationTime');
  
      if (!token || !expirationTime || new Date().getTime()> expirationTime) {
        // Token is expired or not available, handle accordingly (e.g., redirect to login)
        // Example: history.push('/login');
       // alert("hello");
        localStorage.clear();
       // history.push('/');
       <Navigate to="/" />
        window.location.href = '/';
        return;
      }
      else{
       // console.log(new Date().getTime());
      }
       
       };