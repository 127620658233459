import React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams  } from "react-router-dom";
import axios from "axios";
import authheader from "../../services/authheader";
import { format } from "date-fns";
import { fetch_data_with_limit } from "../../util/fetch_Data_with_limit";


import { deleteRecord } from "../../util/deleteRecord";
import { fetchData } from "../../util/fetchData";
import { checkToken } from "../../services/checkToken";
import FetchDataComponent from "../../components/tables/fetchTableData";

import Pagination from "../../components/tables/ShowTableData";
import Breadcums from "../../common/Breadcums";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
export default function Viewcontracts() {
    try {
        checkToken();
      } catch (error) {
        alert("Session logged out.Please logged in again");
        localStorage.clear();
        // history.push('/');
        window.location.href = "/";
      }
      const headers = { Authorization: authheader().access_token };
     
      const { id } = useParams();
      const [ContractDetails, setContractDetails] = useState([]);
      const [VendorName, setVendorname] = useState([]);
    
    useEffect(() => {
        fetch_data_with_limit("contracts", setContractDetails, "id", id, "id","limit 1");
      }, []);
  return (
    <>
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <Breadcums heading="Contract Details" paglink="View Contracts" />
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Projects Detail</h3>
              <div className="card-tools">
                <button
                  type="button"
                  className="btn btn-tool"
                  data-card-widget="collapse"
                  title="Collapse"
                >
                  <i className="fas fa-minus" />
                </button>
                <button
                  type="button"
                  className="btn btn-tool"
                  data-card-widget="remove"
                  title="Remove"
                >
                  <i className="fas fa-times" />
                </button>
              </div>
            </div>
            <div className="card-body ">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-8 order-2 order-md-1">
                  <div className="row">
                    <div className="col-12 col-sm-4">
                      <div className="info-box bg-light">
                        <div className="info-box-content">
                          <span className="info-box-text text-center ">
                            Estimated budget
                          </span>
                          <span className="info-box-number text-center  mb-0">
                            INR {ContractDetails.total_value}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-4">
                      <div className="info-box bg-light">
                        <div className="info-box-content">
                          <span className="info-box-text text-center ">
                            Contract Period
                          </span>
                          <span className="info-box-number text-center  mb-0">
                            {ContractDetails.start_date}-{ContractDetails.end_date}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-4">
                      <div className="info-box bg-light">
                        <div className="info-box-content">
                          <span className="info-box-text text-center ">
                            Work Type
                          </span>
                          <span className="info-box-number text-center  mb-0">
                          {ContractDetails.work_type}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <h4 className="text-center">Agreement Details</h4>
                      <div className="post">
                        {/* <div className="user-block">
                          <img
                            className="img-circle img-bordered-sm"
                            src="../../dist/img/user1-128x128.jpg"
                            alt="user image"
                          />
                          <span className="username">
                            <a href="#">Jonathan Burke Jr.</a>
                          </span>
                          <span className="description">
                            Shared publicly - 7:45 PM today
                          </span>
                        </div> */}
                        {/* /.user-block */}
                        <p>
                          {ContractDetails.description}
                        </p>
                       
                      </div>
                     
                     
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-4 order-1 order-md-2">
                  <h3 className="text-primary">
                    <i className="fas fa-paint-brush" /> {ContractDetails.name}
                  </h3>
                  <p className="">
                    {ContractDetails.brief}
                  </p>
                 
                  
                  
                  <h5 className="mt-4 ">Billing Details</h5>
                  <ul className="list-unstyled">
                    <li>
                      <a href className="btn-link text-secondary">
                        
                         Billing Name:{ContractDetails.billing_name}
                      </a>
                    </li>
                    <li>
                      <a href className="btn-link text-secondary">
                        
                         Contact:{ContractDetails.billing_contact}
                      </a>
                    </li>
                    <li>
                      <a href className="btn-link text-secondary">
                      
                        Address:{ContractDetails.billing_address}
                      </a>
                    </li>
                    <li>
                      <a href className="btn-link text-secondary">
                       
                        Bank Holder Name:{ContractDetails.bankholder}
                      </a>
                    </li>
                    <li>
                      <a href className="btn-link text-secondary">
                       
                        Bank Name:{ContractDetails.bank_name}
                      </a>
                    </li>
                    <li>
                      <a href className="btn-link text-secondary">
                       
                        Account:{ContractDetails.account}
                      </a>
                    </li>
                    <li>
                      <a href className="btn-link text-secondary">
                        
                        IFSC:{ContractDetails.ifsc}
                      </a>
                    </li>
                  </ul>
                 
                </div>
              </div>
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    </>
  );
}
