import React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import authheader from "../../services/authheader";
import { format } from "date-fns";
import { fetch_data_with_limit } from "../../util/fetch_Data_with_limit";
import { fetchData_singlePara } from "../../util/fetchDataby_singlePara";
import { fetchDatainvoiceitem } from "../../util/fetchData_invoiceitem";
import { deleteRecord } from "../../util/deleteRecord";
import { fetchData } from "../../util/fetchData";
import { checkToken } from "../../services/checkToken";
import FetchDataComponent from "../../components/tables/fetchTableData";
import Pagination from "../../components/tables/ShowTableData";
import Breadcums from "../../common/Breadcums";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

export default function StockOut() {
  try {
    checkToken();
  } catch (error) {
    alert("Session logged out.Please logged in again");
    localStorage.clear();
    // history.push('/');
    window.location.href = "/";
  }
  const headers = { Authorization: authheader().access_token };
  const [data, setData] = useState([]);

  const [storename, setstorename] = useState([]);
  const [VendorName, setVendorname] = useState([]);
  const [VenderData, setVenderData] = useState([]);
  const [headdata, setheaddata] = useState([]);

  const [ProductName, setProductName] = useState([]);
  const [ProductNamedetails, setProductNamedetails] = useState({});
  const [InventoryData, setInventoryData] = useState({});
  const [formdata, setFormData] = useState({
    vendor_id: "",
    vendor_name: "",
    product_id: "",
    prod_name: "",
    category: "",
    purchase_date: "",
    ref: "",
    obqty: "",
    qty: "0",
    clbalance: "0",
    
  });
  const handleConfirm = () => {
    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure you want to submit this form?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => saveExpense()
        },
        {
          label: 'No',
          onClick: () => toast.info('Submission canceled.')
        }
      ]
    });
  };
  const handleChange1 = (e) => {
    setFormData({ ...formdata, [e.target.id]: e.target.value });

    // Get Vendor Prev. Balance
    fetch_data_with_limit(
      "expenses",
      setVenderData,
      "vendor",
      e.target.value,
      "id",
      "limit 1"
    );
    // Get head details
    fetch_data_with_limit(
      "subheads",
      setheaddata,
      "subhead_name",
      e.target.value,
      "id",
      "limit 1"
    );
   // alert(VenderData.balance);
   // cal(e);
  };

  const handleChange = (e) => {
    setFormData({ ...formdata, [e.target.id]: e.target.value });
   // setmybalance(formdata.qty);
   
    setmyvendor(formdata.vendor_name);
   // setmyref(formdata.ref);
    setmyinvdate(formdata.dte);
  };
  const getproductvalue = (e) => {
    //alert(e.target.value);
    fetch_data_with_limit(
      "products",
      setProductNamedetails,
      "prod_name",
      e.target.value,
      "id",
      "limit 1"
    );
    fetch_data_with_limit(
      "inventory",
      setInventoryData,
      "prod_name",
      e.target.value,
      "id",
      "limit 1"
    );
  };


  const cal = (e) => {
    
    let total =
    parseFloat(InventoryData.balance) -
    parseFloat(e.target.value)
   // alert(formdata.qty);
    setmybalance(total);
    
  };



  const [myref, setmyref] = useState(0);
  const [myvendor, setmyvendor] = useState(0);
  const [mybalance, setmybalance] = useState(0);
  const [myinvdate, setmyinvdate] = useState(0);
  const [mytotalcost, setmytotalcost] = useState(0);
  const [LastID, setLastID] = useState(0);
  const [netamount, setNetamount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  
  const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    const currentDate = year + "/" + month + "/" + date;


  
  const saveExpense = async(e) => {
    e.preventDefault();
    const formdata1 = e.target;
    let total =
      parseFloat(formdata1.prev_balance1.value) +
      parseFloat(formdata1.summary_total_value.value);
      const post1 =  axios.post( "/insertdata/expenses",
        {
          purchase_id: data[0].id,
          date: currentDate,
          invdte: myinvdate,
          userid: localStorage.getItem("uname"),
          vendor: myvendor,
          group_name: "Liabilities",
          subgroup_name: "Current Liabilities",
          head: headdata.head_name,
          subhead: VenderData.vendor_name,
          ref: myref,
          payment_mode: "credit",
          bank: "NA",
          credit: formdata1.summary_total_value.value,
          vat: formdata1.summary_tax_value.value,
          disc: "0",
          debit: "0",
          balance: total,
          remark: "Credit voucher",
        },
        { headers }
      );
     
      try {
        await Promise.all([post1]);
        // All requests are successful
        setFormData({
          ref: "",
         
        });
        //BillTotal1("0");
        fetch_data_with_limit( "expenses",setVenderData, "vendor", myvendor, "id","limit 1");
      // Show success notification
      toast.success('Data saved successfully!');
      //toast.error('An error occurred while making the POST requests');
      //alert("success");
      } catch (err) {
            // Handle errors
            //setError('An error occurred while making the POST requests');
            toast.error('An error occurred while making the POST requests');
            console.error('Error:', err);
      } finally {
        setLoading(false);
      }
     
   
  };
  const handleSubmit = async(e) => {
    e.preventDefault();
    const formdata1 = e.target;

   // setmybalance(formdata1.prev_balance.value);
    setmyvendor(formdata1.vendor_name.value);
  
    setmyinvdate(formdata1.dte.value);
    // const post1 = axios.post("/insertdata/purchases",
    //     {
    //       store_name: formdata1.store_name.value,
    //       // vendor_id: VendorName.id,
    //       vendor_name: formdata1.vendor_name.value,
    //       product_id: ProductNamedetails.prod_code,
    //       prod_name: ProductNamedetails.prod_name,
    //       category: ProductNamedetails.category,
    //       purchase_date: formdata1.dte.value,
    //       ref: formdata1.ref.value,
    //       qty: formdata1.qty.value,
    //       unit_price: formdata1.rate.value,
    //       disc: formdata1.disc.value,
    //       subtotal: subtotal_cost,
    //       tax_percent: ProductNamedetails.tax,
    //       tax_value: ptaxvalue,
    //       total_cost: ptotal_cost,
    //     },
    //     { headers }
    //   );
      let total =
      parseFloat(formdata1.obqty.value) +
      parseFloat(formdata1.qty.value);
    const post2 =   axios.post( "/insertdata/inventory",
      {
        userid:localStorage.getItem("uname"),
        pdate:formdata1.dte.value ,
        category: ProductNamedetails.category,
        purchase_id: "0",
        prod_id: ProductNamedetails.prod_code,
        prod_name: ProductNamedetails.prod_name,
        dept: formdata1.vendor_name.value,
        stockin:"0" ,
        stockout: formdata1.qty.value,
        balance: mybalance,
      },
      { headers }
    );


    try {
      await Promise.all([ post2]);
      fetchData("inventory", setData, "id");
    //  fetchData_singlePara("inventory", setData, "ref", myref, "id");
     // BillTotal1(myref);
    toast.success('Stock Out Successfully!');
    setLastID(data[0].id);
   
    setFormData({
      product_id: "",
      prod_name: "",
      category: "",
      qty: "0",
      rate: "0",
      disc: "0",
      subtotal: "",
      tax_percent: "",
      tax_value: "",
      total_cost: "",
    });
      
    } catch (err) {
      // Handle errors
      //setError('An error occurred while making the POST requests');
      toast.error("Error in creating new product.Please Check again.");
      console.error("Error:", err);
    } finally {
      setLoading(false);
    }
   
    
    // handleAddRecord(formdata);
  };

  //Table data record
  // const [data, setData] = useState([]);
  const [itemsperpage, setitemsperpage] = useState([10]);
  const [columns, setColumns] = useState([]);

  const handleDelete = async (id) => {
    deleteRecord("purchases", id, "id");
    //alert(myref);
    //fetchData("purchases", setData, "id");
    fetchData_singlePara("purchases", setData, "ref", myref, "id");
    //BillTotal1(myref);
  };
  useEffect(() => {
    fetchData("store", setstorename, "id");
    fetchData("departments", setVendorname, "id");
    fetchData("products", setProductName, "id");
    fetchData("inventory", setData, "id");
    //fetchData("purchases", setData, "id");
  }, []);

  return (
    <>
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <Breadcums heading="Stock Issue" paglink="Stockout" />
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              {/* left column */}
              <div className="col-md-12">
                <form onSubmit={handleSubmit}>
                  {/* <div className="row bg-dark">
                    <div className="col-3">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Store Name</label>
                        <select
                          className="form-control form-control-sm select2"
                          id="store_name"
                          onChange={(e) => handleChange(e)}
                          value={formdata.store_name}
                        >
                          <option selected="NA">--Select--</option>
                          {storename &&
                            storename.map((seldata, index) => {
                              return (
                                <option value={seldata.store_name}>
                                  {seldata.store_name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                   
                    
                  </div> */}
                  {/* <div className="dropdown-divider" /> */}
                  <div className="row">
            <div className="col-md-3">
                <div className="card card-dark">
                  <div className="card-header">
                    <h3 className="card-title">Product Details</h3>
                  </div>
                 
                  <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          Issue Date
                          
                        </label>
                        <input
                          type="date"
                          className="form-control form-control-sm"
                          id="dte"
                          onChange={(e) => handleChange(e)}
                          value={formdata.dte}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          Select Department
                        </label>
                        <select
                          className="form-control form-control-sm select2"
                          id="vendor_name"
                          onChange={(e) => handleChange1(e)}
                          value={formdata.vendor_name}
                        >
                          <option selected="NA">--Select--</option>
                          {VendorName &&
                            VendorName.map((seldata, index) => {
                              return (
                                <option value={seldata.department_name}>
                                  {seldata.department_name}/{seldata.department_head}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          Choose Product
                        </label>
                        <select
                          className="form-control form-control-sm select2"
                          id="prod_name"
                          onChange={(e) => getproductvalue(e)}
                          value={ProductNamedetails.prod_name}
                        >
                          <option selected="NA">--Select--</option>
                          {ProductName &&
                            ProductName.map((seldata, index) => {
                              return (
                                <option value={seldata.prod_name}>
                                  {seldata.prod_name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1"> Opn. Stock</label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          id="obqty"
                          disabled
                          onChange={(e) => handleChange(e)}
                          onKeyUp={(e) => cal(e)}
                          value={InventoryData.balance}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1"> Qty</label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          id="qty"
                          onChange={(e) => handleChange(e)}
                          onKeyUp={(e) => cal(e)}
                          value={formdata.qty}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1"> Closing Balance</label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          id="cbalance"
                          onChange={(e) => handleChange(e)}
                          onKeyUp={(e) => cal(e)}
                          value={mybalance}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <button
                          type="submit"
                          id="savedata"
                          className="btn btn-dark  float-right"
                        >
                          Save Data
                        </button>
                        <ToastContainer />
                      </div>
                      </div>
                   
                  
                </div>
              </div>
              <div className="col-md-9">
                {/* general form elements */}
                <div className="card card-dark">
                  <FetchDataComponent
                    tblname="inventory"
                    setData={setData}
                    setColumns={setColumns}
                   
                    id="id"
                  />

                  {/* This component fetches the data and determines columns */}
                  <Pagination
                    data={data}
                    columns={columns}
                    handleDelete={handleDelete}
                    itemsPerPage={itemsperpage}
                  />
                </div>
              </div>
             
            </div>

                </form>
              </div>
            </div>
            {/* general form elements */}

           
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}
    </>
  );
}
