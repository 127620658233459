import React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { format } from "date-fns";
import authheader from "../../services/authheader";
import { deleteRecord } from "../../util/deleteRecord";
import { fetchData } from "../../util/fetchData";
import { checkToken } from "../../services/checkToken";
import FetchDataComponent from "../../components/tables/fetchTableData";
import Pagination from "../../components/tables/ShowTableData";
import Breadcums from "../../common/Breadcums";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Newuser() {
  try {
    checkToken();
  } catch (error) {
    alert("Session logged out.Please logged in again");
    localStorage.clear();
    // history.push('/');
    window.location.href = "/";
  }
  let currentDate = format(new Date(), "yyyy-MM-dd");
  const headers = { Authorization: authheader().access_token };
  const [data, setData] = useState([]);
  const [errors, setErrors] = useState({});
  const [formdata, setFormData] = useState({
    name: "",
    pass: "",
    contact: "",
    email: "",
    type: "",
    lastloggedin: currentDate,
  });
  const handleChange = (e) => {
    setFormData({ ...formdata, [e.target.id]: e.target.value });
  };
  //handle add record
  const handleAddRecord = (newRecord) => {
    setData([...data, newRecord]);
    fetchData("users", setData, "id");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formdata1 = e.target;
  
    axios
      .post(
        "/register",
        {
          name: formdata1.name.value,
          pass: formdata1.pass.value,
          contact: formdata1.contact.value,
          email: formdata1.email.value,
          type: formdata1.usertype.value,
          lastloggedin: currentDate,
        },
        { headers }
      )
      .then((res) => {
        fetchData("users", setData, "id");
        toast.success('user added successfully!');
       
       setFormData({
        name: "",
    pass: "",
    contact: "",
    email: "",
    type: "",
    lastloggedin: currentDate,
       })
      })
      .catch((err) => {
       // alert(err.message)
       toast.error('Error in adding user');
        console.log(err.message);
      });
    // handleAddRecord(formdata);
    setFormData({});
    setErrors({});
  };

  //Table data record
  // const [data, setData] = useState([]);
  const [itemsperpage, setitemsperpage] = useState([10]);
  const [columns, setColumns] = useState([]);

  const changeItem = (e) => {
    setitemsperpage(parseInt(e.target.value, 10));
    console.log(itemsperpage.items);
  };
  const handleDelete = async (id) => {
    deleteRecord("users", id, "id");
    fetchData("users", setData, "id");
  };

  return (
    <>
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <Breadcums heading="User Registration" paglink="New User"/>
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              {/* left column */}
              <div className="col-md-12">
                {/* general form elements */}
                <div className="card card-dark">
                  <div className="card-header">
                    <h3 className="card-title">Add New User</h3>
                  </div>
                  {/* /.card-header */}
                  {/* form start */}
                  <form onSubmit={handleSubmit}>
                    <div className="card-body">
                      <div className="row">
                      <div className="col-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              User Name
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              id="name"
                              onChange={(e) => handleChange(e)}
                              value={formdata.name}
                              
                            />
                          </div>
                        </div>
                       
                        <div className="col-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Password
                            </label>
                            <input
                              type="password"
                              className="form-control form-control-sm"
                              id="pass"
                              onChange={(e) => handleChange(e)}
                              value={formdata.pass}
                              
                            />
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Contact No.
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              id="contact"
                              onChange={(e) => handleChange(e)}
                              value={formdata.contact}
                              
                            />
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                             Email
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              id="email"
                              onChange={(e) => handleChange(e)}
                              value={formdata.email}
                              
                            />
                          </div>
                        </div>
                      <div className="col-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              User Type
                            </label>
                            <select 
                        className="form-control form-control-sm select2"
                        id="usertype"
                        onChange={(e) => handleChange(e)}
                        value={formdata.usertype}
                        >
                            <option value="NA"> --Select--</option>
                            <option value="admin"> Admin</option>
                            <option value="account"> Accounts</option>
                            <option value="manager"> Manager</option>
                            <option value="store"> Store</option>
                            <option value="cashier"> Cashier</option>
                          </select>
                        </div>
                        </div>
                        <div className="col-2">
                        <label htmlFor="exampleInputEmail1">
                             </label>
                        <button
                        type="submit"
                        class="btn btn-dark float-right mt-4"
                      >
                        Add New User
                      </button>
                      <ToastContainer/>
                        </div>
                      </div>
                      
                    </div>
                    {/* /.card-body */}
                   
                  </form>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                {/* general form elements */}
                <div className="card card-dark">
                  <FetchDataComponent
                    tblname="users"
                    setData={setData}
                    setColumns={setColumns}
                    id="id"
                  />

                  {/* This component fetches the data and determines columns */}
                  <Pagination
                    data={data}
                    columns={columns}
                    handleDelete={handleDelete}
                    itemsPerPage={itemsperpage}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}
    </>
  );
}
