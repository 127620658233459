import {} from 'react-router-dom'
import axios from 'axios'
import authheader from '../services/authheader'
import { checkToken } from '../services/checkToken'

export const fetchData = async (tblname, setData, orderby) => {
  checkToken()
  const headers = { Authorization: authheader().access_token }
  // try {
  axios
    .get('/viewalldata/' + tblname + '/' + orderby, { headers })
    .then(res => {
      // alert("fetch data1");
       console.log(res.data.data);
      if (tblname === 'bill_format') {
        const jsonData = res.data.data[0]
       // console.log(jsonData)
        setData(jsonData)
      } else {
        const jsonData = res.data.data
       // console.log(jsonData)
        setData(jsonData)
      }
    })
    .catch(err => {
      console.error('Error fetching data:', err)
    })
}
