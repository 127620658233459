import React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import authheader from "../../services/authheader";
import { fetchData_singlePara } from "../../util/fetchDataby_singlePara";
import { fetch_data_with_limit } from "../../util/fetch_Data_with_limit";
import { deleteRecord } from "../../util/deleteRecord";
import { fetchData } from "../../util/fetchData";
import { checkToken } from "../../services/checkToken";
import FetchDataComponent from "../../components/tables/fetchTableData";
import Pagination from "../../components/tables/ShowTableData";
import Breadcums from "../../common/Breadcums";
import { getOrder } from "../../util/getOrder";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function NewProduct() {
  try {
    checkToken();
  } catch (error) {
    alert("Session logged out.Please logged in again");
    localStorage.clear();
    // history.push('/');
    window.location.href = "/";
  }
  const headers = { Authorization: authheader().access_token };
  const [data, setData] = useState([]);
  const [Selectdata, setSelectData] = useState([]);
  const [subcategory, setsubcategory] = useState([]);
  const [ProductNamedetails, setProductNamedetails] = useState({});
  const [Ordernum, setOrdernum] = useState(0);
  const [subhead, setSubHead] = useState([]);
  const [selecttax, setselectTax] = useState([]);
  const [selectUnit, setselectUnit] = useState([]);
  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({});
  const today = new Date();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const date = today.getDate();
  const currentDate = year + "/" + month + "/" + date;
  const [formdata, setFormData] = useState({
    company_name: "",
    customer_name: "",
    contact: "",
    street1: "",
    street2: "",
    city: "",
    pincode: "",
    gst: "",
    ob: "",
  });
  const handleChange1 = (e) => {
    setFormData({ ...formdata, [e.target.id]: e.target.value });
    fetchData_singlePara(
      "subcategory",
      setsubcategory,
      "group_name",
      e.target.value,
      "id"
    );
  };
  const getSubHead = (e) => {
    setFormData({ ...formdata, [e.target.id]: e.target.value });

    fetchData_singlePara(
      "subheads",
      setSubHead,
      "head_name",
      formdata.head,
      "id"
    );
  };
  const handleChange = (e) => {
    setFormData({ ...formdata, [e.target.id]: e.target.value });
  };
  const handleClick = () => {
    const randomBarcodeNumber = Math.floor(Math.random() * 1000000000);
    setFormData({ barcode1: randomBarcodeNumber });
  };
  //handle add record
  const handleAddRecord = (newRecord) => {
    setData([...data, newRecord]);
    fetchData("products", setData, "id");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formdata1 = e.target;
    const post1 = axios.post(
      "/insertdata/products",
      {
        prod_code: formdata1.prod_code.value,
        barcode1: formdata1.barcode1.value,
        barcode2: formdata1.barcode2.value,
        prod_name: formdata1.prod_name.value,
        category: formdata1.category.value,
        subcategory: formdata1.subcategory.value,
        unit: formdata1.unit.value,
        sku: formdata1.sku.value,
        hsn: formdata1.hsn.value,
        min_qty: formdata1.min_qty.value,
        description: "",
        tax: formdata1.tax.value,
        wsale: formdata1.wsale.value,
        rsale: formdata1.mrp.value,
      },
      { headers }
    );
    const post2 = axios.post(
      "/insertdata/inventory",
      {
        userid: localStorage.getItem("uname"),
        pdate: currentDate,
        purchase_id: "0",
        category: formdata1.category.value,
        prod_id: formdata1.prod_code.value,
        prod_name: formdata1.prod_name.value,
        stockin: "0",
        stockout: "0",
        balance: formdata1.ob.value,
      },
      { headers }
    );
    try {
      await Promise.all([post1, post2]);
      fetchData("products", setData, "id");
     
      getOrder("products", setOrdernum, "prod_code");
      setFormData({
        
        prod_name: "",
        category: "",
        barcode1: "",
        barcode2: "",
        sku: "",
        hsn: "",
        min_qty: "0",
        wsale: "0",
        rsale: "0",
        ob: "0",
        qty: "0",
        rate: "0",
        disc: "0",
        subtotal: "",
        tax_percent: "",
        tax_value: "",
        total_cost: "",
      });
      toast.success("Product added successfully!");
      //toast.error('An error occurred while making the POST requests');
      //alert("success");
    } catch (err) {
      // Handle errors
      //setError('An error occurred while making the POST requests');
      toast.error("Error in creating new product.Please Check again.");
      console.error("Error:", err);
    } finally {
      setLoading(false);
    }
  };

  //Table data record
  // const [data, setData] = useState([]);
  const [itemsperpage, setitemsperpage] = useState([10]);
  const [columns, setColumns] = useState([]);

  const changeItem = (e) => {
    setitemsperpage(parseInt(e.target.value, 10));
    console.log(itemsperpage.items);
  };
  const handleDelete = async (id) => {
    deleteRecord("products", id, "id");
    fetchData("products", setData, "id");
  };
  useEffect(() => {
    getOrder("products", setOrdernum, "prod_code");
    fetchData("category", setSelectData, "id");
    fetchData("taxes", setselectTax, "id");
    fetchData("units", setselectUnit, "id");

    fetchData("products", setData, "id");
  }, []);

  return (
    <>
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <Breadcums heading="Product Information" paglink="New Product" />
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              {/* left column */}
              <div className="col-md-12">
                {/* general form elements */}
                <div className="card ">
                  {/* <div className="card-header">
                    <h3 className="card-title">Add New Product</h3>
                  </div> */}
                  {/* /.card-header */}
                  {/* form start */}
                  <form onSubmit={handleSubmit}>
                    <div className="card-body  ">
                      <div className="row">
                        <div className="col-3">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Product Code
                            </label>
                            <input
                              type="text"
                              disabled
                              className="form-control form-control-sm"
                              id="prod_code"
                              onChange={(e) => handleChange(e)}
                              value={Ordernum}
                            />
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Barcode 1{" "}
                              <i
                                className="fa fa-barcode ml-2"
                                data-bs-toggle="tooltip"
                                title=""
                                onClick={handleClick}
                                data-bs-original-title="fa fa-barcode"
                                aria-label="fa fa-barcode"
                              ></i>
                            </label>
                            <input
                              type="text"
                              required
                              className="form-control form-control-sm"
                              id="barcode1"
                              onChange={(e) => handleChange(e)}
                              value={formdata.barcode1}
                            />
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Barcode 2
                              <i
                                className="fa fa-barcode ml-2"
                                data-bs-toggle="tooltip"
                                title=""
                                onClick={handleClick}
                                data-bs-original-title="fa fa-barcode"
                                aria-label="fa fa-barcode"
                              ></i>
                            </label>
                            <input
                              type="text"
                              required
                              className="form-control form-control-sm"
                              id="barcode2"
                              onChange={(e) => handleChange(e)}
                              value={formdata.barcode2}
                            />
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Product Name
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              id="prod_name"
                              onChange={(e) => handleChange(e)}
                              value={formdata.prod_name}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Category</label>
                            <select
                              className="form-control form-control-sm select2"
                              id="category"
                              onChange={(e) => handleChange1(e)}
                              value={Selectdata.group_name}
                            >
                              <option selected="NA">--Select--</option>
                              {Selectdata &&
                                Selectdata.map((seldata, index) => {
                                  return (
                                    <option value={seldata.brand_name}>
                                      {seldata.brand_name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Sub Category
                            </label>
                            <select
                              className="form-control form-control-sm select2"
                              id="subcategory"
                              onChange={(e) => handleChange(e)}
                              value={formdata.subcategory}
                            >
                              <option selected="NA">--Select--</option>
                              {subcategory &&
                                subcategory.map((seldata, index) => {
                                  return (
                                    <option value={seldata.subgroup_name}>
                                      {seldata.subgroup_name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>

                        <div className="col-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Unit</label>
                            <select
                              className="form-control form-control-sm select2"
                              id="unit"
                              onChange={(e) => handleChange(e)}
                              value={formdata.unit}
                            >
                              <option selected="NA">--Select--</option>
                              {selectUnit &&
                                selectUnit.map((seldata, index) => {
                                  return (
                                    <option value={seldata.unit}>
                                      {seldata.unit}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">SKU</label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              id="sku"
                              onChange={(e) => handleChange(e)}
                              value={formdata.sku}
                            />
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">HSN</label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              id="hsn"
                              onChange={(e) => handleChange(e)}
                              value={formdata.hsn}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-1">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Min. Qty</label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              id="min_qty"
                              onChange={(e) => handleChange(e)}
                              value={formdata.min_qty}
                            />
                          </div>
                        </div>

                        <div className="col-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Tax</label>
                            <select
                              className="form-control form-control-sm select2"
                              id="tax"
                              onChange={(e) => handleChange(e)}
                              value={formdata.tax}
                            >
                              <option selected="NA">--Select--</option>
                              {selecttax &&
                                selecttax.map((seldata, index) => {
                                  return (
                                    <option value={seldata.tax_value}>
                                      {seldata.tax_name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Wholesale Price
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              id="wsale"
                              onChange={(e) => handleChange(e)}
                              value={formdata.wsale}
                            />
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">MRP</label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              id="mrp"
                              onChange={(e) => handleChange(e)}
                              value={formdata.mrp}
                            />
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">OB</label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              id="ob"
                              onChange={(e) => handleChange(e)}
                              value={formdata.ob}
                            />
                          </div>
                        </div>
                        <div className="col-2">
                          <button
                            type="submit"
                            class="btn btn-dark mt-4 float-right"
                          >
                            Save Data
                          </button>
                          <ToastContainer />
                        </div>
                      </div>
                    </div>
                    {/* /.card-body */}
                  </form>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                {/* general form elements */}
                <div className="card card-dark">
                  <FetchDataComponent
                    tblname="products"
                    setData={setData}
                    setColumns={setColumns}
                    id="id"
                  />

                  {/* This component fetches the data and determines columns */}
                  <Pagination
                    data={data}
                    columns={columns}
                    handleDelete={handleDelete}
                    itemsPerPage={itemsperpage}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}
    </>
  );
}
