import React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import authheader from "../../services/authheader";
import { deleteRecord } from "../../util/deleteRecord";
import { fetchData } from "../../util/fetchData";
import { acquitance_byname } from "../../util/acquitance";
import { fetchData_singlePara } from "../../util/fetchDataby_singlePara";
import { filterdatabydate } from "../../util/filterdatabydate";
import { acquitance_bydatename } from "../../util/acquitance baydatename";
import { ledgerSummary } from "../../util/ledger_summary";
import { ledgerSummarybydatename } from "../../util/ledgersummarybydatename";
import { ledgerSummarybydate } from "../../util/ledgersummarybydate";
import { checkToken } from "../../services/checkToken";
import FetchDataAcquitance from "../../components/tables/fetchDataAcuitance";
import ShowLedgerReports from "../../components/tables/showAcquitanceReport";

import Breadcums from "../../common/Breadcums";

export default function Acquitance() {
  try {
    checkToken();
  } catch (error) {
    alert("Session logged out.Please logged in again");
    localStorage.clear();
    // history.push('/');
    window.location.href = "/";
  }
  const headers = { Authorization: authheader().access_token };
  const [data, setData] = useState([]);

  const [VendorName, setVendorname] = useState([]);
  const [labourName, setlabourname] = useState([]);
  const [SummaryData, setSummaryData] = useState([]);
  const [fromdate, setfromdate] = useState([]);
  const [todate, settodate] = useState([]);
  const [transaction, settransaction] = useState([]);

  const [errors, setErrors] = useState({});
  const [Selectdatagroup, setSelectDatagroup] = useState([]);
  const [formdata, setFormData] = useState({
    company_name: "",
  });

  const [selectedMonthYear, setSelectedMonthYear] = useState("");
  const [previousMonthYear, setPreviousMonthYear] = useState("");
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const years = Array.from(new Array(3), (val, index) => index + 2024);

  const monthYearOptions = [];
  years.forEach((year) => {
    months.forEach((month) => {
      monthYearOptions.push(`${month} ${year}`);
    });
  });

  const handleMonthYearChange = (e) => {
    const selected = e.target.value;
    setSelectedMonthYear(e.target.value);
    calculatePreviousMonthYear(selected);
  };
  const calculatePreviousMonthYear = (currentMonthYear) => {
    const [currentMonth, currentYear] = currentMonthYear.split(" ");
    const currentMonthIndex = months.indexOf(currentMonth);
    let previousMonthIndex = currentMonthIndex - 1;
    let previousYear = parseInt(currentYear, 10);

    if (previousMonthIndex < 0) {
      previousMonthIndex = 11; // Wrap around to December
      previousYear -= 1; // Decrement the year
    }

    const previousMonth = months[previousMonthIndex];
    setPreviousMonthYear(`${previousMonth} ${previousYear}`);
  };

  const handleChange = (e) => {
    setFormData({ ...formdata, [e.target.id]: e.target.value });
  };
  
  const filterbydate = (e) => {
    e.preventDefault();
    const formdata1 = e.target;
    //alert(formdata.frm);
   
    filterdatabydate("income", setData, "date",formdata.frm,"date",formdata.to, "id");
    ledgerSummarybydate(
      "income",
      setSummaryData,
      "date",
      formdata.frm,
      "date",
      formdata.to,
      "credit",
      "debit",
      "tax",
      "disc",
    )
  
  };
  const filterbydatename = (e) => {
    e.preventDefault();
    const formdata1 = e.target;
    //alert(formdata.frm);
   
    acquitance_bydatename("income", setData, "date",formdata.frm,"date",formdata.to,"subhead",formdata.vendor_name, "main_account");
    ledgerSummarybydatename(
      "income",
      setSummaryData,
      "date",
      formdata.frm,
      "date",
      formdata.to,
      "subhead",
      formdata.vendor_name,
      "credit",
      "debit",
      "tax",
      "disc",
    )
  
  };
  const handleChange1 = (e) => {
    setFormData({ ...formdata, [e.target.id]: e.target.value });

    acquitance_byname("income", setData, "subhead", e.target.value, "main_account");
    ledgerSummary(
      "income",
      setSummaryData,
      "subhead",
      e.target.value,
      "credit",
      "debit",
      "tax",
      "disc",
      "id"
    );
  };
  const handleChange2 = (e) => {
    setFormData({ ...formdata, [e.target.id]: e.target.value });

    fetchData_singlePara("labour_salary", setData, "salary_month", e.target.value, "id");
  
  };
  //Table data record
  // const [data, setData] = useState([]);
  const [itemsperpage, setitemsperpage] = useState([10]);
  const [columns, setColumns] = useState([]);

  const handleDelete = async (id) => {
    deleteRecord("income", id, "id");
    fetchData("income", setData, "id");
  };
  useEffect(() => {
    fetchData("transactions", settransaction, "id");
    //fetchData("income", setData, "id");
    fetchData("customers", setVendorname, "id");
    fetchData("labour_profiles", setlabourname, "id");
    // ledgerSummary(
    //   "income",
    //   setSummaryData,
    //   "subhead",
    //   "all",
    //   "credit",
    //   "debit",
    //   "tax",
    //   "disc",
    //   "id"
    // );
  }, []);
  return (
    <>
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <Breadcums heading="Acquitance Roll Labour " paglink="Acquitance Roll Labour" />
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              {/* left column */}
              <div className="col-md-12">{/* <Transport_bar /> */}</div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card card-info card-outline">
                  <div className="card-body">
                  <div className="row">
                    <div className="col-3">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          Search by Staff
                        </label>
                        <select
                          className="form-control form-control-sm select2"
                          id="vendor_name"
                          onChange={(e) => handleChange1(e)}
                          value={formdata.vendor_name}
                        >
                          <option selected="all">All</option>
                          {labourName &&
                            labourName.map((seldata, index) => {
                              return (
                                <option value={seldata.first_name} >
                                  {seldata.first_name} {seldata.last_name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">From</label>
                        <input
                          type="date"
                          className="form-control form-control-sm"
                          id="frm"
                          onChange={(e) => handleChange(e)}
                          value={formdata.frm}
                        />
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">To</label>
                        <input
                          type="date"
                          className="form-control form-control-sm"
                          id="to"
                          onChange={(e) => handleChange(e)}
                          value={formdata.to}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Payment Mode
                            </label>
                            <select
                              className="form-control form-control-sm select2"
                              id="imode"
                              onChange={(e) => handleChange1(e)}
                              value={formdata.imode}
                            >
                              <option selected="NA">--Select--</option>
                              {transaction &&
                                transaction.map((seldata, index) => {
                                  return (
                                    <option value={seldata.mode}>
                                      {seldata.mode}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                          </div>

                          <div className="col-md-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Select Month
                            </label>
                            <select
                              className="form-control form-control-sm select2"
                              id="selectmonth"
                              value={selectedMonthYear}
                              onChange={(e) => handleChange2(e)}
                             // onChange={handleChange1}
                            >
                              <option value="" disabled>
                                Select Month and Year
                              </option>
                              {monthYearOptions.map((option, index) => (
                                <option key={index} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                            {/* {selectedMonthYear && (
        <div>
          <p>Selected Month and Year: {selectedMonthYear}</p>
          <p>Previous Month and Year: {previousMonthYear}</p>
        </div>
      )} */}
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                 
               
                  <div className="col-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-sm-12">
                          <div className="row">
                          <div className="col-3">
                            <div className="form-group">
                              <Link type="submit" onClick={filterbydate} className="btn btn-dark mt-4">
                                Search by date
                              </Link>
                            </div>
                            </div>
                            <div className="col-3">
                            <div className="form-group">
                            <Link type="submit" onClick={filterbydatename} className="btn btn-dark mt-4">
                                Search by Date & Name
                              </Link>
                             
                            </div>
                            </div>
                            <div className="col-4">
                            <div className="form-group">
                            <Link type="submit" onClick={filterbydatename} className="btn btn-dark mt-4">
                                Search by Date & Name & Mode
                              </Link>
                             
                            </div>
                            </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* general form elements */}
                <div className="card card-dark">
                  
                  <FetchDataAcquitance
                    tblname="income"
                    setData={setData}
                    setColumns={setColumns}
                    id="id"
                  />

                  {/* This component fetches the data and determines columns */}
                  <ShowLedgerReports
                    data={data}
                    columns={columns}
                    handleDelete={handleDelete}
                    itemsPerPage={itemsperpage}
                    credit={SummaryData.credit}
                    debit={SummaryData.debit}
                    vat={SummaryData.vat}
                    disc={SummaryData.disc}
                    type="income"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}
    </>
  );
}