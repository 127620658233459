import React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import authheader from "../../services/authheader";
import { deleteRecord } from "../../util/deleteRecord";
import { fetchData } from "../../util/fetchData";
import { fetchData_singlePara } from "../../util/fetchDataby_singlePara";
import { checkToken } from "../../services/checkToken";
import FetchDataComponent from "../../components/tables/fetchTableData";
import Pagination from "../../components/tables/ShowTableData";
import Breadcums from "../../common/Breadcums";
import Transport_bar from "../../common/Transport_bar";

export default function Availability() {
  try {
    checkToken();
  } catch (error) {
    alert("Session logged out.Please logged in again");
    localStorage.clear();
    // history.push('/');
    window.location.href = "/";
  }
  const headers = { Authorization: authheader().access_token };
  const [data, setData] = useState([]);
  const [errors, setErrors] = useState({});
  const [Selectdatagroup, setSelectDatagroup] = useState([]);
  const [Selectdatasubgroup, setSelectDatasubgroup] = useState([]);
  const [formdata, setFormData] = useState({
    company_name: "",
    customer_name: "",
    contact: "",
    street1: "",
    street2: "",
    city: "",
    pincode: "",
    gst: "",
    ob: "",
  });
  const handleChange = (e) => {
    setFormData({ ...formdata, [e.target.id]: e.target.value });
  };
  //handle add record
  const handleAddRecord = (newRecord) => {
    setData([...data, newRecord]);
    fetchData("vehicle_logs", setData, "id");
  };
  const handleChange1 = (e) => {
    // console.log(e.target.value);
     setFormData({ ...formdata, [e.target.id]: e.target.value });
      
     fetchData_singlePara("subgroups", setSelectDatasubgroup,"group_name",e.target.value, "id");
    // console.log(setSelectDatagroup);
   };
  const handleSubmit = (e) => {
    e.preventDefault();
    const formdata1 = e.target;
    axios
      .post(
        "/insertdata/vehicle_logs",
        {
          owner: formdata1.owner.value,
          make: formdata1.make.value,
          model: formdata1.model.value,
          year: formdata1.year.value,
          regno: formdata1.regno.value,
          
        },
        { headers }
      )
      .then((res) => {
        fetchData("vehicle_logs", setData, "id");

        const newRecord = res;
        handleAddRecord(newRecord);
        setFormData({
         
          owner: "",
          make: "",
          model: "",
          year: "",
          regno: "",
         
        });
        //navigate("/");
      })
      .catch((err) => {
        console.log(err.message);
      });
    // handleAddRecord(formdata);
    setFormData({});
    setErrors({});
  };

  //Table data record
  // const [data, setData] = useState([]);
  const [itemsperpage, setitemsperpage] = useState([10]);
  const [columns, setColumns] = useState([]);


  const handleDelete = async (id) => {
    deleteRecord("vehicle_logs", id, "id");
    fetchData("vehicle_logs", setData, "id");
  };
  useEffect(() => {
    fetchData("vehicle_logs", setSelectDatagroup,"id");
    
  }, []);
  return (
    <>
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <Breadcums heading="Booking & Scheduling" paglink="Vehicle In Out"/>
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              {/* left column */}
              <div className="col-md-12">
                   <Transport_bar />
              </div>
              
            </div>
           
            <div className="row">
              <div className="col-md-12">
                {/* general form elements */}
                <div className="card card-dark">
                <div className="row">
                  <div className="col-4">
                       
                   </div>
                   <div className="col-4">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Vehicle Status
                            </label>
                            <select 
                              className="form-control form-control-sm select2"
                              id="owner"
                              onChange={(e) => handleChange1(e)}
                              value={formdata.owner}
                              >
                          
                        <option selected value="NA">--Select--</option>
                        
                        <option value="close">Not Available</option>
                        <option value="available">Available</option>
                      
                  </select>
                          </div>
                   </div>
                </div>
                  <FetchDataComponent
                    tblname="vehicle_logs"
                    setData={setData}
                    setColumns={setColumns}
                    id="id"
                  />

                  {/* This component fetches the data and determines columns */}
                  <Pagination
                    data={data}
                    columns={columns}
                    handleDelete={handleDelete}
                    itemsPerPage={itemsperpage}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}
    </>
  );
}
