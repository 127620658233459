import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../Context/AuthProvider";
export default function CashierSidebar() {
  const { pathname } = useLocation();
  const { user } = useAuth();
  return (
    <aside className="main-sidebar sidebar-dark-dark elevation-4">
      {/* Brand Logo */}
      <Link to="/dashboard/admin" className="brand-link">
        <img
          src="../../../dist/img/dilogo.png"
          alt="AdminLTE Logo"
          className="brand-image img-circle elevation-3"
          style={{ opacity: "1" }}
        />
        <span className="brand-text font-weight-900">JD AEROMATICS</span>
      </Link>
      {/* Sidebar */}
      <div className="sidebar">
        {/* Sidebar user (optional) */}
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
          <div className="image">
            <img
              src="../../../dist/img/user2-160x160.jpg"
              className="img-circle elevation-2"
              alt="User Image"
            />
          </div>
          <div className="info">
            <Link to="#" className="d-block">
              {user.role}
            </Link>
          </div>
        </div>
        {/* SidebarSearch Form */}
        <div className="form-inline">
          <div className="input-group" data-widget="sidebar-search">
            <input
              className="form-control form-control-sidebar"
              type="search"
              placeholder="Search"
              aria-label="Search"
            />
            <div className="input-group-append">
              <button className="btn btn-sidebar">
                <i className="fas fa-search fa-fw" />
              </button>
            </div>
          </div>
        </div>
        {/* Sidebar Menu */}
        <nav className="mt-2">
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            {/* Add icons to the links using the .nav-icon class
         with font-awesome or any other icon font library */}

            <li className="nav-item">
              <Link
                to="/dashboard/admin"
                className={
                  pathname === "/dashboard/admin" ? "nav-link active" : "nav-link"
                }
              >
                <i className="nav-icon fas fa-tachometer-alt" />
                <p>Dashboard</p>
              </Link>
            </li>

            
            <li className="nav-item">
              <Link to="#" className="nav-link">
                <i class="nav-icon fas fa-store"></i>
                <p>
                  Products
                  <i className="right fas fa-angle-left" />
                </p>
              </Link>
              <ul className="nav nav-treeview">
              <li className="nav-item">
                  <Link
                    to="/products/category"
                    className={
                      pathname === "/products/category"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>Product Category </p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/products/subcategory"
                    className={
                      pathname === "/products/subcategory"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>Product Sub Category </p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/products/newproduct"
                    className={
                      pathname === "/customers/newcustomer"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>New Product </p>
                  </Link>
                </li>
              </ul>
            </li>
          
          {/* Sale */}
            <li className="nav-item">
              <Link to="#" className="nav-link">
              <i class="nav-icon fas fa-money-bill"></i>
                <p>
                  Sale
                  <i className="right fas fa-angle-left" />
                </p>
              </Link>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <Link
                    to="/sale/makeinvoice"
                    className={
                      pathname === "/sale/makeinvoice"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>Tax Invoice </p>
                  </Link>
                </li>
              
                <li className="nav-item">
                  <Link
                    to="/sale/taxinvoice"
                    className={
                      pathname === "/sale/quotation"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>Quotation </p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/sale/invoicedetails"
                    className={
                      pathname === "/sale/invoicedetails"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>Invoice Details</p>
                  </Link>
                </li>
              </ul>
            </li>

            <li className="nav-item">
              <Link to="#" className="nav-link">
                <i class="nav-icon fas fa-copy"></i>
                <p>
                  Vendors
                  <i className="right fas fa-angle-left" />
                </p>
              </Link>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <Link
                    to="/vendors/newvendor"
                    className={
                      pathname === "/vendors/newvendor"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>New vendor </p>
                  </Link>
                </li>
              </ul>
            </li>

            <li className="nav-item">
              <Link to="#" className="nav-link">
                <i class="nav-icon fas fa-user"></i>
                <p>
                  Customers
                  <i className="right fas fa-angle-left" />
                </p>
              </Link>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <Link
                    to="/customers/newcustomer"
                    className={
                      pathname === "/customers/newcustomer"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>New Customer </p>
                  </Link>
                </li>
              </ul>
            </li>
           
           
          </ul>
        </nav>
        {/* /.sidebar-menu */}
      </div>
      {/* /.sidebar */}
    </aside>
  );
}
