import React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import authheader from "../../services/authheader";
import { deleteRecord } from "../../util/deleteRecord";
import { fetchData } from "../../util/fetchData";
import { fetch_data_with_limit } from "../../util/fetch_Data_with_limit";

import { checkToken } from "../../services/checkToken";
import FetchDataComponent from "../../components/tables/fetchTableData";
import Pagination from "../../components/tables/ShowTableData";
import Breadcums from "../../common/Breadcums";

import Transport_bar from "../../common/Transport_bar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Maintainence() {
  try {
    checkToken();
  } catch (error) {
    alert("Session logged out.Please logged in again");
    localStorage.clear();
    // history.push('/');
    window.location.href = "/";
  }
  const headers = { Authorization: authheader().access_token };
  const [data, setData] = useState([]);
  const [errors, setErrors] = useState({});
  const [Customerdata, setCustomerdata] = useState(0)
  const [Selectdatagroup, setSelectDatagroup] = useState([]);
  const [loading, setLoading] = useState(false)
  const [Selectdatasubgroup, setSelectDatasubgroup] = useState([]);
  const today = new Date()
  const month = today.getMonth() + 1
  const year = today.getFullYear()
  const date = today.getDate()
  const currentDate = year + '/' + month + '/' + date
  const [formdata, setFormData] = useState({
    company_name: "",
    customer_name: "",
    contact: "",
    street1: "",
    street2: "",
    city: "",
    pincode: "",
    gst: "",
    ob: "",
  });
  const handleChange = (e) => {
    setFormData({ ...formdata, [e.target.id]: e.target.value });
  };
  //handle add record
  const handleAddRecord = (newRecord) => {
    setData([...data, newRecord]);
    fetchData("repair_record", setData, "id");
  };
  const handleChange1 = (e) => {
    // console.log(e.target.value);
    setFormData({ ...formdata, [e.target.id]: e.target.value });
    fetch_data_with_limit(
      "subheads",
      setCustomerdata,
      'subhead_name',
      e.target.value,
      'id',
      'limit 1'
    )
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formdata1 = e.target;
    const post1 = axios.post(
      "/insertdata/repair_record",
      {
        vehicle: formdata1.vehicle.value,
        dte: formdata1.dte.value,
        amount: formdata1.amount.value,
        workdetails: formdata1.workdetail.value,
      },
      { headers }
    );
    const post2 = axios.post(
      "/insertdata/expenses",
      {
        purchase_id: "0",
        date: currentDate,
        invdte: currentDate,
        userid: localStorage.getItem("uname"),
        vendor: Customerdata.subhead_name,
        group_name: "Liabilities",
        subgroup_name: "Current Liabilities",
        head: Customerdata.head_name,
        subhead: Customerdata.subhead_name,
        ref: "Journal Entry",
        payment_mode: "Credit",
        bank: "0",
        credit: formdata1.amount.value,
        tax: "0",
        disc: "0",
        debit: "0",
        balance: "0",
        remark: "Transport Credit Voucher Created",
      },
      { headers }
    );
    try {
      await Promise.all([post1, post2]);
      fetchData("repair_record", setData, "id");
      toast.success("Record Added");
      setFormData({
        amount: "",
        workdetail: "",
      });
    } catch (err) {
      
      toast.error("An error occurred while making the POST requests");
      console.error("Error:", err);
    } finally {
      setLoading(false);
    }
    // handleAddRecord(formdata);
    setFormData({});
    setErrors({});
  };

  //Table data record
  // const [data, setData] = useState([]);
  const [itemsperpage, setitemsperpage] = useState([10]);
  const [columns, setColumns] = useState([]);

  const handleDelete = async (id) => {
    deleteRecord("repair_record", id, "id");
    fetchData("repair_record", setData, "id");
  };
  useEffect(() => {
    fetchData("vehicles", setSelectDatagroup, "id");
    fetchData("repair_record", setData, "id");
  }, []);
  return (
    <>
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <Breadcums heading="Maintenence & Repair" paglink="Vehicle Repairing" />
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              {/* left column */}
              <div className="col-md-12">
                <Transport_bar />

                {/* general form elements */}
                <div className="card card-dark">
                  {/* /.card-header */}
                  {/* form start */}
                  <form onSubmit={handleSubmit}>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Vehicle No.
                            </label>
                            <select
                              className="form-control form-control-sm select2"
                              id="vehicle"
                              onChange={(e) => handleChange1(e)}
                              value={formdata.vehicle}
                            >
                              <option selected="NA">--Select--</option>
                              {Selectdatagroup &&
                                Selectdatagroup.map((seldata, index) => {
                                  return (
                                    <option value={seldata.regno}>
                                      {seldata.regno}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>

                        <div className="col-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Date</label>
                            <input
                              type="date"
                              className="form-control form-control-sm"
                              id="dte"
                              onChange={(e) => handleChange(e)}
                              value={formdata.dte}
                            />
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Amount</label>
                            <input
                              type="number"
                              required
                              className="form-control form-control-sm"
                              id="amount"
                              onChange={(e) => handleChange(e)}
                              value={formdata.amount}
                            />
                          </div>
                        </div>
                        <div className="col-5">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Work Detail
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              id="workdetail"
                              required
                              onChange={(e) => handleChange(e)}
                              value={formdata.workdetail}
                            />
                          </div>
                        </div>

                        <div className="col-1">
                          <div className="form-group">
                            <button
                              type="submit"
                              class="btn mt-4 btn-dark float-right"
                            >
                              Save
                            </button>
                            <ToastContainer />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* /.card-body */}
                  </form>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                {/* general form elements */}
                <div className="card card-dark">
                  <FetchDataComponent
                    tblname="repair_record"
                    setData={setData}
                    setColumns={setColumns}
                    id="id"
                  />

                  {/* This component fetches the data and determines columns */}
                  <Pagination
                    data={data}
                    columns={columns}
                    handleDelete={handleDelete}
                    itemsPerPage={itemsperpage}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}
    </>
  );
}
