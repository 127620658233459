import React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import authheader from "../../services/authheader";
import { fetchData } from "../../util/fetchData";
import { deleteRecord } from "../../util/deleteRecord";
import { dashboard_fetch_data_with_limit } from "../../util/dashboarddatawithlimit";
import { fetch_data_with_limit } from "../../util/fetch_Data_with_limit";
import { totalrecord } from "../../util/totalRecord";
import { todayrecord } from "../../util/todayrecord";
import { monthlysalechart } from "../../util/monthlysalechart";

import { checkToken } from "../../services/checkToken";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Breadcums from "../../common/Breadcums";
import SalesChart from "../../components/graphs/SaleChart";
import { minqty } from "../../util/minqty";
import Pagination from "../../components/tables/Pagination";
import FetchDataComponent from "../../components/tables/fetchTableData";
export default function Admin() {
    try {
        checkToken();
      } catch (error) {
        alert("Session logged out.Please logged in again");
        localStorage.clear();
        // history.push('/');
        window.location.href = "/";
      }
   
      const today = new Date();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const date = today.getDate();
  const currentDate = year + "-" + month + "-" + date;
      const [modalIsOpen, setModalIsOpen] = useState(false);
      const headers = { Authorization: authheader().access_token };
      const [itemsperpage, setitemsperpage] = useState([10]);
      const [columns, setColumns] = useState([]);

      const [data, setData] = useState([]);
      const [errors, setErrors] = useState({});
      const [VendorName, setVendorname] = useState([]);

      const [TotalVendors, setTotalVendors] = useState([]);
      const [TotalCustomers, setTotalCustomers] = useState([]);
      const [TotalLabours, setTotalLabours] = useState([]);
      const [TotalStaff, setTotalStaff] = useState([]);
      const [invdetails, setinvdetails] = useState([]);

      const [InvoiceSummary, setInvoiceSummary] = useState([]);

      const [TotalSale, setTotalSale] = useState([]);
      const [TotalPurchase, setTotalPurchase] = useState([]);
      const [TotalExpense, setTotalExpense] = useState([]);
      const [TotalIncome, setTotalIncome] = useState([]);
      const [SaleData, setSaleData] = useState([]);
      const [MinQty, setMinQty] = useState([]);
      const handleDelete = async (id) => {
        deleteRecord("customers", id, "id");
        fetchData("customers", setData, "id");
      
      };
   
      useEffect(() => {
        fetchData("invoices", setData, "id");
        totalrecord("vendors", setVendorname, "id");
        totalrecord("vendors", setTotalVendors, "id");
        
        totalrecord("customers", setTotalCustomers, "id");
        totalrecord("labour_profiles", setTotalLabours, "id");
        totalrecord("employee_profiles", setTotalStaff, "id");

        monthlysalechart("invoices", setSaleData);      
        minqty("limit 5",setMinQty);

        todayrecord("invoices", setTotalSale, "invoice_date",currentDate,"total_amount");
        todayrecord("income", setTotalIncome, "date",currentDate,"credit");
        todayrecord("expenses", setTotalExpense, "date",currentDate,"debit");
        todayrecord("purchases", setTotalPurchase, "purchase_date",currentDate,"total_cost");
       
        dashboard_fetch_data_with_limit("invoices", setInvoiceSummary,"invoice_date",currentDate,"id","limit 5")
       
      }, []);
      
    
  return (
   <>
  
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
   {/* Main content */}
   <Breadcums heading="Dashboard" paglink="Home" />
<section className="content">
  <div className="container-fluid">
    {/* Info boxes */}
    <div className="row">
      
      <div className="col-12 col-sm-6 col-md-3">
        <div className="info-box">
          <span className="info-box-icon bg-info elevation-1"><i className="fas fa-cog" /></span>
         
             <div className="info-box-content">
            <span className="info-box-text">Todays Sale</span>
            <span className="info-box-number">
              {TotalSale.totalrecord}
            </span>
          </div>
         
          {/* /.info-box-content */}
        </div>
        {/* /.info-box */}
      </div>
      
      {/* /.col */}
      
      <div className="col-12 col-sm-6 col-md-3">
        <div className="info-box mb-3">
          <span className="info-box-icon bg-danger elevation-1"><i className="fas fa-thumbs-up" /></span>
          <div className="info-box-content">
            <span className="info-box-text">Todays Purchase</span>
            <span className="info-box-number">{TotalPurchase.totalrecord}</span>
          </div>
          {/* /.info-box-content */}
        </div>
        {/* /.info-box */}
      </div>
      {/* /.col */}
      {/* fix for small devices only */}
      <div className="clearfix hidden-md-up" />
      <div className="col-12 col-sm-6 col-md-3">
        <div className="info-box mb-3">
          <span className="info-box-icon bg-success elevation-1"><i className="fas fa-shopping-cart" /></span>
          <div className="info-box-content">
            <span className="info-box-text">Income</span>
            <span className="info-box-number">{TotalIncome.totalrecord}</span>
          </div>
          {/* /.info-box-content */}
        </div>
        {/* /.info-box */}
      </div>
      {/* /.col */}
      <div className="col-12 col-sm-6 col-md-3">
        <div className="info-box mb-3">
          <span className="info-box-icon bg-warning elevation-1"><i className="fas fa-users" /></span>
          <div className="info-box-content">
            <span className="info-box-text">Expenses</span>
            <span className="info-box-number">{TotalExpense.totalrecord}</span>
          </div>
          {/* /.info-box-content */}
        </div>
        {/* /.info-box */}
      </div>
      {/* /.col */}
    </div>
    <div className="row">
      {/* Left col */}
    
      <div className="col-md-8">
       
        <div className="card card-dark">
          <div className="card-header border-transparent">
            <h3 className="card-title">Sale Chart</h3>
           
          </div>
          {/* /.card-header */}
          <div className="card-body p-0">
           
          <SalesChart data={SaleData} />
            {/* /.table-responsive */}
          </div>
          {/* /.card-body */}
       
          {/* /.card-footer */}
        </div>
        {/* /.card */}
      </div>
      <div className="col-md-4">
        {/* Info Boxes Style 2 */}
        <div className="info-box mb-3 bg-warning">
          <span className="info-box-icon"><i className="fas fa-tag" /></span>
          <div className="info-box-content">
            <span className="info-box-text">Total Customers</span>
            <span className="info-box-number">{TotalCustomers.totalrecord}</span>
          </div>
          {/* /.info-box-content */}
        </div>
        {/* /.info-box */}
        <div className="info-box mb-3 bg-success">
          <span className="info-box-icon"><i className="far fa-heart" /></span>
          <div className="info-box-content">
            <span className="info-box-text">Total Vendors</span>
            <span className="info-box-number">{TotalVendors.totalrecord}</span>
          </div>
          {/* /.info-box-content */}
        </div>
        {/* /.info-box */}
        <div className="info-box mb-3 bg-danger">
          <span className="info-box-icon"><i className="fas fa-cloud-download-alt" /></span>
          <div className="info-box-content">
            <span className="info-box-text">Total Labour</span>
            <span className="info-box-number">{TotalLabours.totalrecord}</span>
          </div>
          {/* /.info-box-content */}
        </div>
        {/* /.info-box */}
        <div className="info-box mb-3 bg-info">
          <span className="info-box-icon"><i className="far fa-comment" /></span>
          <div className="info-box-content">
            <span className="info-box-text">Total Staff</span>
            <span className="info-box-number">{TotalStaff.totalrecord}</span>
          </div>
          {/* /.info-box-content */}
        </div>
        
      </div>


      </div>
    <div className="row">
      {/* Left col */}
      <div className="col-md-8">
       
        <div className="card card-dark">
          <div className="card-header border-transparent">
            <h3 className="card-title">Latest Sale</h3>
            <div className="card-tools">
              <button type="button" className="btn btn-tool" data-card-widget="collapse">
                <i className="fas fa-minus" />
              </button>
              <button type="button" className="btn btn-tool" data-card-widget="remove">
                <i className="fas fa-times" />
              </button>
            </div>
          </div>
          {/* /.card-header */}
          <div className="card-body p-0">
            <div className="table-responsive">
            <table
                      className="table m-0"
                      id="tbldata"
                    >
                      <thead className="thead">
                        <tr>
                          <th>Date</th>
                          <th>Customer Name</th>
                          <th>Customer</th>
                         
                          <th>Total Amount</th>
                          <th>Status</th>
                         
                        </tr>
                      </thead>
                      <tbody>
                   
                        {InvoiceSummary &&
                          InvoiceSummary.map((user, index) => {
                            return (
                              <tr key={index}>
                              
                                <td>
                                {user.inv}
                                  </td>
                                  <td>{user.invoice_date} </td>
                                <td>{user.customer_name} </td>
                              
                                <td>{user.total_amount} </td>
                                <td>{user.status} </td>
                               
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
           
            </div>
            {/* /.table-responsive */}
          </div>
          {/* /.card-body */}
          <div className="card-footer clearfix">
            <Link to="/sale/makeinvoice" className="btn btn-sm btn-info float-left">Make New Bill</Link>
            <Link to="/sale/invoicedetails" className="btn btn-sm btn-secondary float-right">View All Invoices</Link>
          </div>
          {/* /.card-footer */}
        </div>
        {/* /.card */}
      </div>
      {/* /.col */}
      <div className="col-md-4">
       
       <div className="card card-dark">
         <div className="card-header border-transparent">
           <h3 className="card-title">Below Average Stock List</h3>
           
         </div>
         {/* /.card-header */}
         <div className="card-body p-0">
           <div className="table-responsive">
           <table
                     className="table m-0"
                     id="tbldata"
                   >
                     <thead className="thead">
                       <tr>
                         <th>Products</th>
                         <th>Balance</th>
                        
                       </tr>
                     </thead>
                     <tbody>
                  
                       {MinQty &&
                         MinQty.map((user, index) => {
                           return (
                             <tr key={index}>
                              
                                 <td>{user.prod_name} </td>
                               <td>{user.balance} </td>
                             
                              
                             </tr>
                           );
                         })}
                     </tbody>
                   </table>
          
           </div>
           {/* /.table-responsive */}
         </div>
         {/* /.card-body */}
         <div className="card-footer clearfix">
          
           <Link to="/purchase/belowstock" className="btn btn-sm btn-secondary float-right">View All</Link>
         </div>
         {/* /.card-footer */}
       </div>
       {/* /.card */}
     </div>
   
      {/* /.col */}
    </div>
  
   
    {/* /.row */}
  </div>{/*/. container-fluid */}
</section>
{/* /.content */}

      
        </div>
   </>
  )
}
