import React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import authheader from "../../services/authheader";
import { deleteRecord } from "../../util/deleteRecord";
import { fetchData } from "../../util/fetchData";
import { checkToken } from "../../services/checkToken";
import FetchDataComponent from "../../components/tables/fetchTableData";
import Pagination from "../../components/tables/ShowTableData";
import Breadcums from "../../common/Breadcums";

export default function Contracts() {
  try {
    checkToken();
  } catch (error) {
    alert("Session logged out.Please logged in again");
    localStorage.clear();
    // history.push('/');
    window.location.href = "/";
  }
  const headers = { Authorization: authheader().access_token };
  const [data, setData] = useState([]);
  const [errors, setErrors] = useState({});
  const [Selectdatagroup, setSelectDatagroup] = useState([]);
  const [Selectdatasubgroup, setSelectDatasubgroup] = useState([]);
  const [formdata, setFormData] = useState({
    name: "",
    contact: "",
    address: "",
    start_date: "",
    end_date: "",
    work_type: "",
    brief: "",
    description: "",
    rental: "",
    disc: "",
    penalty: "",
    total_value: "",
    payment_terms: "",
    billing_name: "",
    billing_contact: "",
    billing_address: "",
    bankholder: "",
    bank_name: "",
    account: "",
    ifsc: "",
  });
  const handleChange = (e) => {
    setFormData({ ...formdata, [e.target.id]: e.target.value });
  };
  //handle add record
  const handleAddRecord = (newRecord) => {
    setData([...data, newRecord]);
    fetchData("contracts", setData, "id");
  };
 
  const handleSubmit = (e) => {
    e.preventDefault();
    const formdata1 = e.target;
    axios
      .post(
        "/insertdata/contracts",
        {
          
          name: formdata1.name.value,
          contact: formdata1.contact.value,
          address: formdata1.address.value,
          start_date: formdata1.start_date.value,
          end_date: formdata1.end_date.value,
          work_type: formdata1.worktype.value,
          brief: formdata1.brief.value,
          description: formdata1.desc.value,
          rental: formdata1.rental.value,
          disc: formdata1.disc.value,
          penalty: formdata1.penalty.value,
          total_value: formdata1.total_value.value,
          payment_terms: formdata1.payment.value,
          billing_name: formdata1.bname.value,
          billing_contact: formdata1.bcontact.value,
          billing_address: formdata1.baddress.value,
          bankholder: formdata1.bankholder.value,
          bank_name: formdata1.bank.value,
          account: formdata1.account.value,
          ifsc: formdata1.ifsc.value,
        },
        { headers }
      )
      .then((res) => {
        fetchData("contracts", setData, "id");

        const newRecord = res;
        handleAddRecord(newRecord);
        setFormData({
          name: "",
          contact: "",
          address: "",
          start_date: "",
          end_date: "",
          work_type: "",
          brief: "",
          desc: "",
          rental: "",
          disc: "",
          penalty: "",
          total_value: "",
          payment_terms: "",
          bname: "",
          bcontact: "",
          baddress: "",
          bankholder: "",
          bank: "",
          account: "",
          ifsc: "",
        });
        //navigate("/");
      })
      .catch((err) => {
        console.log(err.message);
      });
      axios
      .post(
        "/insertdata/subheads",
        {
          head_name: "Journal",
          subhead_name: formdata1.name.value,
         
        },
        { headers }
      )
      .then((res) => {
        fetchData("subheads", setData, "id");
        
      })
      .catch((err) => {
        console.log(err.message);
      });
    // handleAddRecord(formdata);
    setFormData({});
    setErrors({});
  };

  //Table data record

  const [itemsperpage, setitemsperpage] = useState([10]);
  const [columns, setColumns] = useState([]);

  const handleDelete = async (id) => {
    deleteRecord("contracts", id, "id");
    fetchData("contracts", setData, "id");
  };
  const location = useLocation();
  useEffect(() => {
   
    fetchData("contract_type", setSelectDatagroup, "id");
    
  }, []);
  return (
    <>
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <Breadcums heading="Contracts Management" paglink="New Contracts" />
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              {/* left column */}
              <div className="col-md-12">
                {/* general form elements */}
                <form onSubmit={handleSubmit}>
                <div className="card card-dark card-tabs">
                  <div className="card-header p-0 pt-1">
                    <ul
                      className="nav nav-tabs"
                      id="custom-tabs-one-tab"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          id="custom-tabs-one-home-tab"
                          data-toggle="pill"
                          href="#custom-tabs-one-home"
                          role="tab"
                          aria-controls="custom-tabs-one-home"
                          aria-selected="true"
                        >
                          Personal Details
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="custom-tabs-one-profile-tab"
                          data-toggle="pill"
                          href="#custom-tabs-one-profile"
                          role="tab"
                          aria-controls="custom-tabs-one-profile"
                          aria-selected="false"
                        >
                          Contract Details
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="custom-tabs-one-messages-tab"
                          data-toggle="pill"
                          href="#custom-tabs-one-messages"
                          role="tab"
                          aria-controls="custom-tabs-one-messages"
                          aria-selected="false"
                        >
                          Pricing 
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="custom-tabs-one-settings-tab"
                          data-toggle="pill"
                          href="#custom-tabs-one-settings"
                          role="tab"
                          aria-controls="custom-tabs-one-settings"
                          aria-selected="false"
                        >
                          Billing Information
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="card-body">
                    <div
                      className="tab-content"
                      id="custom-tabs-one-tabContent"
                    >
                      <div
                        className="tab-pane fade show active"
                        id="custom-tabs-one-home"
                        role="tabpanel"
                        aria-labelledby="custom-tabs-one-home-tab"
                      >
                        <div className="row">
                          <div className="col-2">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">Name</label>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                id="name"
                                onChange={(e) => handleChange(e)}
                                value={formdata.name}
                              />
                            </div>
                          </div>
                          <div className="col-2">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                Contact No.
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                id="contact"
                                onChange={(e) => handleChange(e)}
                                value={formdata.contact}
                              />
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                Address
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                id="address"
                                onChange={(e) => handleChange(e)}
                                value={formdata.address}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="custom-tabs-one-profile"
                        role="tabpanel"
                        aria-labelledby="custom-tabs-one-profile-tab"
                      >
                        <div className="row">
                          <div className="col-2">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                Start Date
                              </label>
                              <input
                                type="date"
                                className="form-control form-control-sm"
                                id="start_date"
                                onChange={(e) => handleChange(e)}
                                value={formdata.start_date}
                              />
                            </div>
                          </div>
                          <div className="col-2">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                End Date
                              </label>

                              <input
                                type="date"
                                className="form-control form-control-sm datetimepicker-input"
                                id="end_date"
                                onChange={(e) => handleChange(e)}
                                value={formdata.end_date}
                              />
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                Work Type
                              </label>
                              <select
                                className="form-control form-control-sm select2"
                                id="worktype"
                                onChange={(e) => handleChange(e)}
                                value={formdata.worktype}
                              >
                                <option selected="NA">--Select--</option>
                                {Selectdatagroup &&
                                  Selectdatagroup.map((seldata, index) => {
                                    return (
                                      <option value={seldata.ctype}>
                                        {seldata.ctype}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>
                          </div>
                          <div className="col-5">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                Brief Detail
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-sm "
                                id="brief"
                                onChange={(e) => handleChange(e)}
                                value={formdata.brief}
                              />

                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                Contract Details with T&C
                              </label>
                              <textarea
                              className="form-control form-control-sm"
                                id="desc"
                                onChange={(e) => handleChange(e)}
                                value={formdata.desc}
                                
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="custom-tabs-one-messages"
                        role="tabpanel"
                        aria-labelledby="custom-tabs-one-messages-tab"
                      >
                     <div className="row">
                          <div className="col-2">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">Rental Rate</label>
                              <input
                                type="number"
                                className="form-control form-control-sm"
                                id="rental"
                                onChange={(e) => handleChange(e)}
                                value={formdata.rental}
                              />
                            </div>
                          </div>
                          <div className="col-2">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                Discounts
                              </label>
                              <input
                                type="number"
                                className="form-control form-control-sm"
                                id="disc"
                                onChange={(e) => handleChange(e)}
                                value={formdata.disc}
                              />
                            </div>
                          </div>
                          <div className="col-2">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                Penalities
                              </label>
                              <input
                                type="number"
                                className="form-control form-control-sm"
                                id="penalty"
                                onChange={(e) => handleChange(e)}
                                value={formdata.penalty}
                              />
                            </div>
                          </div>
                          <div className="col-2">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                Total Contract value
                              </label>
                              <input
                                type="number"
                                className="form-control form-control-sm"
                                id="total_value"
                                onChange={(e) => handleChange(e)}
                                value={formdata.total_value}
                              />
                            </div>
                          </div>
                          <div className="col-2">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                               Payment Terms
                              </label>
                              <select 
                        className="form-control form-control-sm select2"
                        id="payment"
                        onChange={(e) => handleChange(e)}
                        value={formdata.payment}
                        >
                          
                        <option selected value="NA">--Select--</option>
                        <option value="Monthly">Monthly</option>
                        <option value="Quarterly">Quarterly</option>
                        <option value="Half_Yearly">Half Yearly</option>
                        <option value="Yearly"> Yearly</option>
                     
                  </select>

                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="custom-tabs-one-settings"
                        role="tabpanel"
                        aria-labelledby="custom-tabs-one-settings-tab"
                      >
                        <div className="row">
                          <div className="col-3">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1"> Name</label>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                id="bname"
                                onChange={(e) => handleChange(e)}
                                value={formdata.bname}
                              />
                            </div>
                          </div>
                          <div className="col-2">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                 Contact
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                id="bcontact"
                                onChange={(e) => handleChange(e)}
                                value={formdata.bcontact}
                              />
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                 Address
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                id="baddress"
                                onChange={(e) => handleChange(e)}
                                value={formdata.baddress}
                              />
                            </div>
                          </div>
                          <div className="col-2">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                 Bank Holder Name
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                id="bankholder"
                                onChange={(e) => handleChange(e)}
                                value={formdata.bankholder}
                              />
                            </div>
                          </div>
                          </div>
                            <div className="row">
                         
                          <div className="col-2">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                 Bank Name
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                id="bank"
                                onChange={(e) => handleChange(e)}
                                value={formdata.bank}
                              />
                            </div>
                          </div>
                          <div className="col-2">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                 A/C Number
                              </label>
                              <input
                                type="number"
                                className="form-control form-control-sm"
                                id="account"
                                onChange={(e) => handleChange(e)}
                                value={formdata.account}
                              />
                            </div>
                          </div>
                          <div className="col-2">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                 IFSC Code
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                id="ifsc"
                                onChange={(e) => handleChange(e)}
                                value={formdata.ifsc}
                              />
                            </div>
                          </div>
                          <div className="col-2">
                            <div className="form-group">
                            <button type="submit" class="btn mt-4 btn-dark float-right">
                        Save
                      </button>
                            </div>
                          </div>
                         
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /.card */}
                </div>
                </form>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                {/* general form elements */}
                <div className="card card-dark">
                  <FetchDataComponent
                    tblname="contracts"
                    setData={setData}
                    setColumns={setColumns}
                    id="id"
                  />

                  {/* This component fetches the data and determines columns */}
                  <Pagination
                    data={data}
                    columns={columns}
                    handleDelete={handleDelete}
                    itemsPerPage={itemsperpage}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}
    </>
  );
}
