import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { AuthProvider } from './Context/AuthProvider';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from "axios";
import { BrowserRouter as Router } from 'react-router-dom';
import { startOfDay } from 'date-fns';
//axios.defaults.baseURL = "http://127.0.0.1:4200/api";
//axios.defaults.baseURL = "https://jdapi.dhruvinnovations.in/api";
axios.defaults.baseURL = "https://api.dhruvinnovations.in/demojd/api";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
  <AuthProvider>
    <App />
    </AuthProvider>
    </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
