import React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import authheader from "../../services/authheader";
import { deleteRecord } from "../../util/deleteRecord";
import { fetchData } from "../../util/fetchData";
import { fetch_data_with_limit } from "../../util/fetch_Data_with_limit";
import { checkToken } from "../../services/checkToken";
import FetchDataComponent from "../../components/tables/fetchTableData";
import Pagination from "../../components/tables/ShowTableData";
import Breadcums from "../../common/Breadcums";

export default function Companyinfo() {
  try {
    checkToken();
  } catch (error) {
    alert("Session logged out.Please logged in again");
    localStorage.clear();
    // history.push('/');
    window.location.href = "/";
  }
  const headers = { Authorization: authheader().access_token };
  const [data, setData] = useState([]);
  const [compdata, setcompdata] = useState([]);
  const [errors, setErrors] = useState({});
  const [formdata, setFormData] = useState({
    cname: "",
  });
  const handleChange = (e) => {
    setFormData({ ...formdata, [e.target.id]: e.target.value });
  };
  //handle add record
  const handleAddRecord = (newRecord) => {
    setData([...data, newRecord]);
    fetchData("units", setData, "id");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formdata1 = e.target;
    axios
      .put(
        "/updatecompanyinfo/bill_format/id/1",
        {
          cname: formdata1.cname.value,
          address: formdata1.address.value,
          pincode: formdata1.pincode.value,
          contact: formdata1.contact.value,
          gst: formdata1.gst.value,
          state: formdata1.state.value,
          bank: formdata1.bank.value,
          t1: formdata1.t1.value,
          t2: formdata1.t2.value,
          t3: formdata1.t3.value,
        },
        { headers }
      )
      .then((res) => {
        const newRecord = res;
        handleAddRecord(newRecord);
        fetchData("bill_format", setData, "id");
        //navigate("/");
        setFormData({
          tax_name1: "",
        });
      })
      .catch((err) => {
        console.log(err.message);
      });
    // handleAddRecord(formdata);
    setFormData({});
    setErrors({});
  };

  //Table data record
  // const [data, setData] = useState([]);
  const [itemsperpage, setitemsperpage] = useState([10]);
  const [columns, setColumns] = useState([]);

  const handleDelete = async (id) => {
    deleteRecord("units", id, "id");
    fetchData("units", setData, "id");
  };
  useEffect(() => {
    fetchData("bill_format", setcompdata, "id");
  }, []);
  return (
    <>
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <Breadcums heading="Settings" pagelink="Unit" />
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <form onSubmit={handleSubmit}>
              <div className="row">
                {/* left column */}
                <div className="col-md-6">
                  {/* general form elements */}
                  <div className="card card-dark">
                    <div className="card-header">
                      <h3 className="card-title">Company Information</h3>
                    </div>
                    {/* /.card-header */}
                    {/* form start */}

                    <div className="card-body">
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Company Name
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              id="cname"
                              onChange={(e) => handleChange(e)}
                              value={compdata.cname}
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Address</label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              id="address"
                              onChange={(e) => handleChange(e)}
                              value={compdata.address}
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Pin Code</label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              id="pincode"
                              onChange={(e) => handleChange(e)}
                              value={compdata.pincode}
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Contact No.
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              id="contact"
                              onChange={(e) => handleChange(e)}
                              value={compdata.contact}
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">GST No.</label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              id="gst"
                              onChange={(e) => handleChange(e)}
                              value={compdata.gst}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* /.card-body */}
                  </div>
                </div>
                <div className="col-md-6">
                  {/* general form elements */}
                  <div className="card card-dark">
                    <div className="card-header">
                      <h3 className="card-title">Company Information</h3>
                    </div>
                    <div className="card-body">
                      <div className="col-12">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">State</label>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            id="state"
                            onChange={(e) => handleChange(e)}
                            value={compdata.state}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            Bank Details
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            id="bank"
                            onChange={(e) => handleChange(e)}
                            value={compdata.bank}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Term1</label>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            id="t1"
                            onChange={(e) => handleChange(e)}
                            value={compdata.t1}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Term2</label>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            id="t2"
                            onChange={(e) => handleChange(e)}
                            value={compdata.t2}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Term3</label>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            id="t3"
                            onChange={(e) => handleChange(e)}
                            value={compdata.t3}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="card-footer">
                      <button
                        type="submit"
                        class="btn btn-dark float-right btn-sm"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}
    </>
  );
}
