import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";

export default function Header() {
  const navigate = useNavigate();
  const logout = () => {
    localStorage.clear();
    navigate("/");
  };
  return (
    <>
      {/* Navbar */}
      <nav className="main-header navbar navbar-expand navbar-black navbar-dark">
        {/* Left navbar links */}
        <ul className="navbar-nav">
          <li className="nav-item">
            <Link
              className="nav-link"
              data-widget="pushmenu"
              href="#"
              role="button"
            >
              <i className="fas fa-bars" />
            </Link>
          </li>
          <li className="nav-item d-none d-sm-inline-block">
            <Link to="/purchase/inventory" className="nav-link">
              Store
            </Link>
          </li>
          <li className="nav-item d-none d-sm-inline-block">
            <Link to="/purchase/newpurchase" className="nav-link">
              Purchase
            </Link>
          </li>
          <li className="nav-item d-none d-sm-inline-block">
            <Link to="/transports/booking/scheduling" className="nav-link">
              Transport
            </Link>
          </li>
          <li className="nav-item d-none d-sm-inline-block">
          <Link
                    to="/transports/newcontract"
                    className="nav-link"
                      
                  >
                   
                    <p>Contractors</p>
                  </Link>
           
          </li>
        </ul>
        {/* Right navbar links */}
        <ul className="navbar-nav ml-auto">
          {/* Navbar Search */}
          <li className="nav-item">
            <Link
              className="nav-link"
              data-widget="navbar-search"
              href="#"
              role="button"
            >
              <i className="fas fa-search" />
            </Link>
            <div className="navbar-search-block">
              <form className="form-inline">
                <div className="input-group input-group-sm">
                  <input
                    className="form-control form-control-navbar"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                  />
                  <div className="input-group-append">
                    <button className="btn btn-navbar" type="submit">
                      <i className="fas fa-search" />
                    </button>
                    <button
                      className="btn btn-navbar"
                      type="button"
                      data-widget="navbar-search"
                    >
                      <i className="fas fa-times" />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </li>
          {/* Messages Dropdown Menu */}
          <li className="nav-item dropdown">
            <Link className="nav-link" data-toggle="dropdown" href="#">
              <i className="far fa-comments" />
              <span className="badge badge-danger navbar-badge">3</span>
            </Link>
            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
              <Link href="#" className="dropdown-item">
                {/* Message Start */}
                <div className="media">
                  <img
                    src="../../dist/img/user1-128x128.jpg"
                    alt="User Avatar"
                    className="img-size-50 mr-3 img-circle"
                  />
                  <div className="media-body">
                    <h3 className="dropdown-item-title">
                      Brad Diesel
                      <span className="float-right text-sm text-danger">
                        <i className="fas fa-star" />
                      </span>
                    </h3>
                    <p className="text-sm">Call me whenever you can...</p>
                    <p className="text-sm text-muted">
                      <i className="far fa-clock mr-1" /> 4 Hours Ago
                    </p>
                  </div>
                </div>
                {/* Message End */}
              </Link>
              <div className="dropdown-divider" />
              <Link href="#" className="dropdown-item">
                {/* Message Start */}
                <div className="media">
                  <img
                    src="../../dist/img/user8-128x128.jpg"
                    alt="User Avatar"
                    className="img-size-50 img-circle mr-3"
                  />
                  <div className="media-body">
                    <h3 className="dropdown-item-title">
                      John Pierce
                      <span className="float-right text-sm text-muted">
                        <i className="fas fa-star" />
                      </span>
                    </h3>
                    <p className="text-sm">I got your message bro</p>
                    <p className="text-sm text-muted">
                      <i className="far fa-clock mr-1" /> 4 Hours Ago
                    </p>
                  </div>
                </div>
                {/* Message End */}
              </Link>
              <div className="dropdown-divider" />
              <Link href="#" className="dropdown-item">
                {/* Message Start */}
                <div className="media">
                  <img
                    src="../../dist/img/user3-128x128.jpg"
                    alt="User Avatar"
                    className="img-size-50 img-circle mr-3"
                  />
                  <div className="media-body">
                    <h3 className="dropdown-item-title">
                      Nora Silvester
                      <span className="float-right text-sm text-warning">
                        <i className="fas fa-star" />
                      </span>
                    </h3>
                    <p className="text-sm">The subject goes here</p>
                    <p className="text-sm text-muted">
                      <i className="far fa-clock mr-1" /> 4 Hours Ago
                    </p>
                  </div>
                </div>
                {/* Message End */}
              </Link>
              <div className="dropdown-divider" />
              <Link href="#" className="dropdown-item dropdown-footer">
                See All Messages
              </Link>
            </div>
          </li>
          {/* Notifications Dropdown Menu */}
          <li className="nav-item dropdown">
            <Link className="nav-link" data-toggle="dropdown" href="#">
              <i className="far fa-bell" />
              <span className="badge badge-warning navbar-badge">15</span>
            </Link>
            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
              <span className="dropdown-item dropdown-header">
                15 Notifications
              </span>
              <div className="dropdown-divider" />
              <Link href="#" className="dropdown-item">
                <i className="fas fa-envelope mr-2" /> 4 new messages
                <span className="float-right text-muted text-sm">3 mins</span>
              </Link>
              <div className="dropdown-divider" />
              <Link href="#" className="dropdown-item">
                <i className="fas fa-users mr-2" /> 8 friend requests
                <span className="float-right text-muted text-sm">12 hours</span>
              </Link>
              <div className="dropdown-divider" />
              <Link href="#" className="dropdown-item">
                <i className="fas fa-file mr-2" /> 3 new reports
                <span className="float-right text-muted text-sm">2 days</span>
              </Link>
              <div className="dropdown-divider" />
              <Link href="#" className="dropdown-item dropdown-footer">
                See All Notifications
              </Link>
            </div>
          </li>
          {/* Profile Dropdown Menu */}
          <li className="nav-item dropdown">
            <Link className="nav-link" data-toggle="dropdown" href="#">
              <i className="far fa-user" />
              <span className="badge badge-warning navbar-badge"></span>
            </Link>
            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
              {/* <span className="dropdown-item dropdown-header">
                15 Notifications
              </span> */}
              <div className="dropdown-divider" />
              <Link href="#" className="dropdown-item">
                <div className="row">
                  <div className="col-2">
                    <ion-icon name="person-circle" size="large"></ion-icon>
                  </div>
                  <div className="col-2 mt-2">
                    <h6> Profile </h6>
                  </div>
                </div>

                {/* <span className="float-right text-muted text-sm">3 mins</span> */}
              </Link>

              <div className="dropdown-divider" />

              <Link className="dropdown-item" to="/settings/companyinfo">
                <div className="row">
                  <div className="col-2">
                    <ion-icon name="business" size="large"></ion-icon>
                  </div>
                  <div className="col-2 mt-2">
                    <h6> Company Info </h6>
                  </div>
                </div>
              </Link>
              <button className="dropdown-item" onClick={logout}>
                <div className="row">
                  <div className="col-2">
                    <ion-icon name="key" size="large"></ion-icon>
                  </div>
                  <div className="col-2 mt-2">
                    <h6> License Info </h6>
                  </div>
                </div>
              </button>
              <div className="dropdown-divider" />

              <button className="dropdown-item" onClick={logout}>
                <div className="row">
                  <div className="col-2">
                    <ion-icon name="log-out" size="large"></ion-icon>
                  </div>
                  <div className="col-2 mt-2">
                    <h6> Sign Out </h6>
                  </div>
                </div>
              </button>
            </div>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link"
              data-widget="fullscreen"
              href="#"
              role="button"
            >
              <i className="fas fa-expand-arrows-alt" />
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link"
              data-widget="control-sidebar"
              data-slide="true"
              href="#"
              role="button"
            >
              <i className="fas fa-th-large" />
            </Link>
          </li>
        </ul>
      </nav>

      <div id="control-sidebar"></div>
      {/* /.navbar */}
    </>
  );
}
