import React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import authheader from "../../services/authheader";
import { deleteRecord } from "../../util/deleteRecord";
import { fetchData } from "../../util/fetchData";
import { getclosingstock } from "../../util/closingstock";
import { fetchData_singlePara } from "../../util/fetchDataby_singlePara";
import { checkToken } from "../../services/checkToken";
import FetchDataComponent from "../../components/tables/fetchTableData";
import ShowTableReportData from "../../components/tables/ShowTableReports";
import { fetch_data_with_limit } from "../../util/fetch_Data_with_limit";
import Breadcums from "../../common/Breadcums";
import Transport_bar from "../../common/Transport_bar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { format } from "date-fns";
import getCurrentDate from "../../util/getCurrentDate";

export default function StaffSalary() {
  try {
    checkToken();
  } catch (error) {
    alert("Session logged out.Please logged in again");
    localStorage.clear();
    // history.push('/');
    window.location.href = "/";
  }
  const headers = { Authorization: authheader().access_token };
  const [data, setData] = useState([]);
  
  const [LabourData, setLabourData] = useState([]);
  const [name, setname] = useState(['', '', '']);
  const [attd, setattd] = useState(['', '', '']);
  const [VendorName, setVendorname] = useState([]);
  const [VenderData, setVenderData] = useState([]);
  const [totalvalue, setTotalvalue] = useState("0");
  const [netpay, setNetPay] = useState("0");
  const [selectedDate, setSelectedDate] = useState(getCurrentDate);

  const [Selectdatasubgroup, setSelectDatasubgroup] = useState([]);
  const [formdata, setFormData] = useState({
    company_name: "",
    customer_name: "",
    contact: "",
    street1: "",
    street2: "",
    city: "",
    pincode: "",
    gst: "",
    ob: "",
  });
  const handleChange = (e) => {
    setFormData({ ...formdata, [e.target.id]: e.target.value });
  };
  const today = new Date();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const date = today.getDate();
  const currentDate = year + "/" + month + "/" + date;

  const getVendorDetails =(e)=>{
    fetch_data_with_limit(
      "employee_profiles",
      setVenderData,
      "first_name",
      e.target.value,
      "id",
      "limit 1"
    );
  }
  const cal = (e) => {
    let hr = parseFloat(formdata.hrs);
    let Deducted = parseFloat(formdata.deduction);
    let par  =parseFloat(VenderData.salary/8);
    let total =hr*par;
    let net_pay = total-Deducted; 
    setTotalvalue(total);
    setNetPay(net_pay);
   
  };
  const handleInputChange1 = (index, event) => {
    const newInputs1 = [...name];
    newInputs1[index] = event.target.value;
    setname(newInputs1);
  };
  const handleInputChange2 = (index, event) => {
    const newInputs2 = [...attd];
    newInputs2[index] = event.target.value;
    setattd(newInputs2);
  };
  const addInputBox = () => {
    setname([...name, '']);
    setattd([...attd, '']);
  };
 
  const handleSubmit = async () => {
    try {
      const response = await axios.post('/insertdata', { name, attd });
      if (response.status === 200) {
        toast.success("Data Saved Successfully");
        alert('Values inserted successfully');
      }
    } catch (error) {
        toast.error("Error inserting values");
     
    }
  };
  const handleSubmit1 = async(e) => {
    e.preventDefault();
    const formdata1 = e.target;
    const post1 =axios
      .post(
        "/insertdata/labour_salary",
        {
          sal_date: currentDate,
          name: formdata1.vendor_name.value,
          month_salary: formdata1.month.value,
          hrs: formdata1.hrs.value,
          wage: formdata1.wage.value,
          total: formdata1.total.value,
          deduction: formdata1.deduction.value,
          netpay: formdata1.netpay.value,
          remark: formdata1.remark.value,
        },
        { headers }
      );
      const post2 =  axios.post(
        "/insertdata/expenses",
        {
          purchase_id: "0",
          date: currentDate,
          invdte: currentDate,
          userid: localStorage.getItem("uname"),
          vendor: formdata1.vendor_name.value,
          group_name: "Liabilities",
          subgroup_name: "Current Liabilities",
          head: "Salary",
          subhead: formdata1.vendor_name.value,
          ref: "NA",
          payment_mode: "credit",
          bank: "0",
          credit: formdata1.total.value,
          tax: "0",
          disc: "0",
          debit: formdata1.deduction.value,
          balance: "0",
          remark: formdata1.month.value+" salary created ,"+formdata1.remark.value
        },
        { headers }
      );
      //Income Entry
      const post3 =  axios.post(
        "/insertdata/income",
        {
          sale_id: "0",
          date: currentDate,
          invdte: currentDate,
          userid: localStorage.getItem("uname"),
          vendor: formdata1.vendor_name.value,
          group_name: "Assets",
          subgroup_name: "Current Assets",
          head: "Advance",
          subhead: formdata1.vendor_name.value,
          ref: "NA",
          payment_mode: "credit",
          bank: "0",
          credit: formdata1.deduction.value,
          tax: "0",
          disc: "0",
          debit:"0",
          balance: "0",
          remark: formdata1.remark.value
        },
        { headers }
      );
      try {
        await Promise.all([post1,post2,post3]);
        // Show success notification
        fetchData("labour_salary", setData, "id");
        setFormData({
          remark:"",
          hrs:"",
          deduction:"0",
          netpay:"0"
        })
        toast.success("Salary created successfully!");
        //toast.error('An error occurred while making the POST requests');
        //alert("success");
      } catch (err) {
        // Handle errors
        //setError('An error occurred while making the POST requests');
        toast.error("An error occurred while making the POST requests");
        console.error("Error:", err);
      } finally {
      //  setLoading(false);
      }
     
  };

  //Table data record
  // const [data, setData] = useState([]);
  const [itemsperpage, setitemsperpage] = useState([10]);
  const [columns, setColumns] = useState([]);

  const handleDelete = async (id) => {
    deleteRecord("expenses", id, "id");
    fetchData("expenses", setData, "id");
  };
  useEffect(() => {
    fetchData("labour_salary", setData, "id");
    fetchData("labour_salary", setLabourData, "id");
    fetchData("employee_profiles", setVendorname, "id");
    
  }, []);
  return (
    <>
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <Breadcums heading="Staff Salary" paglink="Salary" />
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              {/* left column */}
              <div className="col-md-12">{/* <Transport_bar /> */}</div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                  <form onSubmit={handleSubmit1}>
                    <div className="row">
                    <div className="col-3">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Select Employee
                            </label>
                            <select
                              className="form-control form-control-sm select2"
                              id="vendor_name"
                              onChange={(e) => getVendorDetails(e)}
                              value={formdata.vendor_name}
                            >
                              <option selected="NA">--Select--</option>
                              {VendorName &&
                                VendorName.map((seldata, index) => {
                                  return (
                                    <option value={seldata.first_name}>
                                      {seldata.first_name} {seldata.last_name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Select Month
                            </label>
                            <select
                              className="form-control form-control-sm select2"
                              id="month"
                              onChange={(e) => handleChange(e)}
                              value={formdata.month}
                            >
                              <option selected value="NA">--Select--</option>
                              <option  value="Jan 2024">Jan 2024</option>
                              <option  value="Feb 2024">Feb 2024</option>
                              <option  value="March 2024">March 2024</option>
                              <option  value="April 2024">April 2024</option>
                              <option  value="May 2024">May 2024</option>
                              <option  value="June 2024">June 2024</option>
                              <option  value="July 2024">July 2024</option>
                              <option  value="Aug 2024">Aug 2024</option>
                              <option  value="Sept 2024">Sept 2024</option>
                              <option  value="Oct 2024">Oct 2024</option>
                              <option  value="Nov 2024">Nov 2024</option>
                              <option  value="Dec 2024">Dec 2024</option>
                              <option  value="Jan 2024">Jan 2024</option>
                              <option  value="Jan 2024">Jan 2024</option>
                             
                            </select>
                          </div>
                        </div>
                     
                      <div className="col-3">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">No. of Days</label>
                            <input
                              type="text"
                              required
                              className="form-control form-control-sm"
                              id="hrs"
                              onChange={(e) => handleChange(e)}
                              onKeyUp={(e) => cal(e)}
                              value={formdata.hrs}
                            />
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Wage Price</label>
                            <input
                              type="text"
                              required
                              disabled
                              className="form-control form-control-sm"
                              id="wage"
                              onChange={(e) => handleChange(e)}
                              value={VenderData.salary/31}
                            />
                          </div>
                        </div>
                        </div>
                        <div className="row">
                        
                        <div className="col-3">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Total</label>
                            <input
                              type="text"
                              required
                              disabled
                              className="form-control form-control-sm"
                              id="total"
                              onChange={(e) => handleChange(e)}
                              value={totalvalue}
                            />
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Deduction</label>
                            <input
                              type="text"
                              required
                              className="form-control form-control-sm"
                              id="deduction"
                              onChange={(e) => handleChange(e)}
                              onKeyUp={(e)=>cal(e)}
                              value={formdata.deduction}
                            />
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Net Pay</label>
                            <input
                              type="text"
                              required
                              disabled
                              className="form-control form-control-sm"
                              id="netpay"
                              onChange={(e) => handleChange(e)}
                              value={netpay}
                            />
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Remark</label>
                            <input
                              type="text"
                              
                              className="form-control form-control-sm"
                              id="remark"
                              onChange={(e) => handleChange(e)}
                              value={formdata.remark}
                            />
                          </div>
                        </div>
                        <div className="col-1">
                          <button
                            type="submit"
                            class="btn btn-dark mt-4 float-right"
                          >
                            Save 
                          </button>
                          <ToastContainer />
                        </div>
                      {/* <table className="table table-border table-hover text-nowrap" id="tbldata" >
                        <thead>
                          <tr> </tr>
                        </thead>
                        <tbody>
                          {LabourData &&
                            LabourData.map((seldata, index) => {
                              return (
                                <div className="col-sm-4">
                                  <tr>
                                    <td>
                                      <input
                                        type="text"
                                        disabled
                                        className="form-control form-control-sm"
                                        id={`name_${index}`}
                                        onChange={(event) => handleInputChange1(index, event)}
                                        value={seldata.first_name}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        id={`whours_${index}`}
                                        onChange={(event) => handleInputChange2(index, event)}
                                        value={formdata.whours}
                                      />{" "}
                                    </td>
                                  </tr>
                                </div>
                              );
                            })}
                        </tbody>
                      </table> */}

                     
                    </div>
                    </form>
                  </div>
                </div>

                {/* general form elements */}
                <div className="card card-dark">
                  <FetchDataComponent
                    tblname="labour_salary"
                    setData={setData}
                    setColumns={setColumns}
                    id="id"
                  />

                  {/* This component fetches the data and determines columns */}
                  <ShowTableReportData
                    data={data}
                    columns={columns}
                    handleDelete={handleDelete}
                    itemsPerPage={itemsperpage}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}
    </>
  );
}
