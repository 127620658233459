import React from 'react'

export default function getCurrentDate() {
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const day = today.getDate();
    const currentDate =  month + "/" + day+ "/" + year;
    return(currentDate);
 
}
