import React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import authheader from "../../services/authheader";
import { deleteRecord } from "../../util/deleteRecord";
import { fetchData } from "../../util/fetchData";
import { fetchData_singlePara } from "../../util/fetchDataby_singlePara";
import { fetchData_twoPara } from "../../util/filterDataby_twoparam";
import { filterdatabydate } from "../../util/filterdatabydate";
import { filterdatabydatename } from "../../util/filterbydatename";
import { ledgerSummary } from "../../util/ledger_summary";
import { ledgerSummary_2param } from "../../util/ledgersummary_2param";
import { ledgerSummarybydatename } from "../../util/ledgersummarybydatename";
import { ledgerSummarybydate } from "../../util/ledgersummarybydate";
import { checkToken } from "../../services/checkToken";
import FetchDataComponent from "../../components/tables/fetchTableData";
import JournalShowLedgerReports from "../../components/tables/JournalShowLedgerReport";

import Breadcums from "../../common/Breadcums";

export default function JournalReports() {
  try {
    checkToken();
  } catch (error) {
    alert("Session logged out.Please logged in again");
    localStorage.clear();
    // history.push('/');
    window.location.href = "/";
  }
  const headers = { Authorization: authheader().access_token };
  const [data, setData] = useState([]);
  const [errors, setErrors] = useState({});
  const [VendorName, setVendorname] = useState([]);
  const [labourName, setlabourname] = useState([]);
  const [myname, setmyname] = useState([]);
  const [SelectDatagroup, setSelectDatagroup] = useState([]);
  
  const [SummaryData, setSummaryData] = useState([]);
  const [Acchead, setAcchead] = useState([])
  const [transaction, settransaction] = useState([]);
  const [formdata, setFormData] = useState({
    company_name: "",
    customer_name: "",
    contact: "",
    street1: "",
    street2: "",
    city: "",
    pincode: "",
    gst: "",
    ob: "",
  });
  const handleChange = (e) => {
    setFormData({ ...formdata, [e.target.id]: e.target.value });
  };
  
  const filterbydate = (e) => {
    e.preventDefault();
    const formdata1 = e.target;
    //alert(formdata.frm);
   
    filterdatabydate("journal", setData, "date",formdata.frm,"date",formdata.to, "id");
    ledgerSummarybydate(
      "journal",
      setSummaryData,
      "date",
      formdata.frm,
      "date",
      formdata.to,
      "credit",
      "debit",
      "tax",
      "disc",
    )
  
  };
  const filterbydatename = (e) => {
    e.preventDefault();
    
    filterdatabydatename("journal", setData, "date",formdata.frm,"date",formdata.to,"subhead",formdata.vendor_name, "id");
    ledgerSummarybydatename(
      "journal",
      setSummaryData,
      "date",
      formdata.frm,
      "date",
      formdata.to,
      "subhead",
      formdata.vendor_name,
      "credit",
      "debit",
      "tax",
      "disc",
    )
  
  };
  
  const handleChange1 = (e) => {
    setFormData({ ...formdata, [e.target.id]: e.target.value });
    setmyname(e.target.value);
    fetchData_singlePara("journal", setData, "subhead", e.target.value, "id");
    ledgerSummary(
      "journal",
      setSummaryData,
      "subhead",
      e.target.value,
      "credit",
      "debit",
      "tax",
      "disc",
      "id"
    );
  };
  const handleChange2 = (e) => {
    setFormData({ ...formdata, [e.target.id]: e.target.value });
    setmyname(e.target.value);
    fetchData_singlePara("journal", setData, "main_account", e.target.value, "id");
    ledgerSummary(
      "journal",
      setSummaryData,
      "main_account",
      e.target.value,
      "credit",
      "debit",
      "tax",
      "disc",
      "id"
    );
  };
  const changewith_head_mode = (e) => {
    //setFormData({ ...formdata, [e.target.id]: e.target.value });
  // alert(formdata.imode);
  // alert(formdata.account_name);
    fetchData_twoPara("journal", setData, "main_account",formdata.account_name ,"payment_mode",formdata.imode, "id");
    ledgerSummary_2param(
      "journal",
      setSummaryData,
      "main_account",
      formdata.account_name,
      "payment_mode",
      formdata.imode,
      "credit",
      "debit",
      "tax",
      "disc",
      "id"
    );
  };
  //Table data record
  // const [data, setData] = useState([]);
  const [itemsperpage, setitemsperpage] = useState([10]);
  const [columns, setColumns] = useState([]);

  const handleDelete = async (id) => {
    deleteRecord("journal", id, "id");
    fetchData("journal", setData, "id");
  };
  useEffect(() => {
    fetchData('accounthead', setAcchead, 'id');
    fetchData("transactions", settransaction, "id");
    fetchData("journal", setSelectDatagroup, "id");
    fetchData("subheads", setVendorname, "id");
    fetchData("labour_profiles", setlabourname, "id");
    ledgerSummary(
      "journal",
      setSummaryData,
      "subhead",
      "all",
      "credit",
      "debit",
      "tax",
      "disc",
      "id"
    );
  }, []);
  return (
    <>
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <Breadcums heading="Journal Reports" paglink="journal" />
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              {/* left column */}
              <div className="col-md-12">{/* <Transport_bar /> */}</div>
            </div>

            <div className="row">
              <div className="col-md-12">
                {/* general form elements */}
                <div className="row">
                  <div className="col-3">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-12">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                Search by Vendor
                              </label>
                              <select
                                className="form-control form-control-sm select2"
                                id="vendor_name"
                                onChange={(e) => handleChange1(e)}
                                value={formdata.vendor_name}
                              >
                                <option selected="all">All</option>
                                {VendorName &&
                                  VendorName.map((seldata, index) => {
                                    return (
                                      <option value={seldata.subhead_name}>
                                        {seldata.subhead_name}
                                      </option>
                                    );
                                  })}
                                   {/* {labourName &&
                                  labourName.map((seldata, index) => {
                                    return (
                                      <option value={seldata.first_name} >
                                        {seldata.first_name} {seldata.last_name}
                                      </option>
                                    );
                                  })} */}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-6">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">From</label>
                              <input
                                type="date"
                                className="form-control form-control-sm"
                                id="frm"
                                onChange={(e) => handleChange(e)}
                                value={formdata.frm}
                              />
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">To</label>
                              <input
                                type="date"
                                className="form-control form-control-sm"
                                id="to"
                                onChange={(e) => handleChange(e)}
                                value={formdata.to}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-5">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-12">
                          <div className="row">
                          <div className="col-6">
                            <div className="form-group">
                            <Link type="submit" onClick={filterbydate} className="btn btn-dark mt-4">
                                Search by date
                              </Link>
                            </div>
                            </div>
                            <div className="col-6">
                            <div className="form-group">
                            <Link type="submit" onClick={filterbydatename} className="btn btn-dark mt-4">
                                Search by Date & Name
                              </Link>
                            </div>
                            </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className="card card-dark">
                  <FetchDataComponent
                    tblname="journal"
                    setData={setData}
                    setColumns={setColumns}
                    id="id"
                  />

                  {/* This component fetches the data and determines columns */}
                  <JournalShowLedgerReports
                    data={data}
                    columns={columns}
                    handleDelete={handleDelete}
                    itemsPerPage={itemsperpage}
                    credit={SummaryData.credit}
                    debit={SummaryData.debit}
                    vat={SummaryData.vat}
                    disc={SummaryData.disc}
                    type="expense"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}
    </>
  );
}
