import React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import authheader from "../../services/authheader";
import { format } from "date-fns";
import { fetchData_singlePara } from "../../util/fetchDataby_singlePara";
import { deleteRecord } from "../../util/deleteRecord";
import { fetchData } from "../../util/fetchData";
import { checkToken } from "../../services/checkToken";
import FetchDataComponent from "../../components/tables/fetchTableData";
import Pagination from "../../components/tables/ShowTableData";
import Breadcums from "../../common/Breadcums";

export default function Newvendor() {
  try {
    checkToken();
  } catch (error) {
    alert("Session logged out.Please logged in again");
    localStorage.clear();
    // history.push('/');
    window.location.href = "/";
  }
  const today = new Date();
  const month = today.getMonth()+1;
const year = today.getFullYear();
const date = today. getDate();
const currentDate = year + "/" + month + "/" + date;
  const headers = { Authorization: authheader().access_token };
  const [data, setData] = useState([]);
  const [lastID, setLastID] = useState(0);
  const [errors, setErrors] = useState({});
  const [Selectdatagroup, setSelectDatagroup] = useState([]);
  const [Selectdatasubgroup, setSelectDatasubgroup] = useState([]);
  const [heads, setHeads] = useState([]);
  const [formdata, setFormData] = useState({
    company_name: "",
    customer_name: "",
    contact: "",
    street1: "",
    street2: "",
    city: "",
    pincode: "",
    gst: "",
    ob: "",
  });
  const handleChange1 = (e) => {
   // console.log(e.target.value);
    setFormData({ ...formdata, [e.target.id]: e.target.value });
     
    fetchData_singlePara("subgroups", setSelectDatasubgroup,"group_name",e.target.value, "id");
   // console.log(setSelectDatagroup);
  };
  const getheads = (e) => {
    // console.log(e.target.value);
     setFormData({ ...formdata, [e.target.id]: e.target.value });
      
//     fetchData_singlePara("heads", setHeads,"subgroup","Current Liabilities", "id");
    // console.log(setSelectDatagroup);
   };

  const handleChange = (e) => {
    setFormData({ ...formdata, [e.target.id]: e.target.value });
  };
  //handle add record
  const handleAddRecord = (newRecord) => {
    setData([...data, newRecord]);
    fetchData("vendors", setData, "id");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
   
   let last
    const formdata1 = e.target;
    axios
      .post(
        "/insertdata/vendors",
        {
          group_name: "Liabilities",
          subgroup_name: "Current Liabilities",
          head_name: formdata1.head.value,
          company_name: formdata1.company_name.value,
          customer_name: formdata1.cname.value,
          contact: formdata1.contact.value,
          street1: formdata1.street1.value,
          street2: formdata1.street2.value,
          city: formdata1.city.value,
          pincode: formdata1.pin.value,
          gst: formdata1.gst.value,
          ob: formdata1.ob.value,
        },
        { headers }
      )
      .then((res) => {
        fetchData("vendors", setData, "id");
        //console.log(res);
        setLastID(res.data.id);
        alert(res.data.id);
        alert(data[0].id+1);
        const newRecord = res;
        handleAddRecord(newRecord);
        setFormData({
          company_name: "",
          cname: "",
          contact: "",
          street1: "",
          street2: "",
          city: "",
          pincode: "",
          gst: "",
          ob: "",
        });
        //navigate("/");
      })
      .catch((err) => {
        console.log(err.message);
      });
      axios
      .post(
        "/insertdata/subheads",
        {
          vendor_id:data[0].id+1,
          head_name: formdata1.head.value,
          subhead_name: formdata1.company_name.value,
         
        },
        { headers }
      )
      .then((res) => {
        setFormData({
        
        });
        //navigate("/");
      })
      .catch((err) => {
        console.log(err.message);
      });
      axios
      .post(
        "/insertdata/expenses",
        {
          purchase_id: data[0].id+1,
          date: currentDate,
          invdte: currentDate,
          userid: localStorage.getItem('uname'),
          vendor: formdata1.company_name.value,
          head: formdata1.head.value,
          subhead: formdata1.company_name.value,
          group_name: "Liabilities",
          subgroup_name: "Current Liabilities",
          ref: "",
          payment_mode: "credit",
          bank: "NA",
          credit: formdata1.ob.value,
          tax: "0",
          disc: "0",
          debit: "0",
          balance:formdata1.ob.value ,
          remark: "opening balance",
        },
        { headers }
      )
      .then((res) => {
        
      })
      .catch((err) => {
        console.log(err.message);
      });
  
  };

  //Table data record
  // const [data, setData] = useState([]);
  const [itemsperpage, setitemsperpage] = useState([10]);
  const [columns, setColumns] = useState([]);

  const handleDelete = async (id) => {
    deleteRecord("vendors", id, "id");
    deleteRecord("subheads", id, "vendor_id");
    deleteRecord("expenses", id, "purchase_id");
    fetchData("vendors", setData, "id");
  };
  const [head, setHead] = useState([]);
  useEffect(() => {
    fetchData("groups", setSelectDatagroup,"id");
    fetchData("heads", setHeads,"id");
   
    
  }, []);
  return (
    <>
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <Breadcums heading="Vendor Information" paglink="New Vendor" />
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              {/* left column */}
              <div className="col-md-12">
                {/* general form elements */}
                <div className="card card-dark">
                  <div className="card-header">
                    <h3 className="card-title">Add New Vendor</h3>
                  </div>
                  {/* /.card-header */}
                  {/* form start */}
                  <form onSubmit={handleSubmit}>
                    <div className="card-body">
                      <div className="row">
                      {/* <div className="col-2">
                        <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Under Group</label>
                        <select 
                        className="form-control select2"
                        id="group"
                        onChange={(e) => handleChange1(e)}
                        value={formdata.group}
                        >
                          
                        <option selected="NA">--Select--</option>
                        {Selectdatagroup &&
                          Selectdatagroup.map((seldata, index) => {
                            return (
                              <option value={seldata.brand_name}>{seldata.brand_name}</option>
                            );
                          })}
                  </select>
                      </div>
                        </div>
                        <div className="col-2">
                        <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Under Sub Group</label>
                        <select 
                        className="form-control select2"
                        id="subgroup"
                        onChange={(e) => getheads(e)}
                        value={heads.brandname}
                        >
                          
                        <option selected="NA">--Select--</option>
                        {Selectdatasubgroup &&
                          Selectdatasubgroup.map((seldata, index) => {
                            return (
                              <option value={seldata.subgroup_name}>{seldata.subgroup_name}</option>
                            );
                          })}
                  </select>
                      </div>
                        </div> */}

                        <div className="col-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Head</label>
                            <select 
                        className="form-control form-control-sm select2"
                        id="head"
                        onChange={(e) => handleChange(e)}
                        value={heads.brand_name}
                        >
                          
                        <option selected="NA">--Select--</option>
                        {heads &&
                          heads.map((seldata, index) => {
                            return (
                              <option value={seldata.brand_name}>{seldata.brand_name}</option>
                            );
                          })}
                         
                         
                  </select>
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Company Name
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              id="company_name"
                              onChange={(e) => handleChange(e)}
                              value={formdata.company_name}
                            />
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Supplier Name
                            </label>
                            <input
                              type="text"
                              required
                              className="form-control form-control-sm"
                              id="cname"
                              onChange={(e) => handleChange(e)}
                              value={formdata.cname}
                            />
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Contact No.
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              id="contact"
                              onChange={(e) => handleChange(e)}
                              value={formdata.contact}
                            />
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Street 1</label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              id="street1"
                              onChange={(e) => handleChange(e)}
                              value={formdata.street1}
                            />
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Street 2</label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              id="street2"
                              onChange={(e) => handleChange(e)}
                              value={formdata.street2}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                   
                        <div className="col-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">City</label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              id="city"
                              onChange={(e) => handleChange(e)}
                              value={formdata.city}
                            />
                          </div>
                        </div>
                        <div className="col-1">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Pin Code</label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              id="pin"
                              onChange={(e) => handleChange(e)}
                              value={formdata.pin}
                            />
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              GST(if Any)
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              id="gst"
                              onChange={(e) => handleChange(e)}
                              value={formdata.gst}
                            />
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Opening Balance
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              id="ob"
                              onChange={(e) => handleChange(e)}
                              value={formdata.ob}
                            />
                          </div>
                        </div>
                        <div className="col-1">
                          <div className="form-group">
                          <button type="submit" class="btn mt-4 btn-dark float-right">
                        Save
                      </button>
                          </div>
                          </div>
                      </div>
                    </div>
                    {/* /.card-body */}
                    
                  </form>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                {/* general form elements */}
                <div className="card card-dark">
                  <FetchDataComponent
                    tblname="vendors"
                    setData={setData}
                    setColumns={setColumns}
                    id="id"
                  />

                  {/* This component fetches the data and determines columns */}
                  <Pagination
                    data={data}
                    columns={columns}
                    handleDelete={handleDelete}
                    itemsPerPage={itemsperpage}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}
    </>
  );
}
