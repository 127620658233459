import React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import authheader from "../../services/authheader";
import { deleteRecord } from "../../util/deleteRecord";
import { fetchData } from "../../util/fetchData";
import { fetchcombolist } from "../../util/fetch_combolist";
import { getclosingstock } from "../../util/closingstock";
import { fetchData_singlePara } from "../../util/fetchDataby_singlePara";
import { checkToken } from "../../services/checkToken";
import FetchDataComponent from "../../components/tables/fetchTableData";
import ShowTableReportData from "../../components/tables/ShowTableReports";

import Breadcums from "../../common/Breadcums";
import Transport_bar from "../../common/Transport_bar";

export default function Inventory() {
  try {
    checkToken();
  } catch (error) {
    alert("Session logged out.Please logged in again");
    localStorage.clear();
    // history.push('/');
    window.location.href = "/";
  }
  const headers = { Authorization: authheader().access_token };
  const [data, setData] = useState([]);
  const [errors, setErrors] = useState({});
  const [Selectdata, setSelectData] = useState([]);
  const [ProductName, setProductName] = useState([]);
  const [Selectdatagroup, setSelectDatagroup] = useState([]);
  const [DeptName, setDeptName] = useState([]);
  const [Search, setSearch] = useState([]);

  
  const [Selectdatasubgroup, setSelectDatasubgroup] = useState([]);
  const [formdata, setFormData] = useState({
    company_name: "",
    customer_name: "",
    contact: "",
    street1: "",
    street2: "",
    city: "",
    pincode: "",
    gst: "",
    ob: "",
  });
  const handleChange = (e) => {
    setFormData({ ...formdata, [e.target.id]: e.target.value });
  };
  const filterbyCategory = (e) => {
    setFormData({ ...formdata, [e.target.id]: e.target.value });
    //alert(e.target.value);
    getclosingstock("inventory", setData, "category", e.target.value);
    
  };
  const filterbyname = (e) => {
    setFormData({ ...formdata, [e.target.id]: e.target.value });
    //alert(e.target.value);
    fetchData_singlePara("inventory", setData, "prod_name", e.target.value,"id");
  };
  const filterbydept = (e) => {
    setFormData({ ...formdata, [e.target.id]: e.target.value });
    //alert(e.target.value);
    fetchData_singlePara("inventory", setData, "dept", e.target.value,"id");
  };
  
  const [itemsperpage, setitemsperpage] = useState([10]);
  const [columns, setColumns] = useState([]);


  const handleDelete = async (id) => {
    deleteRecord("expenses", id, "id");
    fetchData("expenses", setData, "id");
  };
  useEffect(() => {
  
    fetchData("category", setSelectData, "id");
    fetchcombolist("products", setProductName, "prod_name");
    fetchcombolist("departments", setDeptName, "department_name","id");
    
  }, []);
  return (
    <>
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <Breadcums heading="Inventory Track" paglink="inventory"/>
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              {/* left column */}
              <div className="col-md-12">
                   {/* <Transport_bar /> */}
              </div>
              
            </div>
           
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                  <div className="row">
                  <div className="col-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Category</label>
                            <select
                              className="form-control form-control-sm select2"
                              id="category"
                              onChange={(e) => filterbyCategory(e)}
                              value={Selectdata.group_name}
                            >
                              <option selected="NA">--Select--</option>
                              {Selectdata &&
                                Selectdata.map((seldata, index) => {
                                  return (
                                    <option value={seldata.brand_name}>
                                      {seldata.brand_name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                        <div className="col-3">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          Choose Product
                        </label>
                        <select
                          className="form-control form-control-sm select2"
                          id="prod_name"
                          onChange={(e) => filterbyname(e)}
                          value={formdata.prod_name}
                        >
                          <option selected="NA">--Select--</option>
                          {ProductName &&
                            ProductName.map((seldata, index) => {
                              return (
                                <option value={seldata.prod_name}>
                                  {seldata.prod_name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          Select Department
                        </label>
                        <select
                          className="form-control form-control-sm select2"
                          id="vendor_name"
                          onChange={(e) => filterbydept(e)}
                          value={formdata.vendor_name}
                        >
                          <option selected="NA">--Select--</option>
                          <option value="Sale">Sale</option>
                          {DeptName &&
                            DeptName.map((seldata, index) => {
                              return (
                               
                                <option value={seldata.department_name}>
                                  {seldata.department_name}/{seldata.department_head}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
              
                </div>
                  </div>
                </div>
            
              
                       
                {/* general form elements */}
                <div className="card card-dark">
               
                  <FetchDataComponent
                    tblname="inventory"
                    setData={setData}
                    setColumns={setColumns}
                    id="id"
                  />

                  {/* This component fetches the data and determines columns */}
                  <ShowTableReportData
                    data={data}
                    columns={columns}
                    handleDelete={handleDelete}
                    itemsPerPage={itemsperpage}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}
    </>
  );
}
