import React from 'react'

export default function Footer() {
  return (
    <div>
      <footer className="main-footer">
        <div className="float-right d-none d-sm-block">
          <b>Version</b> 1.0.0
        </div>
        <strong>
          Copyright © 2024-2025 <a href="https://dhruvinnovations.in">CRM Pro-Developed by Dhruv Innovations.Sale/Support:+66-986643299/+91-7832807218</a>.
        </strong>{" "}
        
      </footer>
      {/* Control Sidebar */}
      <aside className="control-sidebar control-sidebar-dark">
        {/* Control sidebar content goes here */}
      </aside>
      {/* /.control-sidebar */}
    </div>
  );
}
