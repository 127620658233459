export default function authheader()
{
   // Authorization:localStorage.getItem('user')
 //  const cheaders = JSON.parse(localStorage.getItem('user'));
  // alert(cheaders);
    const access_token =JSON.parse(localStorage.getItem("token"));
   // const headers = {"Authorization":access_token}
    if(access_token && access_token.length>0)
    {
        return {access_token}
    }
    else{
        
        localStorage.clear();
        window.location.href = '/';
    }
}