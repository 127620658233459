import React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import authheader from "../../services/authheader";
import { deleteRecord } from "../../util/deleteRecord";
import { fetchData } from "../../util/fetchData";
import { fetchData_singlePara } from "../../util/fetchDataby_singlePara";
import { filterdatabydate } from "../../util/filterdatabydate";
import { filterbydate_summary } from "../../util/filterbydate_summary";
import ModalTable from "../../components/ModalTable";
import { checkToken } from "../../services/checkToken";
import FetchDataComponent from "../../components/tables/fetchTableData";
import Pagination from "../../components/tables/Pagination";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Breadcums from "../../common/Breadcums";
import ShowTableData from "../../components/tables/ShowTableData";

export default function Salereport() {
  try {
    checkToken();
  } catch (error) {
    alert("Session logged out.Please logged in again");
    localStorage.clear();
    // history.push('/');
    window.location.href = "/";
  }
  const formatNumber = (num) => {
    return new Intl.NumberFormat().format(num);
  };
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const headers = { Authorization: authheader().access_token };
  const [data, setData] = useState([]);
  const [errors, setErrors] = useState({});
  const [VendorName, setVendorname] = useState([]);
  const [invdetails, setinvdetails] = useState([]);
  
  const [myname, setmyname] = useState([]);
  const [SummaryData, setSummaryData] = useState([]);
  const [Selectdatagroup, setSelectDatagroup] = useState([]);
  const [Selectdatasubgroup, setSelectDatasubgroup] = useState([]);
  const [formdata, setFormData] = useState({
    company_name: "",
    customer_name: "",
    contact: "",
    street1: "",
    street2: "",
    city: "",
    pincode: "",
    gst: "",
    ob: "",
  });
  const handleChange = (e) => {
    setFormData({ ...formdata, [e.target.id]: e.target.value });
  };
  let netamount=0;

  const filterbydate = (e) => {
    e.preventDefault();
    const formdata1 = e.target;
    //alert(formdata.frm);
   
    filterdatabydate("invoices", setData, "invoice_date",formdata.frm,"invoice_date",formdata.to, "id");
    filterbydate_summary("invoices", setSummaryData, "invoice_date",formdata.frm,"invoice_date",formdata.to)
  
  };
 
  const handleChange1 = (e) => {
    setFormData({ ...formdata, [e.target.id]: e.target.value });
    setmyname(e.target.value);
    fetchData_singlePara("invoices", setData, "subhead", e.target.value, "id");
  
  };


 

  //Table data record
  // const [data, setData] = useState([]);
  const [itemsperpage, setitemsperpage] = useState([10]);
  const [columns, setColumns] = useState([]);
  let netamt =0;
  const handleDelete = async (id) => {
    deleteRecord("invoices", id, "id");
    fetchData("invoices", setData, "id");
  };
  useEffect(() => {
    fetchData("invoices", setData, "id");
    fetchData("customers", setVendorname, "id");
   
  }, []);
  
  const closeModal = () => setModalIsOpen(false);
  const openModal = (invoice) => {
    setModalIsOpen(true);
    fetchData_singlePara("invoiceitems", setinvdetails, "inv", invoice, "id");
  }

  return (
    <>
     <ModalTable isOpen={modalIsOpen} onRequestClose={closeModal} data={invdetails} />
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <Breadcums heading="Sale Report-Date Wise" paglink="Sale Report" />
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              {/* left column */}
              <div className="col-md-12">{/* <Transport_bar /> */}</div>
            </div>

            <div className="row">
              <div className="col-md-12">
                {/* general form elements */}
                <div className="row">
               
                  
                  <div className="col-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-3">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">From</label>
                              <input
                                type="date"
                                className="form-control form-control-sm"
                                id="frm"
                                onChange={(e) => handleChange(e)}
                                value={formdata.frm}
                              />
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">To</label>
                              <input
                                type="date"
                                className="form-control form-control-sm"
                                id="to"
                                onChange={(e) => handleChange(e)}
                                value={formdata.to}
                              />
                            </div>
                          </div>
                          <div className="col-6">
                          <Link type="submit" onClick={filterbydate} className="btn btn-dark mt-4">
                                Search
                              </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                </div>
                <div className="card card-dark">
                <div className="card-body table-responsive p-0">
                    <table
                      className="table table-hover text-nowrap"
                      id="tbldata"
                    >
                      <thead className="thead-dark">
                        <tr>
                          
                          <th>Date</th>
                          
                          <th>subtotal</th>
                          <th>Tax</th>
                          <th>Shipping</th>
                          <th>Total Amount</th>
                          <th>Balance Flow</th>
                          
                        </tr>
                      </thead>
                      <tbody>
                   
                       
                           {
                        
                        SummaryData &&
                        SummaryData.map((sumdata, index) => {
                            return (
                            <tr> 
                           
                           
                           <td>{sumdata.invoice_date}</td>
                            <td>{formatNumber(sumdata.subtotal)}</td>
                            <td>{formatNumber(sumdata.taxvalue)}</td>
                            <td>{sumdata.shipping}</td>
                            
                            <td>{formatNumber(sumdata.total_amount)}</td>
                            <td>{formatNumber(netamt =netamt+sumdata.total_amount)}</td>
                            
                          </tr>
                            );
                            
                        })}
    <tr> 
                           
                           
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                           
                           <th>{formatNumber(netamt.toFixed(2))}</th>
                           
                         </tr>
                          
                      </tbody>
                    </table>
                  </div>
                  {/* <FetchDataComponent
                    tblname="invoices"
                    setData={setData}
                    setColumns={setColumns}
                    id="id"
                  /> */}

                  {/* This component fetches the data and determines columns */}
                  {/* <ShowTableData
                    data={data}
                    columns={columns}
                    handleDelete={handleDelete}
                    itemsPerPage={itemsperpage}
                   
                  />
                  <FetchDataComponent
                    tblname="invoices"
                    setData={setData}
                    setColumns={setColumns}
                    id="id"
                  /> */}

                  {/* This component fetches the data and determines columns */}
                  {/* <Pagination
                    data={setData}
                    columns={columns}
                    handleDelete={handleDelete}
                    itemsPerPage={itemsperpage}
                  /> */}
                </div>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}
    </>
  );
}
