// CSVExporter.js

import React from 'react';
import { CSVLink } from 'react-csv';

const CSVExporter = ({ data, headers, filename }) => {
  return (
    <CSVLink data={data} headers={headers} filename={`${filename}.csv`}>
      <button className="btn btn-dark  btn-sm" >
      
      <i class="fa fa-file-csv" aria-hidden="true"></i> 
          </button> 
    </CSVLink>
  );
};

export default CSVExporter;
