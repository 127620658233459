// src/components/ModalTable.js
import React from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root'); // Set the app element for accessibility

const ModalTable = ({ isOpen, onRequestClose, data }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Table Modal"
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
      }}
    >
      <div className='row'>
        <div className='col-11'>
        <h5>Item Details</h5>
        </div>
        <div className='col-1'>
        <button className="btn-danger" onClick={onRequestClose}>X</button>
        </div>
      </div>
     
      <table className='table table-hover text-nowrap ' >
        <thead>
          <tr>
            
            <th>Name</th>
            <th>Qty</th>
            <th>Unit Price</th>
            <th>Tax Value</th>
            <th>Net</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.id}>
             
              <td>{item.product_name}</td>
              <td>{item.quantity}</td>
              <td>{item.unit_price}</td>
              <td>{item.tax}</td>
              <td>{item.total_price}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Modal>
  );
};

export default ModalTable;
