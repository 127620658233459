import React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import authheader from "../../services/authheader";
import { deleteRecord } from "../../util/deleteRecord";
import { fetchData } from "../../util/fetchData";
import { fetchcombolist } from "../../util/fetch_combolist";
import { checkToken } from "../../services/checkToken";
import FetchDataComponent from "../../components/tables/fetchTableData";
import Pagination from "../../components/tables/ShowTableData";
import Breadcums from "../../common/Breadcums";
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export default function IssueBalance() {
  try {
    checkToken();
  } catch (error) {
    alert("Session logged out.Please logged in again");
    localStorage.clear();
    // history.push('/');
    window.location.href = "/";
  }
  const today = new Date()
  const month = today.getMonth() + 1
  const year = today.getFullYear()
  const date = today.getDate()
  const currentDate = year + '/' + month + '/' + date
  
  const headers = { Authorization: authheader().access_token };
  const [data, setData] = useState([]);
  const [errors, setErrors] = useState({});
  const [transaction, settransaction] = useState([]);
  const [loading, setLoading] = useState(false)
  const [Acchead, setAcchead] = useState([])
  const [formdata, setFormData] = useState({
    brand_name: "",
    description: "",
  });
  const handleChange = (e) => {
    setFormData({ ...formdata, [e.target.id]: e.target.value });
  };
  //handle add record
  const handleAddRecord = (newRecord) => {
    setData([...data, newRecord]);
    fetchData("accounthead", setData,"id");
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    const formdata1 = e.target;
   // alert(formdata1.credit.value);
   // alert(formdata1.debit.value);
    try {
        const post1 = axios.post("/insertdata/accounthead",
   {
    date: currentDate,
     head_name: formdata1.fromacchead.value,
     credit: formdata1.credit.value,
     debit: formdata1.debit.value,
     trans_type: formdata1.mode.value,
     remark:"Amount transfer to the account-"+formdata1.toacchead.value,
     
   },
   { headers }
 );
 const post2 =  axios.post("/insertdata/accounthead",
   {
    date: currentDate,
     head_name: formdata1.toacchead.value,
     credit: formdata1.debit.value,
     debit: formdata1.credit.value,
     trans_type: formdata1.mode.value,
     remark:"Amount Recieved from account-"+formdata1.fromacchead.value,
     
   },
   { headers }
 );
 await Promise.all([post1, post2]);
 fetchData("accounthead", setData, "id");
 toast.success('Amount issued');
    }
     catch (error) {
   
        // Handle errors
        //setError('An error occurred while making the POST requests');
        toast.error('An error occurred while making the POST requests')
        console.error('Error:', error)
      } finally {
        setLoading(false)
      }
    }
 
  

  //Table data record
  // const [data, setData] = useState([]);
  const [itemsperpage, setitemsperpage] = useState([10]);
  const [columns, setColumns] = useState([]);

  const changeItem = (e) => {
    setitemsperpage(parseInt(e.target.value, 10));
    //console.log(itemsperpage.items);
  };
  const handleDelete = async (id) => {
    deleteRecord("accounthead", id, "id");
    fetchData("accounthead", setData, "id");
  };

  useEffect(() => {
  fetchData("transactions", settransaction, "id");
  fetchData("accounthead", setData, "id");
  fetchcombolist('accounthead', setAcchead, 'head_name');
  }, []);

  return (
    <>
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <Breadcums heading="Master" paglink="accounthead"/>
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              {/* left column */}
              <div className="col-md-4">
                {/* general form elements */}
                <div className="card card-dark">
                  <div className="card-header">
                    <h3 className="card-title">Account Head Creation</h3>
                  </div>
                  {/* /.card-header */}
                  {/* form start */}
                  <form onSubmit={handleSubmit}>
                    <div className="card-body">
                   
                          <div className='form-group'>
                            <label htmlFor='exampleInputEmail1'>
                             From Account
                            </label>
                            <select
                              className='form-control form-control-sm select2'
                              id='fromacchead'
                              onChange={e => handleChange(e)}
                              value={formdata.fromacchead}
                            >
                              <option selected='NA'>--Select--</option>
                             
                                 {Acchead &&
                                Acchead.map((seldata, index) => {
                                  return (
                                    <option value={seldata.head_name}>
                                      {seldata.head_name}
                                    </option>
                                  )
                                })}
                            </select>
                          </div>
                       
                          <div className='form-group'>
                            <label htmlFor='exampleInputEmail1'>
                             To Account
                            </label>
                            <select
                              className='form-control form-control-sm select2'
                              id='toacchead'
                              onChange={e => handleChange(e)}
                              value={formdata.toacchead}
                            >
                              <option selected='NA'>--Select--</option>
                             
                                 {Acchead &&
                                Acchead.map((seldata, index) => {
                                  return (
                                    <option value={seldata.head_name}>
                                      {seldata.head_name}
                                    </option>
                                  )
                                })}
                            </select>
                          </div>

                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Credit(When company recieve money)</label>
                        <input
                          type="number"
                          className="form-control form-control-sm"
                          id="credit"
                          onChange={(e) => handleChange(e)}
                          value={formdata.credit}
                       
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Debit(When company issue balance)</label>
                        <input
                          type="number"
                          className="form-control form-control-sm"
                          id="debit"
                          onChange={(e) => handleChange(e)}
                          value={formdata.debit}
                         
                        />
                      </div>
                      <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Payment Mode
                            </label>
                            <select
                              className="form-control form-control-sm select2"
                              id="mode"
                              onChange={(e) => handleChange(e)}
                              value={formdata.mode}
                            >
                              <option selected="NA">--Select--</option>
                              {transaction &&
                                transaction.map((seldata, index) => {
                                  return (
                                    <option value={seldata.mode}>
                                      {seldata.mode}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                     
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Remark</label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          id="remark"
                          onChange={(e) => handleChange(e)}
                          value={formdata.remark}
                          placeholder=""
                        />
                      </div>
                    </div>
                    {/* /.card-body */}
                    <div className="card-footer">
                      <button type="submit" class="btn btn-dark float-right btn-sm">
                        Save
                      </button>
                    </div>
                    <ToastContainer />
                  </form>
                </div>
              </div>
              <div className="col-md-8">
                {/* general form elements */}
                <div className="card card-dark">
                  <FetchDataComponent
                    tblname="accounthead"
                    setData={setData}
                    setColumns={setColumns}
                    id="id"
                  />

                  {/* This component fetches the data and determines columns */}
                  <Pagination
                    data={data}
                    columns={columns}
                    handleDelete={handleDelete}
                    itemsPerPage={itemsperpage}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}
    </>
  );
}
