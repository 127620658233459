import React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import authheader from "../../services/authheader";
import { deleteRecord } from "../../util/deleteRecord";
import { fetchData } from "../../util/fetchData";
import { fetchData_singlePara } from "../../util/fetchDataby_singlePara";
import { filterdatabydate } from "../../util/filterdatabydate";
import { filterdatabydatename } from "../../util/filterbydatename";
import ModalTable from "../../components/ModalTable";
import { checkToken } from "../../services/checkToken";
import FetchDataComponent from "../../components/tables/fetchTableData";
import Pagination from "../../components/tables/Pagination";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Breadcums from "../../common/Breadcums";
import ReactModal from 'react-modal';

export default function InvoiceDetails() {
  try {
    checkToken();
  } catch (error) {
    alert("Session logged out.Please logged in again");
    localStorage.clear();
    // history.push('/');
    window.location.href = "/";
  }
  const modalStyles = {
    content: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      background: 'white',
      padding: '20px',
      borderRadius: '10px',
      boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
    },
    overlay: {
      position: 'fixed',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
    },
  };
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpen1, setModalIsOpen1] = useState(false);
  const headers = { Authorization: authheader().access_token };
  const [data, setData] = useState([]);
  const [errors, setErrors] = useState({});
  const [VendorName, setVendorname] = useState([]);
  const [invdetails, setinvdetails] = useState([]);
  const [transaction, settransaction] = useState([]);

  const [myname, setmyname] = useState([]);
  const [SummaryData, setSummaryData] = useState([]);
  const [Selectdatagroup, setSelectDatagroup] = useState([]);
  const [Selectdatasubgroup, setSelectDatasubgroup] = useState([]);
  const [formdata, setFormData] = useState({ id:'',inv:'',custname: '', amount: '',disc:'',mode:'' });
  const [dateformdata, setdateFormData] = useState({ id:'',inv:'',custname: '', amount: '',disc:'',mode:'' });
  const handleChange = (e) => {
    setFormData({ ...formdata, [e.target.id]: e.target.value });
  };

  const filterbydate = (e) => {
    e.preventDefault();
    const formdata2 = e.target;
    //alert(formdata.frm);

    filterdatabydate(
      "invoices",
      setData,
      "invoice_date",
      formdata.frm,
      "invoice_date",
      formdata.to,
      "id"
    );
  };
  const filterbydatename = (e) => {
    e.preventDefault();
    const formdata1 = e.target;
    //alert(formdata.frm);

    filterdatabydatename(
      "invoices",
      setData,
      "date",
      formdata.frm,
      "date",
      formdata.to,
      "subhead",
      formdata.vendor_name,
      "id"
    );
  };
  const handleChange1 = (e) => {
    setFormData({ ...formdata, [e.target.id]: e.target.value });
    setmyname(e.target.value);
    fetchData_singlePara("invoices", setData, "customer_name", e.target.value, "id");
  };
  const today = new Date();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const date = today.getDate();
  const currentDate = year + "/" + month + "/" + date;
  const paynow = async (e,amount,vendorName) => {
    //alert(e);
    const formdata1 = e.target;
     const post1 = axios.post(
       "/insertdata/income",
       {
         sale_id: e,
         date: currentDate,
         invdte: currentDate,
         userid: localStorage.getItem("uname"),
         vendor: vendorName,
         head: "Customers",
         subhead: vendorName,
         ref: "Sale Vcher payment",
         payment_mode: "Cash",
         bank: "NA",
         debit: "0",
         tax: "0",
         disc: "0",
         credit: amount,
         balance: "0",
         remark: "Money credited to JD A/C against Inv No."+e+" from "+formdata1.custname.value,
       },
      { headers }
     );
     const post2 =  axios.post("/insertdata/accounthead",
            {
              date: currentDate,
              head_name: "JD",
              credit: "0",
              debit: amount,
              trans_type: "Cash",
              remark: "Money credited to JD A/C against Inv No."+e+" from "+formdata1.custname.value,
           
              
            },
            { headers }
          );
    const post3 = axios.put(
      "/updatstatus/invoices/id/" + e,
      {
        status: "paid",
      },

      { headers }
    );
    ///alert(post3);
    try {
      await Promise.all([post1,post2,post3]);
      fetchData("invoices", setData, "id");

      toast.success("Bill Cleared");
    } catch (err) {
      // Handle errors
      toast.error("An error occurred while making the POST requests" + err);

      console.error("Error:", err);
    } finally {
     // handleCloseModal();
    }
  };
  const paynow1 = async (e) => {
    //alert(e);
    const formdata1 = e.target;
     const post1 = axios.post(
       "/insertdata/income",
       {
         sale_id: formdata1.inv.value,
         date: currentDate,
         invdte: currentDate,
         userid: localStorage.getItem("uname"),
         vendor: formdata1.custname.value,
         head: "Customers",
         subhead: formdata1.custname.value,
         ref: "Sale Vcher payment",
         payment_mode: formdata1.mode.value,
         bank: "NA",
         debit: "0",
         tax: "0",
         disc: formdata1.disc.value,
         credit: formdata1.amount.value,
         balance: "0",
         remark: "Money credited to JD A/C against Inv No."+formdata1.inv.value+" from "+formdata1.custname.value,
       },
      { headers }
     );
     const post2 =  axios.post("/insertdata/accounthead",
            {
              date: currentDate,
              head_name: "JD",
              credit: formdata1.amount.value,
              debit:"0",
              trans_type: formdata1.mode.value,
              remark: "Money credited to JD A/C against Inv No."+formdata1.inv.value+" from "+formdata1.custname.value,
           
              
            },
            { headers }
          );
    const post3 = axios.put(
      "/updatstatus/invoices/id/" +formdata1.id.value,
      {
        status: "paid",
      },

      { headers }
    );
    const post4 = axios.put(
      "/updatstatus1/customer_bill_details/invoice/" +formdata1.inv.value,
      {
        payment_mode: formdata1.mode.value,
      },

      { headers }
    );
    ///alert(post3);
    try {
      await Promise.all([post1,post2,post3,post4]);
      fetchData("invoices", setData, "id");

      toast.success("Bill Cleared");
    } catch (err) {
      // Handle errors
      toast.error("An error occurred while making the POST requests" + err);

      console.error("Error:", err);
    } finally {
      //setLoading(false);
    }
  };
  const bystatus = (e) => {
    setFormData({ ...formdata, [e.target.id]: e.target.value });
    setmyname(e.target.value);
    fetchData_singlePara("invoices", setData, "status", e.target.value, "id");
  };

  //Table data record
  // const [data, setData] = useState([]);
  const [itemsperpage, setitemsperpage] = useState([10]);
  const [columns, setColumns] = useState([]);

  const handleDelete = async (id) => {
    deleteRecord("invoices", id, "id");
    fetchData("invoices", setData, "id");
  };
  useEffect(() => {
    fetchData("invoices", setData, "id");
    fetchData("customers", setVendorname, "id");
    fetchData("transactions", settransaction, "id");
  }, []);

  const closeModal = () => setModalIsOpen(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = (invoice) => {
    setModalIsOpen(true);
    fetchData_singlePara("invoiceitems", setinvdetails, "inv", invoice, "id");
  };
 
  const handleOpenModal = (data) => {
    setFormData(data);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setFormData({ id:'',inv:'',custname: '', amount: '',disc:'',mode:''});
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission
    console.log('Form submitted');
    handleCloseModal();
  };
  return (
    <>
      <ModalTable
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        data={invdetails}
      />
       <ReactModal
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        contentLabel="User Form"
        style={modalStyles}
      >
        <h2>Payment Details</h2>
        <form onSubmit={paynow1}>
        <div className="row">
              {/* left column */}
              <div className="col-md-12">
              <div className="form-group">
                            <label htmlFor="exampleInputEmail1"> Customer Name</label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              id="custname"
                              readOnly
                              onChange={(e) => handleChange(e)}
                              value={formdata.custname}
                            />
                              <input
                              type="hidden"
                              className="form-control form-control-sm"
                              id="id"
                              readOnly
                              onChange={(e) => handleChange(e)}
                              value={formdata.id}
                            />
                             <input
                              type="hidden"
                              className="form-control form-control-sm"
                              id="inv"
                              readOnly
                              onChange={(e) => handleChange(e)}
                              value={formdata.inv}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1"> Amount </label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              id="amount"
                              onChange={(e) => handleChange(e)}
                              
                              value={formdata.amount}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1"> Discount</label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              id="disc"
                              onChange={(e) => handleChange(e)}
                              value={formdata.disc}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1"> Payment Mode</label>
                            <select
                              className="form-control form-control-sm select2"
                              id="mode"
                              onChange={(e) => handleChange(e)}
                              value={formdata.mode}
                            >
                              <option selected="NA">--Select--</option>
                              {transaction &&
                                transaction.map((seldata, index) => {
                                  return (
                                    <option value={seldata.mode}>
                                      {seldata.mode}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                </div>
                </div>
                <button type="submit" className="btn btn-dark mt-4 float-right" >
                              Save Record 
                            </button>
          
         
        </form>
        <button type="button" className="btn btn-danger mt-4 float-right"  onClick={handleCloseModal}>
            Close
          </button>
      </ReactModal>
        
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <Breadcums heading="Invoice Details" paglink="invoice details" />
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              {/* left column */}
              <div className="col-md-12">{/* <Transport_bar /> */}</div>
            </div>

            <div className="row">
              <div className="col-md-12">
                {/* general form elements */}
                <div className="row">
                  <div className="col-2">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-12">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                Search by Status
                              </label>
                              <select
                                className="form-control form-control-sm select2"
                                id="vendor_name"
                                onChange={(e) => bystatus(e)}
                                value={formdata.vendor_name}
                              >
                                <option selected value="all">
                                  All
                                </option>
                                <option value="paid">paid</option>
                                <option value="unpaid">unpaid</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-12">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                Search by Vendor
                              </label>
                              <select
                                className="form-control form-control-sm select2"
                                id="vendor_name"
                                onChange={(e) => handleChange1(e)}
                                value={formdata.vendor_name}
                              >
                                <option selected="all">All</option>
                                {VendorName &&
                                  VendorName.map((seldata, index) => {
                                    return (
                                      <option value={seldata.company_name}>
                                        {seldata.company_name}/{seldata.gst}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-6">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">From</label>
                              <input
                                type="date"
                                className="form-control form-control-sm"
                                id="frm"
                                onChange={(e) => handleChange(e)}
                                value={formdata.frm}
                              />
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">To</label>
                              <input
                                type="date"
                                className="form-control form-control-sm"
                                id="to"
                                onChange={(e) => handleChange(e)}
                                value={formdata.to}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-12">
                            <div className="row">
                              <div className="col-6">
                                <div className="form-group">
                                  <Link
                                    type="submit"
                                    onClick={filterbydate}
                                    className="btn btn-dark mt-4"
                                  >
                                    Search by date
                                  </Link>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="form-group">
                                  <Link
                                    type="submit"
                                    onClick={filterbydatename}
                                    className="btn btn-dark mt-4"
                                  >
                                    Search by Date & Name
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card card-dark">
                  <div className="card-body table-responsive p-0">
                    <table
                      className="table table-hover text-nowrap"
                      id="tbldata"
                    >
                      <thead className="thead-dark">
                        <tr>
                          <th>Date</th>
                          <th>Voucher No.</th>
                          <th>Customer</th>
                          <th>disc</th>
                          <th>subtotal</th>
                          <th>Tax Value</th>
                          <th>Shipping</th>
                          <th>Total Amount</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data &&
                          data.map((user, index) => {
                            return (
                              <tr key={index}>
                                {/* <td>
                                  <div className="icheck-primary d-inline">
                                    <input
                                      type="checkbox"
                                      id={index}
                                      checked={isChecked}
                                      onChange={handleCheckboxChange}
                                      value={user.credit}
                                    />
                                   
                                  </div>
                                </td> */}
                                <td>{user.invoice_date} </td>
                                <td>
                                  <a
                                    href="#!"
                                    onClick={() => openModal(user.inv)}
                                  >
                                    {user.inv}
                                  </a>
                                </td>

                                <td>{user.customer_name} </td>
                                <td>{user.disc} </td>
                                <td>{user.subtotal} </td>
                                <td>{user.taxvalue} </td>
                                <td>{user.shipping} </td>
                                <td>{user.total_amount} </td>
                                
                                {user.status === "unpaid" ? (
                                  <>
                                    <td>
                                    <span className="badge bg-danger"
                                      >
                                        <a
                                    href="#!"
                                    onClick={() => handleOpenModal({ id:user.id,inv:user.inv,custname: user.customer_name, amount: user.total_amount, disc: '0' })}
                                    
                                  >
                                   Pay Now
                                  </a>
                                       
                                      </span>
                                      
                                    </td>
                                    
                                  </>
                                ) : (
                                  <>
                                    <td>
                                      <span className="badge bg-success">
                                        Paid
                                      </span>
                                    </td>
                                   
                                  </>
                                )}
                                <td>
                                  <Link
                                    to={`/sale/printinvoice/${user.inv}`}
                                    className="badge bg-warning"
                                  >
                                    Print 
                                  </Link>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                  {/* This component fetches the data and determines columns */}

                  <FetchDataComponent
                    tblname="invoices"
                    setData={setData}
                    setColumns={setColumns}
                    id="id"
                  />

                  {/* This component fetches the data and determines columns */}
                  <Pagination
                    data={setData}
                    columns={columns}
                    handleDelete={handleDelete}
                    itemsPerPage={itemsperpage}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}
    </>
  );
}
