
import {  } from "react-router-dom";
import axios from "axios";
import authheader from "../services/authheader";
import { checkToken } from "../services/checkToken";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export const monthlysalechart = async (tblname, productdatachange1) => {
    checkToken();
    const headers = { Authorization: authheader().access_token };
    // try {
         axios
         .get("/monthlysalechart/"+tblname, { headers })
         .then((res) => {
           // alert("fetch data1");
            // console.log(res.data.data);
            const jsonData =res.data.data;
            console.log(jsonData);
            productdatachange1(jsonData);

         })
         .catch((err) => {
          //  productdatachange1("Error fetching data:");
           // toast.error('An error occurred while making the POST requests');
             console.error('Error fetching data:', err);
         });
   
   };
