import React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import authheader from "../../services/authheader";
import { deleteRecord } from "../../util/deleteRecord";
import { fetchData } from "../../util/fetchData";
import { fetchcombolist } from "../../util/fetch_combolist";
import { getclosingstock } from "../../util/closingstock";
import { fetchData_singlePara } from "../../util/fetchDataby_singlePara";
import { checkToken } from "../../services/checkToken";
import FetchDataComponent from "../../components/tables/fetchTableData";
import ShowTableReportData from "../../components/tables/ShowTableReports";
import { minqty } from "../../util/minqty";

import Breadcums from "../../common/Breadcums";
import Transport_bar from "../../common/Transport_bar";

export default function BelowStock() {
  try {
    checkToken();
  } catch (error) {
    alert("Session logged out.Please logged in again");
    localStorage.clear();
    // history.push('/');
    window.location.href = "/";
  }
  const headers = { Authorization: authheader().access_token };
  const [data, setData] = useState([]);
  const [errors, setErrors] = useState({});
  const [Selectdata, setSelectData] = useState([]);
  const [ProductName, setProductName] = useState([]);
  const [Selectdatagroup, setSelectDatagroup] = useState([]);
  const [DeptName, setDeptName] = useState([]);
  const [MinQty, setMinQty] = useState([]);

  
  const [Selectdatasubgroup, setSelectDatasubgroup] = useState([]);
  const [formdata, setFormData] = useState({
    company_name: "",
    customer_name: "",
    contact: "",
    street1: "",
    street2: "",
    city: "",
    pincode: "",
    gst: "",
    ob: "",
  });
  const handleChange = (e) => {
    setFormData({ ...formdata, [e.target.id]: e.target.value });
  };
  const filterbyCategory = (e) => {
    setFormData({ ...formdata, [e.target.id]: e.target.value });
    //alert(e.target.value);
    getclosingstock("inventory", setData, "category", e.target.value);
    
  };
  const filterbyname = (e) => {
    setFormData({ ...formdata, [e.target.id]: e.target.value });
    //alert(e.target.value);
    fetchData_singlePara("inventory", setData, "prod_name", e.target.value,"id");
  };
  const filterbydept = (e) => {
    setFormData({ ...formdata, [e.target.id]: e.target.value });
    //alert(e.target.value);
    fetchData_singlePara("inventory", setData, "dept", e.target.value,"id");
  };
  
  const [itemsperpage, setitemsperpage] = useState([10]);
  const [columns, setColumns] = useState([]);


  const handleDelete = async (id) => {
    deleteRecord("expenses", id, "id");
    fetchData("expenses", setData, "id");
  };
  useEffect(() => {
  
    fetchData("category", setSelectData, "id");
    fetchcombolist("products", setProductName, "prod_name");
    fetchcombolist("departments", setDeptName, "department_name","id");
    minqty("limit 500",setMinQty);
    
  }, []);
  return (
    <>
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <Breadcums heading="Inventory Track" paglink="inventory"/>
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              {/* left column */}
              <div className="col-md-12">
                   {/* <Transport_bar /> */}
              </div>
              
            </div>
           
            <div className="row">
              <div className="col-md-12">
              <div className="card card-dark">
         <div className="card-header border-transparent">
           <h3 className="card-title">Below Average Stock List</h3>
           <div className="card-tools">
             <button type="button" className="btn btn-tool" data-card-widget="collapse">
               <i className="fas fa-minus" />
             </button>
             <button type="button" className="btn btn-tool" data-card-widget="remove">
               <i className="fas fa-times" />
             </button>
           </div>
         </div>
         {/* /.card-header */}
         <div className="card-body p-0">
           <div className="table-responsive">
           <table
                     className="table m-0"
                     id="tbldata"
                   >
                     <thead className="thead">
                       <tr>
                         <th>Products</th>
                         <th>Minimum Quantity</th>
                         <th>Balance</th>
                        
                       </tr>
                     </thead>
                     <tbody>
                  
                       {MinQty &&
                         MinQty.map((user, index) => {
                           return (
                             <tr key={index}>
                              
                                 <td>{user.prod_name} </td>
                                 <td>{user.min_qty} </td>
                               <td>{user.balance} </td>
                             
                              
                             </tr>
                           );
                         })}
                     </tbody>
                   </table>
          
           </div>
           {/* /.table-responsive */}
         </div>
         {/* /.card-body */}
       
         {/* /.card-footer */}
       </div>
            
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}
    </>
  );
}
