import React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import authheader from "../../services/authheader";
import { deleteRecord } from "../../util/deleteRecord";
import { fetchData } from "../../util/fetchData";
import { fetch_data_with_limit } from "../../util/fetch_Data_with_limit";
import { fetchData_singlePara } from "../../util/fetchDataby_singlePara";
import { checkToken } from "../../services/checkToken";
import FetchDataComponent from "../../components/tables/fetchTableData";
import Pagination from "../../components/tables/ShowTableData";
import Breadcums from "../../common/Breadcums";

export default function NewCustomer() {
  try {
    checkToken();
  } catch (error) {
    alert("Session logged out.Please logged in again");
    localStorage.clear();
    // history.push('/');
    window.location.href = "/";
  }
  const today = new Date();
  const month = today.getMonth()+1;
const year = today.getFullYear();
const date = today. getDate();
const currentDate = year + "/" + month + "/" + date;
  const headers = { Authorization: authheader().access_token };
  const [data, setData] = useState([]);

  const [storename, setstorename] = useState([]);
  const [VendorName, setVendorname] = useState([]);
  const [VenderData, setVenderData] = useState([]);
  const [headdata, setheaddata] = useState([]);
  const [heads, setHeads] = useState([]);

  const [errors, setErrors] = useState({});
  const [Selectdatagroup, setSelectDatagroup] = useState([]);
  const [Selectdatasubgroup, setSelectDatasubgroup] = useState([]);
  const [formdata, setFormData] = useState({
    company_name: "",
    customer_name: "",
    contact: "",
    street1: "",
    street2: "",
    city: "",
    pincode: "",
    gst: "",
    ob: "",
  });
  const handleChange1 = (e) => {
    // console.log(e.target.value);
     setFormData({ ...formdata, [e.target.id]: e.target.value });
      
     fetchData_singlePara("subgroups", setSelectDatasubgroup,"group_name",e.target.value, "id");
    // console.log(setSelectDatagroup);
   };
  const handleChange = (e) => {
    setFormData({ ...formdata, [e.target.id]: e.target.value });
  };
  const getheads = (e) => {
    // console.log(e.target.value);
     setFormData({ ...formdata, [e.target.id]: e.target.value });
      
     fetchData_singlePara("heads", setHeads,"subgroup",e.target.value, "id");
    // console.log(setSelectDatagroup);
   };
  //handle add record
  const handleAddRecord = (newRecord) => {
    setData([...data, newRecord]);
    fetchData("customers", setData, "id");
  };
 
  const handleSubmit = (e) => {
    e.preventDefault();
    const formdata1 = e.target;
    axios
      .post(
        "/insertdata/customers",
        {
          group_name: "Assets",
          subgroup_name: "Current Assets",
          head_name: formdata1.head.value,
          company_name: formdata1.company_name.value,
          customer_name: formdata1.cname.value,
          contact: formdata1.contact.value,
          street1: formdata1.street1.value,
          street2: formdata1.street2.value,
          city: formdata1.city.value,
          pincode: formdata1.pin.value,
          gst: formdata1.gst.value,
          ob: formdata1.ob.value,
        },
        { headers }
      )
      .then((res) => {
        fetchData("customers", setData, "id");

        const newRecord = res;
        handleAddRecord(newRecord);
       
        //navigate("/");
      })
      .catch((err) => {
        console.log(err.message);
      });
      axios
      .post(
        "/insertdata/subheads",
        {
          head_name: formdata1.head.value,
          subhead_name: formdata1.company_name.value,
         
        },
        { headers }
      )
      .then((res) => {
        setFormData({
          brand_name: "",
          desc: "",
        });
        //navigate("/");
      })
      .catch((err) => {
        console.log(err.message);
      });
      axios
      .post(
        "/insertdata/income",
        {
          sale_id: data[0].id,
          date: currentDate,
          invdte: currentDate,
          userid: localStorage.getItem('uname'),
          vendor: formdata1.company_name.value,
          head: formdata1.head.value,
          subhead: formdata1.company_name.value,
          group_name: "Assets",
          subgroup_name: "Current Assets",
          ref: "",
          payment_mode: "credit",
          bank: "NA",
          debit: "0",
          vat: "0",
          disc: "0",
          credit: "0",
          balance:formdata1.ob.value ,
          remark: "opening balance",
        },
        { headers }
      )
      .then((res) => {
        setFormData({
          company_name: "",
          cname: "",
          contact: "",
          street1: "",
          street2: "",
          city: "",
          pincode: "",
          gst: "",
          ob: "",
        });
      })
      .catch((err) => {
        console.log(err.message);
      });
    // handleAddRecord(formdata);
    setFormData({});
    setErrors({});
  };

  //Table data record
  // const [data, setData] = useState([]);
  const [itemsperpage, setitemsperpage] = useState([10]);
  const [columns, setColumns] = useState([]);

  const changeItem = (e) => {
    setitemsperpage(parseInt(e.target.value, 10));
    console.log(itemsperpage.items);
  };
  const handleDelete = async (id) => {
    deleteRecord("customers", id, "id");
    fetchData("customers", setData, "id");
  };
  useEffect(() => {
    fetchData("groups", setSelectDatagroup,"id");
    fetchData("heads", setHeads,"id");
    
  }, []);
  return (
    <>
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <Breadcums heading="Customer Information" paglink="New Customer"/>
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              {/* left column */}
              <div className="col-md-12">
                {/* general form elements */}
                <div className="card card-dark">
                  <div className="card-header">
                    <h3 className="card-title">Add New Customer</h3>
                  </div>
                  {/* /.card-header */}
                  {/* form start */}
                  <form onSubmit={handleSubmit}>
                    <div className="card-body">
                      <div className="row">
                      {/* <div className="col-2">
                        <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Under Group</label>
                        <select 
                        className="form-control form-control-sm select2"
                        id="group"
                        onChange={(e) => handleChange1(e)}
                        value={formdata.group}
                        >
                          
                        <option selected="NA">--Select--</option>
                        {Selectdatagroup &&
                          Selectdatagroup.map((seldata, index) => {
                            return (
                              <option value={seldata.brand_name}>{seldata.brand_name}</option>
                            );
                          })}
                  </select>
                      </div>
                        </div>
                        <div className="col-2">
                        <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Under Sub Group</label>
                        <select 
                        className="form-control form-control-sm select2"
                        id="subgroup"
                        onChange={(e) => getheads(e)}
                        value={formdata.subgroup}
                        >
                          
                        <option selected="NA">--Select--</option>
                        {Selectdatasubgroup &&
                          Selectdatasubgroup.map((seldata, index) => {
                            return (
                              <option value={seldata.subgroup_name}>{seldata.subgroup_name}</option>
                            );
                          })}
                  </select>
                      </div>
                        </div> */}
                        <div className="col-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Head</label>
                            <select 
                        className="form-control form-control-sm select2"
                        id="head"
                        onChange={(e) => handleChange(e)}
                        value={heads.brand_name}
                        >
                          
                        <option selected="NA">--Select--</option>
                        {heads &&
                          heads.map((seldata, index) => {
                            return (
                              <option value={seldata.brand_name}>{seldata.brand_name}</option>
                            );
                          })}
                         
                         
                  </select>
                          </div>
                        </div>
                      <div className="col-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Company Name
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              id="company_name"
                              onChange={(e) => handleChange(e)}
                              value={formdata.company_name}
                              
                            />
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Contact Person
                            </label>
                            <input
                              type="text"
                              required
                              className="form-control form-control-sm"
                              id="cname"
                              onChange={(e) => handleChange(e)}
                              value={formdata.cname}
                              
                            />
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Contact No.
                            </label>
                            <input
                              type="number"
                              className="form-control form-control-sm"
                              id="contact"
                              onChange={(e) => handleChange(e)}
                              value={formdata.contact}
                              
                            />
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Street 1
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              id="street1"
                              onChange={(e) => handleChange(e)}
                              value={formdata.street1}
                              
                            />
                          </div>
                        </div>
                      <div className="col-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Street 2
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              id="street2"
                              onChange={(e) => handleChange(e)}
                              value={formdata.street2}
                              
                            />
                          </div>
                        </div>
                        
                      </div>
                      <div className="row">
                      
                        <div className="col-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              City
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              id="city"
                              onChange={(e) => handleChange(e)}
                              value={formdata.city}
                              
                            />
                          </div>
                        </div>
                        <div className="col-1">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Pin Code
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              id="pin"
                              onChange={(e) => handleChange(e)}
                              value={formdata.pin}
                              
                            />
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              GST(if Any)
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              id="gst"
                              onChange={(e) => handleChange(e)}
                              value={formdata.gst}
                              
                            />
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                             Opening Balance
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              id="ob"
                              onChange={(e) => handleChange(e)}
                              value={formdata.ob}
                              
                            />
                          </div>
                        </div>
                        <div className="col-1">
                          <div className="form-group">
                          <button type="submit" class="btn mt-4 btn-dark float-right">
                        Save
                      </button>
                          </div>
                          </div>
                       
                      </div>
                    </div>
                    {/* /.card-body */}
                    
                  </form>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                {/* general form elements */}
                <div className="card card-dark">
                  <FetchDataComponent
                    tblname="customers"
                    setData={setData}
                    setColumns={setColumns}
                    id="id"
                  />

                  {/* This component fetches the data and determines columns */}
                  <Pagination
                    data={data}
                    columns={columns}
                    handleDelete={handleDelete}
                    itemsPerPage={itemsperpage}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}
    </>
  );
}
