import React from 'react';
import QRCodeGenerator from '../components/Qrcodegenerator';

const Qrcode = () => {
  const url1 = 'https://www.instagram.com/jannaatclubofficial_?igsh=cXdvd2RvMXd6MjZp';
  const url2 = 'https://www.facebook.com/jannaatclub?mibextid=LQQJ4d';
  const url3 = 'https://www.sharmasweet.com/';

  return (
    <div className='container'>
      <h1>QR Code Generator Instagram</h1>
  
<br/><br/><br/>
      <QRCodeGenerator url={url1} />
      <br/><br/><br/>
      <h1>QR Code Generator Facebook</h1>
      <QRCodeGenerator url={url2} /><br/><br/><br/>
      <h1>QR Code Generator Sharma Sweet Shop</h1>
      <QRCodeGenerator url={url3} />
    </div>
  );
};

export default Qrcode;
