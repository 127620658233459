import React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import authheader from "../../services/authheader";
import { deleteRecord } from "../../util/deleteRecord";
import { fetchData } from "../../util/fetchData";
import { fetchData_singlePara } from "../../util/fetchDataby_singlePara";
import { filterdatabydate } from "../../util/filterdatabydate";
import { filterdatabydatename } from "../../util/filterbydatename";
import { ledgerSummary } from "../../util/ledger_summary";
import { ledgerSummarybydatename } from "../../util/ledgersummarybydatename";
import { ledgerSummarybydate } from "../../util/ledgersummarybydate";
import { checkToken } from "../../services/checkToken";
import FetchDataComponent from "../../components/tables/fetchTableData";
import ShowLedgerReports from "../../components/tables/ShowLedgerReports";

import Breadcums from "../../common/Breadcums";
import Transport_bar from "../../common/Transport_bar";

export default function Income() {
  try {
    checkToken();
  } catch (error) {
    alert("Session logged out.Please logged in again");
    localStorage.clear();
    // history.push('/');
    window.location.href = "/";
  }
  const headers = { Authorization: authheader().access_token };
  const [data, setData] = useState([]);

  const [VendorName, setVendorname] = useState([]);
  const [labourName, setlabourname] = useState([]);
  const [SummaryData, setSummaryData] = useState([]);
  const [fromdate, setfromdate] = useState([]);
  const [todate, settodate] = useState([]);

  const [errors, setErrors] = useState({});
  const [Selectdatagroup, setSelectDatagroup] = useState([]);
  const [formdata, setFormData] = useState({
    company_name: "",
  });
  const handleChange = (e) => {
    setFormData({ ...formdata, [e.target.id]: e.target.value });
  };
  
  const filterbydate = (e) => {
    e.preventDefault();
    const formdata1 = e.target;
    //alert(formdata.frm);
   
    filterdatabydate("income", setData, "invdte",formdata.frm,"invdte",formdata.to, "id");
    ledgerSummarybydate(
      "income",
      setSummaryData,
      "invdte",
      formdata.frm,
      "invdte",
      formdata.to,
      "credit",
      "debit",
      "tax",
      "disc",
    )
  
  };
  const filterbydatename = (e) => {
    e.preventDefault();
    const formdata1 = e.target;
    //alert(formdata.frm);
   
    filterdatabydatename("income", setData, "invdte",formdata.frm,"invdte",formdata.to,"subhead",formdata.vendor_name, "id");
    ledgerSummarybydatename(
      "income",
      setSummaryData,
      "invdte",
      formdata.frm,
      "invdte",
      formdata.to,
      "subhead",
      formdata.vendor_name,
      "credit",
      "debit",
      "tax",
      "disc",
    )
  
  };
  const handleChange1 = (e) => {
    setFormData({ ...formdata, [e.target.id]: e.target.value });

    fetchData_singlePara("income", setData, "subhead", e.target.value, "id");
    ledgerSummary(
      "income",
      setSummaryData,
      "subhead",
      e.target.value,
      "credit",
      "debit",
      "tax",
      "disc",
      "id"
    );
  };
  //Table data record
  // const [data, setData] = useState([]);
  const [itemsperpage, setitemsperpage] = useState([10]);
  const [columns, setColumns] = useState([]);

  const handleDelete = async (id) => {
    deleteRecord("income", id, "id");
    fetchData("income", setData, "id");
  };
  useEffect(() => {
    fetchData("income", setSelectDatagroup, "id");
    fetchData("customers", setVendorname, "id");
    fetchData("labour_profiles", setlabourname, "id");
    ledgerSummary(
      "income",
      setSummaryData,
      "subhead",
      "all",
      "credit",
      "debit",
      "tax",
      "disc",
      "id"
    );
  }, []);
  return (
    <>
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <Breadcums heading="Income Ledger" paglink="Income" />
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              {/* left column */}
              <div className="col-md-12">{/* <Transport_bar /> */}</div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-3">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-12">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                Search by Customer
                              </label>
                              <select
                                className="form-control form-control-sm select2"
                                id="vendor_name"
                                onChange={(e) => handleChange1(e)}
                                value={formdata.vendor_name}
                              >
                                <option selected="all">All</option>
                                {VendorName &&
                                  VendorName.map((seldata, index) => {
                                    return (
                                      <option value={seldata.company_name}>
                                        {seldata.company_name}/{seldata.gst}
                                      </option>
                                    );
                                  })}
                                   {labourName &&
                                  labourName.map((seldata, index) => {
                                    return (
                                      <option value={seldata.first_name} >
                                        {seldata.first_name} {seldata.last_name}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-6">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">From</label>
                              <input
                                type="date"
                                className="form-control form-control-sm"
                                id="frm"
                                onChange={(e) => handleChange(e)}
                                value={formdata.frm}
                              />
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">To</label>
                              <input
                                type="date"
                                className="form-control form-control-sm"
                                id="to"
                                onChange={(e) => handleChange(e)}
                                value={formdata.to}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-5">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-12">
                          <div className="row">
                          <div className="col-6">
                            <div className="form-group">
                              <Link type="submit" onClick={filterbydate} className="btn btn-dark mt-4">
                                Search by date
                              </Link>
                            </div>
                            </div>
                            <div className="col-6">
                            <div className="form-group">
                            <Link type="submit" onClick={filterbydatename} className="btn btn-dark mt-4">
                                Search by Date & Name
                              </Link>
                             
                            </div>
                            </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* general form elements */}
                <div className="card card-dark">
                  <FetchDataComponent
                    tblname="income"
                    setData={setData}
                    setColumns={setColumns}
                    id="id"
                  />

                  {/* This component fetches the data and determines columns */}
                  <ShowLedgerReports
                    data={data}
                    columns={columns}
                    handleDelete={handleDelete}
                    itemsPerPage={itemsperpage}
                    credit={SummaryData.credit}
                    debit={SummaryData.debit}
                    vat={SummaryData.vat}
                    disc={SummaryData.disc}
                    type="income"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}
    </>
  );
}
