
import generatePDF, { Margin } from "react-to-pdf";

const downloadPDF = function()  {
    // you can also pass React refs, e.g. `generatePDF(ref, options)`
    const tableRef = document.getElementById("tbldata");

    // Check if the table element exists
    if (!tableRef) {
      console.error("Table element not found.");
      return;
    }
  
    // Clone the table element to avoid modifying the original table
    const clonedTable = tableRef.cloneNode(true);
  
    // Exclude specific columns from the cloned table
    const excludedColumns = ["Action"];
    const headers = clonedTable.querySelectorAll("th");
  
    headers.forEach(header => {
      if (excludedColumns.includes(header.textContent)) {
        header.parentNode.removeChild(header);
      }
    });
    return (
      generatePDF(() => tableRef, {
      method: "save",
      filename: "mypos_data_record.pdf",
      page: { margin: Margin.MEDIUM },
    })
    )
  };
  export default downloadPDF;

 