import React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import authheader from "../../services/authheader";
import { deleteRecord } from "../../util/deleteRecord";
import { fetchData } from "../../util/fetchData";
import { checkToken } from "../../services/checkToken";
import FetchDataComponent from "../../components/tables/fetchTableData";
import Pagination from "../../components/tables/ShowTableData";
import Breadcums from "../../common/Breadcums";

export default function Uom() {
  try {
    checkToken();
  } catch (error) {
    alert("Session logged out.Please logged in again");
    localStorage.clear();
    // history.push('/');
    window.location.href = "/";
  }
  const headers = { Authorization: authheader().access_token };
  const [data, setData] = useState([]);
  const [errors, setErrors] = useState({});
  const [formdata, setFormData] = useState({
    tax_name: "",
    
  });
  const handleChange = (e) => {
    setFormData({ ...formdata, [e.target.id]: e.target.value });
  };
  //handle add record
  const handleAddRecord = (newRecord) => {
    setData([...data, newRecord]);
    fetchData("units", setData,"id");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(
        "/insertdata/units",
        {
          unit: formdata.tax_name1,
          
        },
        { headers }
      )
      .then((res) => {
        

        const newRecord = res;
        handleAddRecord(newRecord);
        fetchData("units", setData, "id");
        //navigate("/");
        setFormData({
            tax_name1:""
        })
      })
      .catch((err) => {
        console.log(err.message);
      });
    // handleAddRecord(formdata);
    setFormData({});
    setErrors({});
  };

  //Table data record
  // const [data, setData] = useState([]);
  const [itemsperpage, setitemsperpage] = useState([10]);
  const [columns, setColumns] = useState([]);

  const handleDelete = async (id) => {
    deleteRecord("units", id, "id");
    fetchData("units", setData, "id");
  };

  return (
    <>
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <Breadcums heading="Settings" pagelink="Unit"/>
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              {/* left column */}
              <div className="col-md-6">
                {/* general form elements */}
                <div className="card card-dark">
                  <div className="card-header">
                    <h3 className="card-title">Units Settings</h3>
                  </div>
                  {/* /.card-header */}
                  {/* form start */}
                  <form onSubmit={handleSubmit}>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12">
                            <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Unit Name</label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          id="tax_name1"
                          onChange={(e) => handleChange(e)}
                          value={formdata.tax_name1}
                          placeholder="Enter Unit e.g Kg/Gm"
                        />
                      </div>
                            </div>
                         
                        </div>
                      
                    </div>
                    {/* /.card-body */}
                    <div className="card-footer">
                      <button type="submit" class="btn btn-dark float-right btn-sm">
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-md-6">
                {/* general form elements */}
                <div className="card card-dark">
                  <FetchDataComponent
                    tblname="units"
                    setData={setData}
                    setColumns={setColumns}
                    id="id"
                  />

                  {/* This component fetches the data and determines columns */}
                  <Pagination
                    data={data}
                    columns={columns}
                    handleDelete={handleDelete}
                    itemsPerPage={itemsperpage}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}
    </>
  );
}
