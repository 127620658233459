
import {  } from "react-router-dom";
import axios from "axios";
import authheader from "../services/authheader";
import { checkToken } from "../services/checkToken";

export const getOrder = async (tblname, setData,max) => {
    checkToken();
    const headers = { Authorization: authheader().access_token };
    // try {
         axios
         .get("/getorder/"+tblname+"/"+max, { headers })
         .then((res) => {
          //  alert(val1);
            // console.log(res.data.data);
            const jsonData =res.data.data[0].orderno;
            //console.log(jsonData);
            setData(jsonData);

         })
         .catch((err) => {
             console.error('Error fetching data:', err);
         });
   
   };
