import React, { useState, useEffect } from 'react';
import {  } from "react-router-dom";
import axios from "axios";
import authheader from "../../services/authheader";
import { checkToken } from '../../services/checkToken';


export default function FetchDataComponent({ tblname, setData, setColumns,id }) {
  //  const [data, setData] = useState([]);
  checkToken();
  const headers = { Authorization: authheader().access_token };
  
    const fetchData = async () => {
    
       // try {
            axios
            .get("/viewalldata/"+tblname+"/"+id, { headers })
            .then((res) => {
               // console.log(res.data.data);
               const jsonData =res.data.data;
                // Extract specific columns from the received data
              if(tblname==="users")
              {
                const specificData = jsonData.map(item => ({
                  id: item.id,
                  name: item.name,
                  uname: item.uname,
                  type: item.type,
                  // Add more columns as needed
                }));
                setData(specificData);
                if (jsonData.length > 0) {
                  const columns = Object.keys(specificData[0]);
                  columns.push('Action');
                  setColumns(columns);
                }
              } 
              else if(tblname==="invoiceitems")
                {
                  const specificData = jsonData.map(item => ({
                    product_name: item.product_name,
                    quantity: item.quantity,
                    unit_price: item.unit_price,
                    disc: item.disc,
                    cgst: item.cgst,
                    sgst: item.sgst,
                    igst: item.igst,
                    tax: item.tax,
                    total_price: item.total_price,
                    // Add more columns as needed
                  }));
                  setData(specificData);
                  if (jsonData.length > 0) {
                    const columns = Object.keys(specificData[0]);
                    columns.push('Act');
                    setColumns(columns);
                  }
                } 
                else if(tblname==="accounthead")
                  {
                    const specificData = jsonData.map(item => ({
                      date: item.date,
                      head_name: item.head_name,
                      trans_type: item.trans_type,
                      credit: item.credit,
                      debit: item.debit,
                      remark: item.remark,
                      
                      // Add more columns as needed
                    }));
                    setData(specificData);
                    if (jsonData.length > 0) {
                      const columns = Object.keys(specificData[0]);
                     
                      setColumns(columns);
                    }
                  } 
                else if(tblname==="purchases")
                  {
                    const specificData = jsonData.map(item => ({
                      prod_name: item.prod_name,
                      qty: item.qty,
                      unit_price: item.unit_price,
                      disc: item.disc,
                      subtotal: item.subtotal,
                      tax_percent: item.tax_percent,
                      total_cost: item.total_cost,
                      // Add more columns as needed
                    }));
                    setData(specificData);
                    if (jsonData.length > 0) {
                      const columns = Object.keys(specificData[0]);
                      columns.push('Delete');
                      setColumns(columns);
                    }
                  } 
                  else if(tblname==="expenses" || tblname==="journal" )
                    {
                      const specificData = jsonData.map(item => ({
                        date: item.date,
                        invdte: item.invdte,
                        vendor: item.vendor,
                        ref: item.ref,
                        // mode: item.mode,
                        // bank: item.bank,
                        credit: item.credit,
                        tax: item.tax,
                        disc: item.disc,
                        debit: item.debit,
                      //  balance: item.balance,
                        remark: item.remark,
                        // Add more columns as needed
                      }));
                      setData(specificData);
                      if (jsonData.length > 0) {
                        const columns = Object.keys(specificData[0]);
                        //columns.push('Delete');
                        setColumns(columns);
                      }
                    } 
                    else if( tblname==="income")
                      {
                        const specificData = jsonData.map(item => ({
                          date: item.date,
                          invdte: item.invdte,
                          vendor: item.vendor,
                          //ref: item.ref,
                          payment_mode: item.payment_mode,
                          // bank: item.bank,
                          debit: item.debit,
                         // tax: item.tax,
                          disc: item.disc,
                          credit: item.credit,
                          
                         
                        //  balance: item.balance,
                          remark: item.remark,
                          // Add more columns as needed
                        }));
                        setData(specificData);
                        if (jsonData.length > 0) {
                          const columns = Object.keys(specificData[0]);
                          //columns.push('Delete');
                          setColumns(columns);
                        }
                      } 
                    else if(tblname==="inventory")
                      {
                        const specificData = jsonData.map(item => ({
                          //id: item.id,
                          //userid: item.userid,
                          pdate: item.pdate,
                          prod_id: item.prod_id,
                          category: item.category,
                          dept: item.dept,
                          prod_name: item.prod_name,
                          stockin: item.stockin,
                          stockout: item.stockout,
                          balance: item.balance,
                        
                          // Add more columns as needed
                        }));
                        setData(specificData);
                        if (jsonData.length > 0) {
                          const columns = Object.keys(specificData[0]);
                          //columns.push('Delete');
                          setColumns(columns);
                        }
                      } 
                      else if(tblname==="contracts")
                        {
                          const specificData = jsonData.map(item => ({
                            name: item.name,
                          contact: item.contact,
                          address: item.address,
                          start_date: item.start_date,
                          end_date: item.end_date,
                          work_type: item.work_type,
                          total_value: item.total_value,
                          payment_terms: item.payment_terms,
                          
                            // Add more columns as needed
                          }));
                          setData(specificData);
                          if (jsonData.length > 0) {
                            const columns = Object.keys(specificData[0]);
                            columns.push('Actions');
                            setColumns(columns);
                          }
                        } 
              else{
                setData(jsonData);
                if (jsonData.length > 0) {
                  const columns = Object.keys(jsonData[0]);
                  columns.push('Action');
          
                  setColumns(columns);
                }
              }

                // Assuming the first item in the array contains the keys for column headers
     
            })
            .catch((err) => {
              checkToken();
                console.error('Error fetching data:', err);
            });
      
      };
    
      useEffect(() => {
        fetchData();
        //deleteData();
      }, []);
      return null; // This component doesn't render anything, it just fetches the data
    }
  
    



  
