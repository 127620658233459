// ExcelDownloader.js

import React from 'react'
import { CSVLink } from 'react-csv'

const ExcelDownloader1 = ({ data, headers, filename }) => {
  return (
    <CSVLink data={data} headers={headers} filename={`${filename}.xlsx`}>
      <button className='btn btn-success  btn-sm'>
        <i class='fa fa-file-excel' aria-hidden='true'></i>
      </button>
    </CSVLink>
  )
}

export default ExcelDownloader1
