import React, { useState, useEffect } from 'react';
import {  } from "react-router-dom";
import axios from "axios";
import authheader from "../../services/authheader";
import { checkToken } from '../../services/checkToken';


export default function FetchDataAcquitance({ tblname, setData, setColumns,id }) {
  //  const [data, setData] = useState([]);
  checkToken();
  const headers = { Authorization: authheader().access_token };
  
    const fetchData = async () => {
    
       // try {
            axios
            .get("/viewalldata/"+tblname+"/"+id, { headers })
            .then((res) => {
               // console.log(res.data.data);
               const jsonData =res.data.data;
                // Extract specific columns from the received data
          
             
                   if(tblname==="expenses")
                    {
                      const specificData = jsonData.map(item => ({
                        date: item.date,
                        vendor: item.vendor,
                        ref: item.ref,
                        // mode: item.mode,
                        // bank: item.bank,
                        credit: item.credit,
                        tax: item.tax,
                        disc: item.disc,
                        debit: item.debit,
                      //  balance: item.balance,
                        remark: item.remark,
                        // Add more columns as needed
                      }));
                      setData(specificData);
                      if (jsonData.length > 0) {
                        const columns = Object.keys(specificData[0]);
                        //columns.push('Delete');
                        setColumns(columns);
                      }
                    } 
                    else if( tblname==="income")
                      {
                        const specificData = jsonData.map(item => ({
                            main_account: item.main_account,
                          payment_made: item.payment_made,
                          //ref: item.ref,
                          payment_recieved: item.payment_recieved,
                          // bank: item.bank,
                          balance: item.balance,
                         
                        }));
                        setData(specificData);
                        if (jsonData.length > 0) {
                          const columns = Object.keys(specificData[0]);
                          //columns.push('Delete');
                          setColumns(columns);
                        }
                      } 
                    
              else{
                setData(jsonData);
                if (jsonData.length > 0) {
                  const columns = Object.keys(jsonData[0]);
                  columns.push('Action');
          
                  setColumns(columns);
                }
              }

                // Assuming the first item in the array contains the keys for column headers
     
            })
            .catch((err) => {
              checkToken();
                console.error('Error fetching data:', err);
            });
      
      };
    
      useEffect(() => {
        fetchData();
        //deleteData();
      }, []);
      return null; // This component doesn't render anything, it just fetches the data
    }
  
    



  
