import React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

export default function Transport_bar() {
  return (
    <div className="row bg-warning">
    <div className="col-md-2 mt-3">
    <div className="form-group">
    <Link to="/transports/booking" className="btn btn-dark" >
        Booking
        </Link>
      
      </div>
    </div>
    <div className="col-md-2 mt-3">
    <div className="form-group">
    <Link to="/transports/booking/scheduling" className="btn btn-dark" >
        Scheduling
        </Link>
      
      </div>
    </div>
    <div className="col-md-3 mt-3">
    <div className="form-group">
    <Link to="/transports/booking/vehicleinout" className="btn btn-dark" >
        Vehicles In/Out
        </Link>
      
      </div>
    </div>
    <div className="col-md-2 mt-3">
    <div className="form-group">
    <Link to="/transports/booking/availability" className="btn btn-dark" >
        Availability
        </Link>
      
      </div>
    </div>
    <div className="col-md-3 mt-3">
    <div className="form-group">
    <Link to="/transports/booking/maintainence" className="btn btn-dark" >
        Maintenance & Repair
        </Link>
      
      </div>
    </div>
</div>
  )
}
