
import {  } from "react-router-dom";
import axios from "axios";
import authheader from "../services/authheader";
import { checkToken } from "../services/checkToken";

export const account_head_ledgerSummarybydatename = async (tblname, setData,col1,val1,col2,val2,col3,val3,credit,debit) => {
    checkToken();
    const headers = { Authorization: authheader().access_token };
    // try {
         axios
         .get("/accounthead_ledger_summary_bydatename/"+tblname+"/"+col1+"/"+val1+"/"+col2+"/"+val2+"/"+col3+"/"+val3+"/"+credit+"/"+debit, { headers })
         .then((res) => {
           // alert("fetch data1");
            // console.log(res.data.data);
            const jsonData =res.data.data[0];
            console.log(jsonData);
            setData(jsonData);

         })
         .catch((err) => {
             console.error('Error fetching data:', err);
         });
   
   };
