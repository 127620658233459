import React from 'react'
import {Outlet} from 'react-router-dom'
import { useAuth } from '../Context/AuthProvider'
import Header from './Header'
import Sidebar from './Sidebar'
import CashierSidebar from './CashierSidebar'
import StoreSidebar from './StoreSidebar'
import AccountSidebar from './AccountSidebar'

import Sidebar1 from './Sidebar1'
import ToggleButton from '../components/ToggleButton'
import Footer from './Footer'

export default function Layout() {
  let contentsidebar;
  //const user = localStorage.getItem("usertype");
  const { user } = useAuth();
  //alert(user.username);
  if (user.role === 'admin' || user.role === 'Admin') {
    contentsidebar =<Sidebar />
  }
  else if (user.role === 'cashier') {
    contentsidebar =<CashierSidebar />
  }
  else if (user.role === 'store') {
    contentsidebar =<StoreSidebar />
  }
  else if (user.role === 'account') {
    contentsidebar =<AccountSidebar />
  }
  

  return (
    
    <>
    <Header />
    {contentsidebar}
   
    <Outlet />
    <Footer />
    </>
   
  )
}
