// UseDataComponent.js

import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import pdfGenerator from "./PDFExportComponent";
import ExcelDownloader1 from "./ExcelDownloader1";
import CSVExporter from "./csvexporter";
import Pagination from "./Pagination";
import { checkToken } from "../../services/checkToken";
import { ledgerSummary } from "../../util/ledger_summary";
 
function ShowLedgerReports1({ data, columns,credit,debit,vat,disc,type }) {
  checkToken();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setitemsperpage] = useState([10]);
  const [SummaryData, setSummaryData] = useState([]);
  const [number, setNumber] = useState(1234567); // Example existing number

  const formatNumber = (num) => {
    return new Intl.NumberFormat().format(num);
  };
  //change page
  const paginate = (pagenumber) => setCurrentPage(pagenumber);

  const totalPages = Math.ceil(data.length / itemsPerPage);
  //console.log("total page:"+totalPages);
  let startIndex = parseInt((currentPage - 1) * itemsPerPage);
  // console.log("Start Index:"+startIndex);
  let endIndex = startIndex + parseInt(itemsPerPage);
  // console.log("End Index:"+endIndex);
  const currentData = data.slice(startIndex, endIndex);
  // console.log("Current Data:"+currentData);
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePrint = () => {
    const table = document.getElementById("tbldata");
    if (table) {
      const newWin = window.open("");
      newWin.document.write(
        "<html><head><title>Data Records</title></head><body>"
      );
      newWin.document.write("<h1> Records</h1>");
      newWin.document.write(table.outerHTML);
      newWin.document.write("</body></html>");
      newWin.document.close();
      newWin.print();
      newWin.close();
    } else {
      console.error("Table not found");
    }
  };

  const changeItem = (e) => {
    setitemsperpage(parseInt(e.target.value, 10));
    //console.log(itemsPerPage.items);
  };
  
  const cellStyle = {
    paddingTop: '0.50rem', // Adjust top padding as needed
    paddingBottom: '0.25rem', // Adjust bottom padding as needed
    paddingLeft: '1rem', // Keep left padding standard (adjust as needed)
    paddingRight: '1rem', // Keep right padding standard (adjust as needed)
  };
  const rowStyle = {
    fontSize:'14Px' ,
   //float:'right'// Adjust this value as needed
  };
  return (
    <>
      <div className="card-header">
        <h5 className="card-title">
          <select
            className="form-control form-control-sm select2"
            aria-label="Default select example"
            id="items"
            onChange={(e) => changeItem(e)}
            value={itemsPerPage}
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={40}>40</option>
            <option value={80}>80</option>
            <option value={100}>100</option>
            <option value={200}>200</option>
            <option value={500}>500</option>
            <option value={50000}>All</option>
          </select>
        </h5>

        <div className="card-tools">
          <CSVExporter
            data={currentData}
            headers={columns}
            filename="CRM_PRO_data_record"
          />
          <button className="btn btn-dark  btn-sm" onClick={pdfGenerator}>
            <i class="fa fa-file-pdf" aria-hidden="true"></i>
          </button>
          <button className="btn btn-dark  btn-sm" onClick={handlePrint}>
            <i class="fa fa-print" aria-hidden="true"></i>
          </button>
        </div>
      </div>
      <div className="card-body table-responsive p-0">
        <table className="table table-hover table-head-fixed  text-nowrap"  id="tbldata">
          <thead>
         
            <tr>
            <th >#</th>
              {columns.map((column) => (
                <th key={column} >{column}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentData.map((row, index) => (
              <tr key={index}>
                  <td style={cellStyle} key={index+1}  > {index+1 } </td>
                {columns.map((column) => (
                  <td key={column}  style={cellStyle}>
                    {row[column] 
                      
                   
                      }
                  </td>
                ))}
              </tr>
            ))}
            {
            type==="income" ? 
        <tr>
                <th> </th>
               
                <th> </th>
                
               
                <th colspan="2">Total Debit: {debit}</th>
               
                <th colspan="2"> Total Credit:{credit}</th>
                <th colspan="2">Net Balance : { formatNumber(parseFloat(debit)-parseFloat(credit))}
                 </th>
            </tr>
            :
            <tr>
            <th> </th>
           
            <th> </th>
            
            
            <th colspan="2"> Total Credit:{formatNumber(credit)}</th>
            
            <th colspan="2"> Total Debit:{formatNumber(debit)}</th>
            <th colspan="2">Net Balance: { formatNumber(parseFloat(credit)-parseFloat(debit))}
          
            </th>
        </tr>
           
            }
            
          </tbody>
        </table>
      </div>
      <div className="card-footer">
        <Pagination
          postsPerPage={itemsPerPage}
          totalPosts={data.length}
          paginate={paginate}
        />
      </div>
    </>
  );
}

export default ShowLedgerReports1;
