import React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import authheader from "../../services/authheader";
import { deleteRecord } from "../../util/deleteRecord";
import { fetchData } from "../../util/fetchData";
import { fetchData_singlePara } from "../../util/fetchDataby_singlePara";
import { filterdatabydate } from "../../util/filterdatabydate";
import { filterdatabydatename } from "../../util/filterbydatename";
import { ledgerSummary } from "../../util/ledger_summary";
import { ledgerSummarybydatename } from "../../util/ledgersummarybydatename";
import { ledgerSummarybydate } from "../../util/ledgersummarybydate";
import { checkToken } from "../../services/checkToken";
import FetchDataComponent from "../../components/tables/fetchTableData";
import ShowLedgerReports from "../../components/tables/ShowLedgerReports";
import { vehiclemileage } from "../../util/vehiclemileage";
import Breadcums from "../../common/Breadcums";
import Transport_bar from "../../common/Transport_bar";

export default function VehicleMileage() {
  try {
    checkToken();
  } catch (error) {
    alert("Session logged out.Please logged in again");
    localStorage.clear();
    // history.push('/');
    window.location.href = "/";
  }
  const headers = { Authorization: authheader().access_token };
  const [data, setData] = useState([]);

  const [VendorName, setVendorname] = useState([]);
  const [labourName, setlabourname] = useState([]);
  const [SummaryData, setSummaryData] = useState([]);
  const [fromdate, setfromdate] = useState([]);
  const [todate, settodate] = useState([]);

  const [errors, setErrors] = useState({});
  const [Selectdatagroup, setSelectDatagroup] = useState([]);
  const [formdata, setFormData] = useState({
    company_name: "",
  });
  const handleChange = (e) => {
    setFormData({ ...formdata, [e.target.id]: e.target.value });
  };
  
  const filterbydate = (e) => {
    e.preventDefault();
    const formdata1 = e.target;
    //alert(formdata.frm);
    
    vehiclemileage("vehicle_logs", setData, "log_date",formdata.frm,"log_date",formdata.to,"vehicle",formdata.vendor_name);
     
  };

  const handleChange1 = (e) => {
    setFormData({ ...formdata, [e.target.id]: e.target.value });

   
  };
  //Table data record
  // const [data, setData] = useState([]);
  const [itemsperpage, setitemsperpage] = useState([10]);
  const [columns, setColumns] = useState([]);

 
  useEffect(() => {
   
    fetchData("vehicles", setVendorname, "id");
  
  }, []);
  return (
    <>
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <Breadcums heading="Vehicle Mileage" paglink="Mileage" />
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              {/* left column */}
              <div className="col-md-12">{/* <Transport_bar /> */}</div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="row">
                 
                  <div className="col-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                            <div className="col-3">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                Select Vehicle
                              </label>
                              <select
                                className="form-control form-control-sm select2"
                                id="vendor_name"
                                onChange={(e) => handleChange1(e)}
                                value={formdata.vendor_name}
                              >
                                <option selected="all">All</option>
                                {VendorName &&
                                  VendorName.map((seldata, index) => {
                                    return (
                                      <option value={seldata.regno}>
                                        {seldata.regno}
                                      </option>
                                    );
                                  })}
                                 
                              </select>
                            </div>
                            </div>
                          <div className="col-3">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">From</label>
                              <input
                                type="date"
                                className="form-control form-control-sm"
                                id="frm"
                                onChange={(e) => handleChange(e)}
                                value={formdata.frm}
                              />
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">To</label>
                              <input
                                type="date"
                                className="form-control form-control-sm"
                                id="to"
                                onChange={(e) => handleChange(e)}
                                value={formdata.to}
                              />
                            </div>
                          </div>
                          <div className="col-3">
                          <div className="form-group">
                              <Link type="submit" onClick={filterbydate} className="btn btn-dark mt-4">
                                Search 
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                
                </div>
                {/* general form elements */}
                <div className="card card-dark">
         <div className="card-header border-transparent">
           <h3 className="card-title">Mileage Record</h3>
           
         </div>
         {/* /.card-header */}
         <div className="card-body p-0">
           <div className="table-responsive">
           <table
                     className="table m-0"
                     id="tbldata"
                   >
                     <thead className="thead">
                       <tr>
                       <th>Month</th>
                       <th>Vehicle</th>
                         <th>Fuel</th>
                         <th>Km</th>
                         <th>Mileage</th>
                        
                       </tr>
                     </thead>
                     <tbody>
                  
                       {data &&
                         data.map((user, index) => {
                           return (
                             <tr key={index}>
                              
                                 <td>{user.date} </td>
                                 <td>{user.vehicle} </td>
                               <td>{user.fuel} </td>
                               <td>{user.km} </td>
                               <td>{(user.km/user.fuel).toFixed(2)} Km/Ltr. </td>
                             
                              
                             </tr>
                           );
                         })}
                     </tbody>
                   </table>
          
           </div>
           {/* /.table-responsive */}
         </div>
        
         {/* /.card-footer */}
       </div>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}
    </>
  );
}
