
import {  } from "react-router-dom";
import axios from "axios";
import authheader from "../services/authheader";
import { checkToken } from "../services/checkToken";

export const acquitance_bydatename = async (tblname, productdatachange1,col1,col2,col3,val1,val2,val3,groupby) => {
    checkToken();
    const headers = { Authorization: authheader().access_token };
    // try {
         axios
         .get("/acqfilterdatabydatename/"+tblname+"/"+col1+"/"+val1+"/"+col2+"/"+val2+"/"+col3+"/"+val3+"/"+groupby, { headers })
         .then((res) => {
           // alert("fetch data1");
           //  console.log(res.data.data);
            const jsonData =res.data.data;
           // console.log(jsonData);
            productdatachange1(jsonData);

         })
         .catch((err) => {
             console.error('Error fetching data:', err);
         });
   
   };
