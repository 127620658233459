import React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import authheader from "../../services/authheader";
import { deleteRecord } from "../../util/deleteRecord";
import { fetchData } from "../../util/fetchData";
import { fetchcombolist } from "../../util/fetch_combolist";
import { ledgerSummary } from "../../util/ledger_summary";
import { checkToken } from "../../services/checkToken";
import { fetchData_singlePara } from "../../util/fetchDataby_singlePara";
import FetchDataComponent from "../../components/tables/fetchTableData";
import ShowTableReportData from "../../components/tables/ShowTableReports";
import { fetch_data_with_limit } from "../../util/fetch_Data_with_limit";
import { twoparawithlimit } from "../../util/twoparawithlimit";
import Breadcums from "../../common/Breadcums";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { format } from "date-fns";
import getCurrentDate from "../../util/getCurrentDate";

export default function AcquitanceSummary() {
  try {
    checkToken();
  } catch (error) {
    alert("Session logged out.Please logged in again");
    localStorage.clear();
    // history.push('/');
    window.location.href = "/";
  }
  const headers = { Authorization: authheader().access_token };
  const [data, setData] = useState([]);

  const [LabourData, setLabourData] = useState([]);
  const [transaction, settransaction] = useState([]);
  const [name, setname] = useState(["", "", ""]);
  const [attd, setattd] = useState(["", "", ""]);
  const [VendorName, setVendorname] = useState([]);
  const [VenderData, setVenderData] = useState([]);
  const [PreviousData, setPreviousData] = useState([]);
  const [Acchead, setAcchead] = useState([]);
  const [totalvalue, setTotalvalue] = useState("0");
  const [totalDeductionvalue, setTotalDeductionvalue] = useState("0");
  const [netpay, setNetPay] = useState("0");
  const [balanceAmount, SetbalanceAmount] = useState("0");
  const [selectedDate, setSelectedDate] = useState(getCurrentDate);
  const [SummaryData, setSummaryData] = useState([]);
  const [Selectdatasubgroup, setSelectDatasubgroup] = useState([]);
  const [formdata, setFormData] = useState({
    hrs: "0",
    wage: "",
    amountofthemonth: "",
    ob: "",
    credit: "0",
    advancepay: "0",
    mess: "0",
    totaldeduction: "0",
    totalamount: "0",
    amountpaid: "0",
    balance: "0",
  });
  const [selectedMonthYear, setSelectedMonthYear] = useState("");
  const [previousMonthYear, setPreviousMonthYear] = useState("");
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const years = Array.from(new Array(3), (val, index) => index + 2024);

  const monthYearOptions = [];
  years.forEach((year) => {
    months.forEach((month) => {
      monthYearOptions.push(`${month} ${year}`);
    });
  });

  const handleMonthYearChange = (e) => {
    const selected = e.target.value;
    setSelectedMonthYear(e.target.value);
    calculatePreviousMonthYear(selected);
  };
  const calculatePreviousMonthYear = (currentMonthYear) => {
    const [currentMonth, currentYear] = currentMonthYear.split(" ");
    const currentMonthIndex = months.indexOf(currentMonth);
    let previousMonthIndex = currentMonthIndex - 1;
    let previousYear = parseInt(currentYear, 10);

    if (previousMonthIndex < 0) {
      previousMonthIndex = 11; // Wrap around to December
      previousYear -= 1; // Decrement the year
    }

    const previousMonth = months[previousMonthIndex];
    setPreviousMonthYear(`${previousMonth} ${previousYear}`);
  };

  const handleChange = (e) => {
    setFormData({ ...formdata, [e.target.id]: e.target.value });
  };
  const today = new Date();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const date = today.getDate();
  const currentDate = year + "/" + month + "/" + date;
  const handleChange2 = (e) => {
    setFormData({ ...formdata, [e.target.id]: e.target.value });

    fetchData_singlePara("labour_salary", setData, "salary_month", e.target.value, "id");
  
  };
  const getbymainaccount = (e) => {
    setFormData({ ...formdata, [e.target.id]: e.target.value });

    fetchData_singlePara("labour_salary", setData, "main_account", e.target.value, "id");
  
  };
  const getbymode = (e) => {
    setFormData({ ...formdata, [e.target.id]: e.target.value });

    fetchData_singlePara("labour_salary", setData, "mode", e.target.value, "id");
  
  };
  const getVendorDetails = (e) => {
    fetch_data_with_limit(
      "labour_profiles",
      setVenderData,
      "first_name",
      e.target.value,
      "id",
      "limit 1"
    );
    ledgerSummary(
      "income",
      setSummaryData,
      "subhead",
      e.target.value,
      "credit",
      "debit",
      "tax",
      "disc",
      "id"
    );
    // alert(previousMonthYear);
    twoparawithlimit(
      "labour_salary",
      setPreviousData,
      "name",
      e.target.value,
      "salary_month",
      previousMonthYear,
      "id",
      "limit 1"
    );
  };
  const cal = (e) => {
    let hr = parseFloat(formdata.hrs);
    let ob1 = parseFloat(PreviousData.balance);
    let ocredit = parseFloat(formdata.credit);
    let advancepay = parseFloat(SummaryData.debit-SummaryData.credit);
    let messded = parseFloat(formdata.mess);
    let amtpaid = parseFloat(formdata.amountpaid);

    let par = parseFloat(VenderData.salary / 8);
    let total = hr * par;
    let totalded = ob1 + ocredit + advancepay + messded;
    let net_pay = total - totalded;
    let balanceamt = net_pay - amtpaid;
    setTotalDeductionvalue(totalded);
    setTotalvalue(total);
    setNetPay(net_pay);
    SetbalanceAmount(balanceamt);
  };
  const handleInputChange1 = (index, event) => {
    const newInputs1 = [...name];
    newInputs1[index] = event.target.value;
    setname(newInputs1);
  };
  const handleInputChange2 = (index, event) => {
    const newInputs2 = [...attd];
    newInputs2[index] = event.target.value;
    setattd(newInputs2);
  };
  const addInputBox = () => {
    setname([...name, ""]);
    setattd([...attd, ""]);
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post("/insertdata", { name, attd });
      if (response.status === 200) {
        toast.success("Data Saved Successfully");
        alert("Values inserted successfully");
      }
    } catch (error) {
      toast.error("Error inserting values");
    }
  };


  //Table data record
  // const [data, setData] = useState([]);
  const [itemsperpage, setitemsperpage] = useState([10]);
  const [columns, setColumns] = useState([]);

  const handleDelete = async (id) => {
    deleteRecord("expenses", id, "id");
    fetchData("expenses", setData, "id");
  };
  useEffect(() => {
    fetchData("labour_salary", setData, "id");
    fetchData("transactions", settransaction, "id");
    fetchData("labour_salary", setLabourData, "id");
    fetchData("labour_profiles", setVendorname, "id");
    fetchcombolist("accounthead", setAcchead, "head_name");
  }, []);
  return (
    <>
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <Breadcums heading="Acquitance Summary Report" paglink="Acquitance_Summary" />
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              {/* left column */}
              <div className="col-md-12">{/* <Transport_bar /> */}</div>
            </div>
            <form >
              <div className="row">
                <div className="col-md-12">
                  <div className="card card-row card-info">
                    <div className="card-header"></div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Select Month
                            </label>
                            <select
                              className="form-control form-control-sm select2"
                              id="selectmonth"
                              value={selectedMonthYear}
                              onChange={(e) => handleChange2(e)}
                            >
                              <option value="" disabled>
                                Select Month and Year
                              </option>
                              {monthYearOptions.map((option, index) => (
                                <option key={index} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                  
                          </div>
                        </div>
                       
                        <div className="col-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Main Account Head
                            </label>
                            <select
                              className="form-control form-control-sm select2"
                              id="acchead"
                              onChange={(e) => getbymainaccount(e)}
                              value={formdata.acchead}
                            >
                              <option selected="NA">--Select--</option>

                              {Acchead &&
                                Acchead.map((seldata, index) => {
                                  return (
                                    <option value={seldata.head_name}>
                                      {seldata.head_name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Payment Mode
                            </label>
                            <select
                              className="form-control form-control-sm select2"
                              id="imode"
                              onChange={(e) => getbymode(e)}
                              value={formdata.imode}
                            >
                              <option selected="NA">--Select--</option>
                              {transaction &&
                                transaction.map((seldata, index) => {
                                  return (
                                    <option value={seldata.mode}>
                                      {seldata.mode}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
             
            </form>
            <div className="row">
              <div className="col-md-12">
                {/* general form elements */}
                <div className="card card-dark">
                  <FetchDataComponent
                    tblname="labour_salary"
                    setData={setData}
                    setColumns={setColumns}
                    id="id"
                  />

                  {/* This component fetches the data and determines columns */}
                  <ShowTableReportData
                    data={data}
                    columns={columns}
                    handleDelete={handleDelete}
                    itemsPerPage={itemsperpage}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}
    </>
  );
}
