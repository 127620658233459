import { Link, useNavigate } from "react-router-dom";
import React, { useState, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

export default function Login() {
  // localStorage.getItem(token)
  const [toaster, setToaster] = useState({ isOpen: false, message: "" });

  const showToast = (message) => {
    setToaster({ isOpen: true, message });
    setTimeout(() => {
      setToaster({ isOpen: false, message: "" });
    }, 3000); // Close the toaster after 3 seconds
  };
  const [uname, unamechange] = useState("");
  const [pass, passchange] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    const empdata = { uname, pass };
    //console.log(uname);
    //console.log(pass);
    axios
      .post("/login", empdata)
      .then((res) => {
        if (res.status === 200) {
          const data = res.data;
          // console.log(data);
          const { expiresIn } = data;
          if (res.data.token) {
            // alert("Status 200");
            // setIsLoggedIn(true);
            const modifytoken = `Bearer ` + res.data.token;
            const usertype = res.data.data.type;
            localStorage.setItem("token", JSON.stringify(modifytoken));
            localStorage.setItem("uname", res.data.data.uname);
            localStorage.setItem( "usertype",res.data.data.type);
            // Store token and expiration time in localStorage
            // Calculate token expiration time
            const expirationTime = new Date().getTime() + 1000 * 1000;
            localStorage.setItem("expirationTime", expirationTime);
            toast.success("Logged in Successfully");
            if (usertype === "admin") {
              
              window.location.href = "/dashboard/admin";
              
              // navigate("/master/groups");
            } else if (usertype === "account") {
              window.location.href = "/dashboard/admin";
              //navigate("/account/dashboard");
            }
            else if (usertype === "cashier") {
              window.location.href = "/sale/makeinvoice";
             // navigate("/sale/makeinvoice");
            }
            else if (usertype === "manager") {
              window.location.href = "/dashboard/manager";
             // navigate("/sale/makeinvoice");
            }
          } else {
            //console.log("Wrong Username or Password");
            toast.error("Wrong username/Password");
          }
        } else if (res.status === 401) {
          toast.error("Wrong username/Password");
         // alert("Wrong Username or Password");
        }
      })
      .catch((err) => {
        //navigate("/");
        //alert("Wrong Username or Passwordcccc");
        toast.error("Wrong username/Password");
        console.log(err.message);
      });
  };
  return (
    <div className="hold-transition login-page">
      <div className="login-box">
        {/* /.login-logo */}
        <div className="card card-outline card-primary">
          <div className="card-header text-center">
            <a href="../../index2.html" className="h1">
              <b>JD</b>Aromatics
            </a>
          </div>
          <div className="card-body">
            <p className="login-box-msg">Sign in to start your session</p>
            <form onSubmit={handleSubmit}>
              <div className="input-group mb-3">
                <input
                  type="text"
                  id="uname"
                  value={uname}
                  onChange={(e) => unamechange(e.target.value)}
                  className="form-control"
                  placeholder="Enter your User ID"
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-envelope" />
                  </div>
                </div>
              </div>
              <div className="input-group mb-3">
                <input
                  type="password"
                  className="form-control"
                  id="pass"
                  value={pass}
                  onChange={(e) => passchange(e.target.value)}
                  placeholder="Password"
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-8">
                  <div className="icheck-primary">
                    <input type="checkbox" id="remember" />
                    <label htmlFor="remember">Remember Me</label>
                  </div>
                </div>
                {/* /.col */}
                <div className="col-4">
                  <button type="submit" className="btn btn-primary btn-block">
                    Sign In
                  </button>
                  <ToastContainer />
                </div>
                {/* /.col */}
              </div>
            </form>
            {/* <div className="social-auth-links text-center mt-2 mb-3">
              <a href="#" className="btn btn-block btn-primary">
                <i className="fab fa-facebook mr-2" /> Sign in using Facebook
              </a>
              <a href="#" className="btn btn-block btn-danger">
                <i className="fab fa-google-plus mr-2" /> Sign in using Google+
              </a>
            </div> */}
            {/* /.social-auth-links */}
            {/* <p className="mb-1">
              <a href="forgot-password.html">I forgot my password</a>
            </p>
            <p className="mb-0">
              <a href="register.html" className="text-center">
                Register a new membership
              </a>
            </p> */}
          </div>
          {/* /.card-body */}
        </div>
        {/* /.card */}
      </div>
      {/* /.login-box */}
    </div>
  );
}
