import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import authheader from "../../services/authheader";
import { fetchData } from '../../util/fetchData';
import { fetchData_singlePara } from "../../util/fetchDataby_singlePara";
import { fetch_data_with_limit } from '../../util/fetch_Data_with_limit';
import { checkToken } from "../../services/checkToken";
import Breadcums from "../../common/Breadcums";
import Watermark from '../../components/Watermark';

export default function PrintInvoice() {
  try {
    checkToken();
  } catch (error) {
    alert("Session logged out.Please logged in again");
    localStorage.clear();
    // history.push('/');
    window.location.href = "/";
  }
  //const  invoiceNumber  = 12;
  const  {invoiceNumber}  = useParams();
  //alert(invoiceNumber);

  const [InvoiceSummary, setInvoiceSummary] = useState([]);
  const [CustomerData, setCustomerData] = useState([]);
  const [CompanyDetail, setCompanyDetail] = useState([]);
  const [invoiceData, setInvoiceData] = useState(null);

  useEffect(() => {
    fetchData_singlePara(
      "invoiceitems",
      setInvoiceData,
      "inv",
      invoiceNumber,
      "id"
    );
    
    fetch_data_with_limit(
      "customer_bill_details",
      setCustomerData,
      "invoice",
      invoiceNumber,
      "id",
      "limit 1"
    );
    fetch_data_with_limit(
      "invoices",
      setInvoiceSummary,
      "inv",
      invoiceNumber,
      "id",
      "limit 1"
    );
    
    fetchData("bill_format", setCompanyDetail, "id");
    //console.log(invoiceNumber);
  }, []);
  return (
    <>
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <Breadcums heading="Print Invoice" paglink="Invoice Details" />

        <section className="invoice">
          {/* title row */}
          <div class="invoice p-3 mb-3">
          <div className="row ml-3">
            <div className="col-12 "></div>
          </div>
          <div className="row mt-5">
            <div className="col-12">
              <h2 className="page-header">
                <i className="fas fa-globe" /> {CompanyDetail.cname}
                <small className="float-right"><b>Date: {InvoiceSummary.invoice_date}</b></small>
              </h2>
            </div>
            {/* /.col */}
          </div>
          {/* info row */}
          <div className="row invoice-info">
            <div className="col-sm-4 invoice-col">
              From
              <address>
                <strong>{CompanyDetail.cname}</strong>
                <br />
                {CompanyDetail.address}
                <br />
               
                State: {CompanyDetail.state}
                <br />
                Pincode: {CompanyDetail.pincode}
                <br />
                Contact: {CompanyDetail.contact}
                <br />
                 {CompanyDetail.gst}
                <br />
                Email: {CompanyDetail.email}
              </address>
            </div>
            {/* /.col */}
            <div className="col-sm-4 invoice-col">
              To
              <address>
                <strong>{CustomerData.name}</strong>
                <br />
                Address: {CustomerData.address}
                <br />
                State: {CustomerData.place}
                <br />
                GST: {CustomerData.gst}
                <br />
                Phone: {CustomerData.contact}
                
              </address>
            </div>
            {/* /.col */}
            <div className="col-sm-4 invoice-col">
              <b>Invoice: {invoiceNumber}</b>
              <br />
              
              <b>Payment mode:</b> {CustomerData.payment_mode}
              <br />
              <b>Payment Status: </b>{InvoiceSummary.status}<br /><br />
              
            </div>
            
            {/* /.col */}
          </div>
          {/* /.row */}
          {/* Table row */}

          <div className="row">
            <div className="col-12 table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>Qty</th>
                    
                    <th>Rate </th>
                    <th>CGST</th>
                    <th>SGST</th>
                    <th>IGST</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {invoiceData &&
                    invoiceData.map((invdata, index) => {
                      return (
                        <tr key={index}>
                          <td>{invdata.product_name} </td>
                          <td>{invdata.quantity} {invdata.uom} </td>
                          
                          <td>{invdata.unit_price} </td>
                          <td>
                            {invdata.cgst}
                          </td>
                          <td>
                            {invdata.sgst}{" "}
                          </td>
                          <td>{invdata.igst} </td>
                          <td>{invdata.total_price} </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
          <div className="row">
            {/* accepted payments column */}
            <div className="col-6">
              <p className="lead">Payment Methods:</p>
              <img src="../../dist/img/credit/visa.png" alt="Visa" />
              <img
                src="../../dist/img/credit/mastercard.png"
                alt="Mastercard"
              />
              <img
                src="../../dist/img/credit/american-express.png"
                alt="American Express"
              />
              <img src="../../dist/img/credit/paypal2.png" alt="Paypal" />
              <p
                className="text-muted well well-sm shadow-none"
                style={{ marginTop: 10 }}
              >
                You can pay via Credit/Debit card or can transfer direct to our account.Our Account details Mentioned below:
                <br />
                <b>{CompanyDetail.bank} </b>
                <br />
                <b>{CompanyDetail.bank1} </b>
                <br /><br />
                <b>Terms & Condition: </b><br />
                {CompanyDetail.t1}<br />
                {CompanyDetail.t2}<br />
                {CompanyDetail.t3}<br />
              </p>
            </div>
            {/* /.col */}
            <div className="col-6">
              <p></p>
              {/* <p className="lead">Amount Due 2/22/2014</p> */}
              <div className="table-responsive">
                <table className="table">
                  <tbody>
                    <tr>
                      <th style={{ width: "50%" }}>Subtotal:</th>
                      <td>Rs.{InvoiceSummary.subtotal}</td>
                    </tr>
                    <tr>
                      <th>Tax Value:</th>
                      <td>Rs. {InvoiceSummary.taxvalue}</td>
                    </tr>
                    <tr>
                      <th>Shipping:</th>
                      <td>Rs.{InvoiceSummary.shipping}</td>
                    </tr>
                    <tr>
                      <th>Total:</th>
                      <td>Rs.{InvoiceSummary.total_amount+InvoiceSummary.shipping}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {/* /.col */}
          </div>
          </div>
          {/* /.row */}
        </section>
        {/* /.content */}
      </div>
    </>
  );
}
