import React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import authheader from "../../services/authheader";
import { deleteRecord } from "../../util/deleteRecord";
import { fetchData } from "../../util/fetchData";
import { fetchData_singlePara } from "../../util/fetchDataby_singlePara";
import { checkToken } from "../../services/checkToken";
import FetchDataComponent from "../../components/tables/fetchTableData";
import Pagination from "../../components/tables/ShowTableData";
import Breadcums from "../../common/Breadcums";

export default function NewStaff() {
  try {
    checkToken();
  } catch (error) {
    alert("Session logged out.Please logged in again");
    localStorage.clear();
    // history.push('/');
    window.location.href = "/";
  }
  const headers = { Authorization: authheader().access_token };
  const [data, setData] = useState([]);
  const [errors, setErrors] = useState({});
  const [Selectdatagroup, setSelectDatagroup] = useState([]);
  const [JobTitle, setJobTitle] = useState([]);
  const [formdata, setFormData] = useState({
    company_name: "",
    customer_name: "",
    contact: "",
    street1: "",
    street2: "",
    city: "",
    pincode: "",
    gst: "",
    ob: "",
  });
  const handleChange = (e) => {
    setFormData({ ...formdata, [e.target.id]: e.target.value });
  };
  //handle add record
  const handleAddRecord = (newRecord) => {
    setData([...data, newRecord]);
    fetchData("employee_profiles", setData, "id");
  };
  const handleChange1 = (e) => {
    // console.log(e.target.value);
    setFormData({ ...formdata, [e.target.id]: e.target.value });

   
    // console.log(setSelectDatagroup);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const formdata1 = e.target;
    axios
      .post(
        "/insertdata/employee_profiles",
        {
          company_name: formdata1.company_name.value,
          customer_name: formdata1.cname.value,
          contact: formdata1.contact.value,
          street1: formdata1.street1.value,
          street2: formdata1.street2.value,
          city: formdata1.city.value,
          pincode: formdata1.pin.value,
          gst: formdata1.gst.value,
          ob: formdata1.ob.value,
        },
        { headers }
      )
      .then((res) => {
        fetchData("employee_profiles", setData, "id");

        const newRecord = res;
        handleAddRecord(newRecord);
        setFormData({
          company_name: "",
          customer_name: "",
          contact: "",
          street1: "",
          street2: "",
          city: "",
          pincode: "",
          gst: "",
          ob: "",
        });
        //navigate("/");
      })
      .catch((err) => {
        console.log(err.message);
      });
    // handleAddRecord(formdata);
    setFormData({});
    setErrors({});
  };

  //Table data record

  const [itemsperpage, setitemsperpage] = useState([10]);
  const [columns, setColumns] = useState([]);

  const handleDelete = async (id) => {
    deleteRecord("employee_profiles", id, "id");
    fetchData("employee_profiles", setData, "id");
  };
  const location = useLocation();
  useEffect(() => {
   
    fetchData("contract_type", setSelectDatagroup, "id");
    fetchData("job_roles", setJobTitle, "id");
    
  }, []);
  return (
    <>
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <Breadcums heading="Payroll System" paglink="New Staff" />
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              {/* left column */}
              <div className="col-md-12">
                {/* general form elements */}

                <div className="card card-dark card-tabs">
                  <div className="card-header p-0 pt-1">
                    <ul
                      className="nav nav-tabs"
                      id="custom-tabs-one-tab"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          id="custom-tabs-one-home-tab"
                          data-toggle="pill"
                          href="#custom-tabs-one-home"
                          role="tab"
                          aria-controls="custom-tabs-one-home"
                          aria-selected="true"
                        >
                          Personal Details
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="custom-tabs-one-profile-tab"
                          data-toggle="pill"
                          href="#custom-tabs-one-profile"
                          role="tab"
                          aria-controls="custom-tabs-one-profile"
                          aria-selected="false"
                        >
                          Contact Details
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="custom-tabs-one-messages-tab"
                          data-toggle="pill"
                          href="#custom-tabs-one-messages"
                          role="tab"
                          aria-controls="custom-tabs-one-messages"
                          aria-selected="false"
                        >
                          Job Description 
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="custom-tabs-one-settings-tab"
                          data-toggle="pill"
                          href="#custom-tabs-one-settings"
                          role="tab"
                          aria-controls="custom-tabs-one-settings"
                          aria-selected="false"
                        >
                          Billing Information
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="card-body">
                    <div
                      className="tab-content"
                      id="custom-tabs-one-tabContent"
                    >
                      <div
                        className="tab-pane fade show active"
                        id="custom-tabs-one-home"
                        role="tabpanel"
                        aria-labelledby="custom-tabs-one-home-tab"
                      >
                        <div className="row">
                          <div className="col-2">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">First Name</label>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                id="fname"
                                onChange={(e) => handleChange(e)}
                                value={formdata.fname}
                              />
                            </div>
                          </div>
                          <div className="col-2">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">Last Name</label>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                id="lname"
                                onChange={(e) => handleChange(e)}
                                value={formdata.lname}
                              />
                            </div>
                          </div>
                          <div className="col-2">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                D.O.B
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                id="dob"
                                onChange={(e) => handleChange(e)}
                                value={formdata.dob}
                              />
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                Gender
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                id="gender"
                                onChange={(e) => handleChange(e)}
                                value={formdata.gender}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="custom-tabs-one-profile"
                        role="tabpanel"
                        aria-labelledby="custom-tabs-one-profile-tab"
                      >
                        <div className="row">
                          <div className="col-2">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                Email
                              </label>
                              <input
                                type="email"
                                className="form-control form-control-sm"
                                id="email"
                                onChange={(e) => handleChange(e)}
                                value={formdata.email}
                              />
                            </div>
                          </div>
                          <div className="col-2">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                Contact
                              </label>

                              <input
                                type="number"
                                className="form-control form-control-sm datetimepicker-input"
                                id="contact"
                                onChange={(e) => handleChange(e)}
                                value={formdata.contact}
                              />
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                               Address
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-sm "
                                id="address"
                                onChange={(e) => handleChange(e)}
                                value={formdata.address}
                              />

                            </div>
                          </div>
                          <div className="col-1">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                               City
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-sm "
                                id="city"
                                onChange={(e) => handleChange(e)}
                                value={formdata.city}
                              />

                            </div>
                          </div>
                          <div className="col-1">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                               State
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-sm "
                                id="state"
                                onChange={(e) => handleChange(e)}
                                value={formdata.state}
                              />

                            </div>
                          </div>
                          <div className="col-1">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                               Pin Code
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-sm "
                                id="pincode"
                                onChange={(e) => handleChange(e)}
                                value={formdata.state}
                              />

                            </div>
                          </div>
                          <div className="col-1">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                               Country
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-sm "
                                id="country"
                                onChange={(e) => handleChange(e)}
                                value={formdata.country}
                              />

                            </div>
                          </div>
                          


                        </div>
                    
                      </div>
                      <div
                        className="tab-pane fade"
                        id="custom-tabs-one-messages"
                        role="tabpanel"
                        aria-labelledby="custom-tabs-one-messages-tab"
                      >
                     <div className="row">
                          <div className="col-2">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">Job Title</label>
                              <select 
                        className="form-control form-control-sm select2"
                        id="jobtile"
                        onChange={(e) => handleChange1(e)}
                        value={formdata.jobtile}
                        >
                          
                        <option selected="NA">--Select--</option>
                        {JobTitle &&
                          JobTitle.map((seldata, index) => {
                            return (
                              <option value={seldata.job_title}>{seldata.job_title}/{seldata.department}</option>
                            );
                          })}
                  </select>

                            </div>
                          </div>
                          <div className="col-2">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                Department
                              </label>
                              <input
                                type="text"
                                disabled
                                className="form-control form-control-sm"
                                id="department"
                                onChange={(e) => handleChange(e)}
                                value={formdata.department}
                              />
                            </div>
                          </div>
                          <div className="col-2">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                Hire Date
                              </label>
                              <input
                                type="date"
                                className="form-control form-control-sm"
                                id="hire"
                                onChange={(e) => handleChange(e)}
                                value={formdata.hire}
                              />
                            </div>
                          </div>
                          <div className="col-2">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                Total Contract value
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                id="penalty"
                                onChange={(e) => handleChange(e)}
                                value={formdata.penalty}
                              />
                            </div>
                          </div>
                          <div className="col-2">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                               Payment Terms
                              </label>
                              <select 
                        className="form-control select2"
                        id="subgroup"
                        onChange={(e) => handleChange(e)}
                        value={formdata.subgroup}
                        >
                          
                        <option selected value="NA">--Select--</option>
                        <option value="Monthly">Monthly</option>
                        <option value="Quarterly">Quarterly</option>
                        <option value="Half_Yearly">Half Yearly</option>
                        <option value="Yearly"> Yearly</option>
                     
                  </select>

                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="custom-tabs-one-settings"
                        role="tabpanel"
                        aria-labelledby="custom-tabs-one-settings-tab"
                      >
                        <div className="row">
                          <div className="col-3">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1"> Name</label>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                id="bname"
                                onChange={(e) => handleChange(e)}
                                value={formdata.bname}
                              />
                            </div>
                          </div>
                          <div className="col-2">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                 Contact
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                id="bcontact"
                                onChange={(e) => handleChange(e)}
                                value={formdata.bcontact}
                              />
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                 Address
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                id="baddress"
                                onChange={(e) => handleChange(e)}
                                value={formdata.baddress}
                              />
                            </div>
                          </div>
                          <div className="col-2">
                            <div className="form-group">
                            <button type="submit" class="btn mt-4 btn-dark float-right">
                        Save
                      </button>
                            </div>
                          </div>
                         
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /.card */}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                {/* general form elements */}
                <div className="card card-dark">
                  <FetchDataComponent
                    tblname="employee_profiles"
                    setData={setData}
                    setColumns={setColumns}
                    id="id"
                  />

                  {/* This component fetches the data and determines columns */}
                  <Pagination
                    data={data}
                    columns={columns}
                    handleDelete={handleDelete}
                    itemsPerPage={itemsperpage}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}
    </>
  );
}
