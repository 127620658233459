import React from "react";
import { useEffect, useState } from "react";

import axios from "axios";
import authheader from "../../services/authheader";
import { deleteRecord } from "../../util/deleteRecord";
import { fetchData } from "../../util/fetchData";
import { fetchcombolist } from "../../util/fetch_combolist";
import { ledgerSummary } from "../../util/ledger_summary";
import { checkToken } from "../../services/checkToken";
import FetchDataComponent from "../../components/tables/fetchTableData";
import ShowTableReportData from "../../components/tables/ShowTableReports";
import { fetch_data_with_limit } from "../../util/fetch_Data_with_limit";
import { twoparawithlimit } from "../../util/twoparawithlimit";
import Breadcums from "../../common/Breadcums";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { format } from "date-fns";
import getCurrentDate from "../../util/getCurrentDate";

export default function LabourAttendance() {
  try {
    checkToken();
  } catch (error) {
    alert("Session logged out.Please logged in again");
    localStorage.clear();
    // history.push('/');
    window.location.href = "/";
  }
  const headers = { Authorization: authheader().access_token };
  const [data, setData] = useState([]);

  const [LabourData, setLabourData] = useState([]);
  const [transaction, settransaction] = useState([]);

  const [VendorName, setVendorname] = useState([]);
  const [VenderData, setVenderData] = useState([]);
  const [PreviousData, setPreviousData] = useState([]);
  const [Acchead, setAcchead] = useState([]);
  const [totalvalue, setTotalvalue] = useState("0");
  const [totalDeductionvalue, setTotalDeductionvalue] = useState("0");
  const [messtotalDeductionvalue, setTotalmessDeductionvalue] = useState("0");
  const [GrandTotalValue, setGrandTotalValue] = useState("0");

  
  const [netpay, setNetPay] = useState("0");
  const [balanceAmount, SetbalanceAmount] = useState("0");
  const [selectedDate, setSelectedDate] = useState(getCurrentDate);
  const [SummaryData, setSummaryData] = useState([]);
  const [Selectdatasubgroup, setSelectDatasubgroup] = useState([]);
  const [formdata, setFormData] = useState({
    hrs: "0",
    wage: "",
    amountofthemonth: "",
    ob: "",
    credit: "0",
    advancepay: "0",
    mess: "0",
    totaldeduction: "0",
    totalamount: "0",
    amountpaid: "0",
    balance: "0",
  });
  const [selectedMonthYear, setSelectedMonthYear] = useState("");
  const [previousMonthYear, setPreviousMonthYear] = useState("");
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const years = Array.from(new Array(3), (val, index) => index + 2024);

  const monthYearOptions = [];
  years.forEach((year) => {
    months.forEach((month) => {
      monthYearOptions.push(`${month} ${year}`);
    });
  });

  const handleMonthYearChange = (e) => {
    const selected = e.target.value;
    setSelectedMonthYear(e.target.value);
    calculatePreviousMonthYear(selected);
  };
  const calculatePreviousMonthYear = (currentMonthYear) => {
    const [currentMonth, currentYear] = currentMonthYear.split(" ");
    const currentMonthIndex = months.indexOf(currentMonth);
    let previousMonthIndex = currentMonthIndex - 1;
    let previousYear = parseInt(currentYear, 10);

    if (previousMonthIndex < 0) {
      previousMonthIndex = 11; // Wrap around to December
      previousYear -= 1; // Decrement the year
    }

    const previousMonth = months[previousMonthIndex];
    setPreviousMonthYear(`${previousMonth} ${previousYear}`);
  };

  const handleChange = (e) => {
    setFormData({ ...formdata, [e.target.id]: e.target.value });
  };
  const today = new Date();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const date = today.getDate();
  const currentDate = year + "/" + month + "/" + date;

  const getVendorDetails = (e) => {
    fetch_data_with_limit(
      "labour_profiles",
      setVenderData,
      "first_name",
      e.target.value,
      "id",
      "limit 1"
    );
    ledgerSummary(
      "income",
      setSummaryData,
      "subhead",
      e.target.value,
      "credit",
      "debit",
      "tax",
      "disc",
      "id"
    );
    // alert(previousMonthYear);
    twoparawithlimit(
      "labour_salary",
      setPreviousData,
      "name",
      e.target.value,
      "salary_month",
      previousMonthYear,
      "id",
      "limit 1"
    );
  };
  const cal = (e) => {
    let hr = parseFloat(formdata.hrs);
    let ob1 = parseFloat(PreviousData.balance);
    let ocredit = parseFloat(formdata.credit);
    let advancepay = parseFloat(SummaryData.debit-SummaryData.credit);
    let messded = parseFloat(formdata.mess);
    let amtpaid = parseFloat(formdata.amountpaid);
   
    let par = parseFloat(VenderData.salary / 8);
    let total = hr * par;
    let grandtotals = ob1 + ocredit+total;
    let totalded =  advancepay + messded;
    let net_pay = grandtotals - totalded;
    let balanceamt = net_pay - amtpaid;

    setTotalmessDeductionvalue(advancepay);
    setTotalDeductionvalue(totalded);
    setTotalvalue(total);
    setNetPay(net_pay);
    SetbalanceAmount(balanceamt);
  

    setGrandTotalValue(grandtotals);

  };
 
 

  const handleSubmit1 = async (e) => {
    e.preventDefault();
    const formdata1 = e.target;
    const post1 = axios.post(
      "/insertdata/labour_salary",
      {
        salary_month: formdata1.selectmonth.value,
        salary_date: currentDate,
        main_account: formdata1.acchead.value,
        name: formdata1.vendor_name.value,
        hrs: formdata1.hrs.value,
        wage: formdata1.wage.value,
        month_amount: formdata1.amountofthemonth.value,
        ob: formdata1.ob.value,
        others: formdata1.credit.value,
        advance_mess: formdata1.mess.value,
        total_deduction: formdata1.totaldeduction.value,
        total_amount: formdata1.totalamount.value,
        
        amount_paid: formdata1.amountpaid.value,
        mode: formdata1.imode.value,
        balance: formdata1.balance.value,
        remark: formdata1.remark.value,
      },
      { headers }
    );
    const post2 = axios.post(
      "/insertdata/expenses",
      {
        purchase_id: "0",
        date: currentDate,
        invdte: currentDate,
        userid: localStorage.getItem("uname"),
        vendor: formdata1.vendor_name.value,
        group_name: "Liabilities",
        subgroup_name: "Current Liabilities",
        head: "Salary",
        subhead: formdata1.vendor_name.value,
        ref: "NA",
        payment_mode: formdata1.imode.value,
        bank: "0",
        credit: formdata1.amountpaid.value,
        tax: "0",
        disc: "0",
        debit: formdata1.amountpaid.value,
        balance: "0",
        remark:
          formdata1.selectmonth.value +
          " salary created ," +
          formdata1.remark.value,
      },
      { headers }
    );
     //Income Entry
     const post3 = axios.post(
      "/insertdata/income",
      {
        sale_id: "0",
        date: currentDate,
        invdte: currentDate,
        userid: localStorage.getItem("uname"),
        vendor: formdata1.vendor_name.value,
        group_name: "Assets",
        subgroup_name: "Current Assets",
        head: "Advance",
        subhead: formdata1.vendor_name.value,
        ref: "NA",
        payment_mode: formdata1.imode.value,
        bank: "0",
        credit: "0",
        tax: "0",
        disc: "0",
        debit: messtotalDeductionvalue,
        balance: "0",
        remark: "Salary Deducted " + formdata1.remark.value,
      },
      { headers }
    );
    //Account head entry
    const post4 = axios.post(
      "/insertdata/accounthead",
      {
        date: currentDate,
        head_name: formdata1.acchead.value,
        credit:  totalDeductionvalue,
        debit:"0",
        trans_type: formdata1.imode.value,
        remark: "Deducted salary credited to" + formdata1.acchead.value,
      },
      { headers }
    );
   
    try {
      await Promise.all([post1, post2, post3, post4]);
      // Show success notification
      fetchData("labour_salary", setData, "id");
      setFormData({
        hrs: "0",
        wage: "",
        amountofthemonth: "",
        ob: "",
        credit: "0",
        advancepay: "0",
        mess: "0",
        totaldeduction: "0",
        totalamount: "0",
        amountpaid: "0",
        balance: "0",
      });
      toast.success("Salary created successfully!");
      //toast.error('An error occurred while making the POST requests');
      //alert("success");
    } catch (err) {
      // Handle errors
      //setError('An error occurred while making the POST requests');
      toast.error("An error occurred while making the POST requests"+err);
      console.error("Error:", err);
    } finally {
      //  setLoading(false);
    }
  };

  //Table data record
  // const [data, setData] = useState([]);
  const [itemsperpage, setitemsperpage] = useState([10]);
  const [columns, setColumns] = useState([]);

  const handleDelete = async (id) => {
    deleteRecord("expenses", id, "id");
    fetchData("expenses", setData, "id");
  };
  useEffect(() => {
    fetchData("labour_salary", setData, "id");
    fetchData("transactions", settransaction, "id");
    fetchData("labour_salary", setLabourData, "id");
    fetchData("labour_profiles", setVendorname, "id");
    fetchcombolist("accounthead", setAcchead, "head_name");
  }, []);
  return (
    <>
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <Breadcums heading="Labour Salary" paglink="attendance" />
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              {/* left column */}
              <div className="col-md-12">{/* <Transport_bar /> */}</div>
            </div>
            <form onSubmit={handleSubmit1}>
              <div className="row">
                <div className="col-md-12">
                  <div className="card card-row card-info">
                    <div className="card-header"></div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Select Month
                            </label>
                            <select
                              className="form-control form-control-sm select2"
                              id="selectmonth"
                              value={selectedMonthYear}
                              onChange={handleMonthYearChange}
                            >
                              <option value="" disabled>
                                Select Month and Year
                              </option>
                              {monthYearOptions.map((option, index) => (
                                <option key={index} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                            {/* {selectedMonthYear && (
        <div>
          <p>Selected Month and Year: {selectedMonthYear}</p>
          <p>Previous Month and Year: {previousMonthYear}</p>
        </div>
      )} */}
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Date</label>
                            <input
                              type="text"
                              required
                              disabled
                              className="form-control form-control-sm"
                              id="saldate"
                              onChange={(e) => handleChange(e)}
                              value={currentDate}
                            />
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Main Account Head
                            </label>
                            <select
                              className="form-control form-control-sm select2"
                              id="acchead"
                              onChange={(e) => handleChange(e)}
                              value={formdata.acchead}
                            >
                              <option selected="NA">--Select--</option>

                              {Acchead &&
                                Acchead.map((seldata, index) => {
                                  return (
                                    <option value={seldata.head_name}>
                                      {seldata.head_name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Payment Mode
                            </label>
                            <select
                              className="form-control form-control-sm select2"
                              id="imode"
                              onChange={(e) => handleChange(e)}
                              value={formdata.imode}
                            >
                              <option selected="NA">--Select--</option>
                              {transaction &&
                                transaction.map((seldata, index) => {
                                  return (
                                    <option value={seldata.mode}>
                                      {seldata.mode}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              This month Pending
                            </label>
                            <input
                              type="text"
                              required
                              disabled
                              className="form-control form-control-sm"
                              id="currentpending"
                              onChange={(e) => handleChange(e)}
                              onKeyUp={(e) => cal(e)}
                              value={formdata.hrs}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-3">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Select Employee
                            </label>
                            <select
                              className="form-control form-control-sm select2"
                              id="vendor_name"
                              onChange={(e) => getVendorDetails(e)}
                              value={formdata.vendor_name}
                            >
                              <option selected="NA">--Select--</option>
                              {VendorName &&
                                VendorName.map((seldata, index) => {
                                  return (
                                    <option value={seldata.first_name}>
                                      {seldata.first_name} {seldata.last_name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>

                        <div className="col-3">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              No. of Hrs. Present
                            </label>
                            <input
                              type="text"
                              required
                              className="form-control form-control-sm"
                              id="hrs"
                              onChange={(e) => handleChange(e)}
                              onKeyUp={(e) => cal(e)}
                              value={formdata.hrs}
                            />
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Wage Price
                            </label>
                            <input
                              type="text"
                              required
                              disabled
                              className="form-control form-control-sm"
                              id="wage"
                              onChange={(e) => handleChange(e)}
                              value={VenderData.salary / 8}
                            />
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Amount of the Month
                            </label>
                            <input
                              type="text"
                              required
                              disabled
                              className="form-control form-control-sm"
                              id="amountofthemonth"
                              onChange={(e) => handleChange(e)}
                              value={totalvalue}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row"> 
                        <div className="col-3">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Opening Balance
                            </label>
                            <input
                              type="text"
                              disabled
                              className="form-control form-control-sm"
                              id="ob"
                              onChange={(e) => handleChange(e)}
                              onKeyUp={(e) => cal(e)}
                              value={PreviousData.balance}
                            />
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Other Credits
                            </label>
                            <input
                              type="text"
                              required
                              className="form-control form-control-sm"
                              id="credit"
                              onChange={(e) => handleChange(e)}
                              onKeyUp={(e) => cal(e)}
                              value={formdata.credit}
                            />
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Grand Total
                            </label>
                            <input
                              type="text"
                              required
                              disabled
                              className="form-control form-control-sm"
                              id="grandtotal"
                              onChange={(e) => handleChange(e)}
                              onKeyUp={(e) => cal(e)}
                              value={GrandTotalValue}
                            />
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Advance Payment Made
                            </label>
                            <input
                              type="text"
                              required
                              disabled
                              className="form-control form-control-sm"
                              id="advancepay"
                              onChange={(e) => handleChange(e)}
                              onKeyUp={(e) => cal(e)}
                              value={SummaryData.debit-SummaryData.credit}
                            />
                          </div>
                        </div>
                        </div>

<div className="row">
                        <div className="col-3">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Mess Deduction
                            </label>
                            <input
                              type="text"
                              required
                              className="form-control form-control-sm"
                              id="mess"
                              onChange={(e) => handleChange(e)}
                              onKeyUp={(e) => cal(e)}
                              value={formdata.mess}
                            />
                          </div>
                        </div>
                     
                        <div className="col-3">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Total Deduction{" "}
                            </label>
                            <input
                              type="text"
                              required
                              disabled
                              className="form-control form-control-sm"
                              id="totaldeduction"
                              onChange={(e) => handleChange(e)}
                              onKeyUp={(e) => cal(e)}
                              value={totalDeductionvalue}
                            />
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Total Amount{" "}
                            </label>
                            <input
                              type="text"
                              required
                              readOnly
                              className="form-control form-control-sm"
                              id="totalamount"
                              onChange={(e) => handleChange(e)}
                              onKeyUp={(e) => cal(e)}
                              value={netpay}
                            />
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Amount Paid
                            </label>
                            <input
                              type="text"
                              required
                              className="form-control form-control-sm"
                              id="amountpaid"
                              onChange={(e) => handleChange(e)}
                              onKeyUp={(e) => cal(e)}
                              value={formdata.amountpaid}
                            />
                          </div>
                        </div>
                        </div>

                    <div className="row">
                        <div className="col-3">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Balance Amount
                            </label>
                            <input
                              type="text"
                              required
                              className="form-control form-control-sm"
                              id="balance"
                              onChange={(e) => handleChange(e)}
                              onKeyUp={(e) => cal(e)}
                              value={balanceAmount}
                            />
                          </div>
                        </div>
                      
                        <div className="col-8">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Remark</label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              id="remark"
                              onChange={(e) => handleChange(e)}
                              value={formdata.remark}
                            />
                          </div>
                        </div>

                        <div className="col-1">
                          <button
                            type="submit"
                            class="btn btn-dark mt-4 float-right"
                          >
                            Save
                          </button>
                          <ToastContainer />
                        </div>
                        {/* <table className="table table-border table-hover text-nowrap" id="tbldata" >
                        <thead>
                          <tr> </tr>
                        </thead>
                        <tbody>
                          {LabourData &&
                            LabourData.map((seldata, index) => {
                              return (
                                <div className="col-sm-4">
                                  <tr>
                                    <td>
                                      <input
                                        type="text"
                                        disabled
                                        className="form-control form-control-sm"
                                        id={`name_${index}`}
                                        onChange={(event) => handleInputChange1(index, event)}
                                        value={seldata.first_name}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        id={`whours_${index}`}
                                        onChange={(event) => handleInputChange2(index, event)}
                                        value={formdata.whours}
                                      />{" "}
                                    </td>
                                  </tr>
                                </div>
                              );
                            })}
                        </tbody>
                      </table> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className="row">
              <div className="col-md-12">
                {/* general form elements */}
                <div className="card card-dark">
                  <FetchDataComponent
                    tblname="labour_salary"
                    setData={setData}
                    setColumns={setColumns}
                    id="id"
                  />

                  {/* This component fetches the data and determines columns */}
                  <ShowTableReportData
                    data={data}
                    columns={columns}
                    handleDelete={handleDelete}
                    itemsPerPage={itemsperpage}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}
    </>
  );
}
