import React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import authheader from "../../services/authheader";
import { fetchData_singlePara } from "../../util/fetchDataby_singlePara";
import { fetch_data_with_limit } from "../../util/fetch_Data_with_limit";
import { deleteRecord } from "../../util/deleteRecord";
import { fetchData } from "../../util/fetchData";
import { fetchcombolist } from "../../util/fetch_combolist";
import { checkToken } from "../../services/checkToken";
import FetchDataComponent from "../../components/tables/fetchTableData";
import Pagination from "../../components/tables/ShowTableData";
import Breadcums from "../../common/Breadcums";
import { getOrder } from "../../util/getOrder";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Payment() {
  try {
    checkToken();
  } catch (error) {
    alert("Session logged out.Please logged in again");
    localStorage.clear();
    // history.push('/');
    window.location.href = "/";
  }
  const headers = { Authorization: authheader().access_token };
  const [data, setData] = useState([]);
  const [Selectdata, setSelectData] = useState([]);
  const [subcategory, setsubcategory] = useState([]);
  const [VendorName, setVendorname] = useState([]);
  const [VenderData, setVenderData] = useState([]);
  const [Acchead, setAcchead] = useState([])
  const [Ordernum, setOrdernum] = useState(0);
  const [myvendor, setmyvendor] = useState(0);
  const [transaction, settransaction] = useState([]);
  const [subhead, setSubHead] = useState([]);
  const [mydisc, setMyDisc] = useState([]);
  const [mybalance, setMybalance] = useState([]);
  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({});
  const today = new Date();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const date = today.getDate();
  const currentDate = year + "/" + month + "/" + date;
  const [formdata, setFormData] = useState({
    prev_balance1: "0",
    amount: "0",
    disc: "0",
    remark: "",
  });
  const [totalAmount, setTotalAmount] = useState(0);
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    const checked = event.target.checked;
    const amount = 100; // Example amount to add or subtract

    if (checked) {
      setTotalAmount(prevTotal => prevTotal + amount);
    } else {
      setTotalAmount(prevTotal => prevTotal - amount);
    }

    setIsChecked(checked);
  };

  const handleChange1 = (e) => {
    setFormData({ ...formdata, [e.target.id]: e.target.value });
    fetchData_singlePara("expenses", setData, "subhead", e.target.value, "id");
    fetch_data_with_limit(
      "expenses",
      setVenderData,
      "vendor",
      e.target.value,
      "id",
      "limit 1"
    );
    cal();
  };

  const handleChange = (e) => {
    setFormData({ ...formdata, [e.target.id]: e.target.value });
    setmyvendor(formdata.vendor_name);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formdata1 = e.target;

    const post1 = axios.post(
      "/insertdata/expenses",
      {
        purchase_id: "0",
        date: currentDate,
        invdte: formdata1.date1.value,
        userid: localStorage.getItem("uname"),
        vendor: formdata1.vendor_name.value,
        group_name: "Liabilities",
        subgroup_name: "Current Liabilities",
        head: VenderData.head,
        subhead: formdata1.vendor_name.value,
        ref: "NA",
        payment_mode: formdata1.mode.value,
        bank: "0",
        credit: formdata1.credit.value,//Payment clear money out
        tax: "0",
        disc: formdata1.disc.value,
        debit: formdata1.amount.value, //Stand by pending money
        balance: mybalance,
        remark: formdata1.remark.value,
      },
      { headers }
    );
    
    try {
      if(formdata1.amount.value !=="0")
      {
        const post2 =  axios.post("/insertdata/accounthead",
          {
            date: formdata1.date1.value,
            head_name: formdata1.acchead.value,
            credit: formdata1.credit.value,
            debit: formdata1.amount.value,
            trans_type: formdata1.mode.value,
            remark: "VND Pymt-"+myvendor+",Remark-"+formdata1.remark.value,
         
            
          },
          { headers }
        );
        await Promise.all([post1,post2]);
      }
      else{

        await Promise.all([post1]);
      }
      
      // All requests are successful
      setFormData({
        amount: "0",
        disc: "0",
        prev_balance1: "",
        remark: "",
        balance: "",
      });

      fetch_data_with_limit(
        "expenses",
        setVenderData,
        "vendor",
        myvendor,
        "id",
        "limit 1"
      );
      // Show success notification
      fetchData("expenses", setData, "id");
      toast.success("Data saved successfully!");
      //toast.error('An error occurred while making the POST requests');
      //alert("success");
    } catch (err) {
      // Handle errors
      //setError('An error occurred while making the POST requests');
      toast.error("An error occurred while making the POST requests");
      console.error("Error:", err);
    } finally {
      setLoading(false);
    }
  };

  //Table data record
  // const [data, setData] = useState([]);
  const [itemsperpage, setitemsperpage] = useState([10]);
  const [columns, setColumns] = useState([]);

  const cal = (e) => {
    let total =
      parseFloat(formdata.amount) -
      parseFloat(formdata.disc);
    //alert(formdata.prev_balance1);
    setMybalance(total);
  };
  const handleDelete = async (id) => {
    deleteRecord("expenses", id, "id");
    fetchData("expenses", setData, "id");
  };
  useEffect(() => {
    //  getOrder("expenses", setOrdernum, "prod_code");
    fetchData("vendors", setVendorname, "id");
    fetchcombolist('accounthead', setAcchead, 'head_name');
    fetchData("transactions", settransaction, "id");
  }, []);

  return (
    <>
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <Breadcums heading="Payment Voucher" paglink="payment" />
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              {/* left column */}
              <div className="col-md-12">
                {/* general form elements */}
                <div className="card card-dark">
                  {/* <div className="card-header">
                    <h3 className="card-title">Add New Payment Voucher</h3>
                  </div> */}
                  {/* /.card-header */}
                  {/* form start */}
                  <form onSubmit={handleSubmit}>
                    <div className="card-body">
                      <div className="row">
                      <div className='col-3'>
                          <div className='form-group'>
                            <label htmlFor='exampleInputEmail1'>
                             Date
                            </label>
                            <input
                              type="date"
                              required
                              className="form-control form-control-sm"
                              id="date1"
                              onChange={(e) => handleChange(e)}
                              value={formdata.date1}
                            />
                          </div>
                        </div>
                      <div className='col-3'>
                          <div className='form-group'>
                            <label htmlFor='exampleInputEmail1'>
                             Main Account Head
                            </label>
                            <select
                              className='form-control form-control-sm select2'
                              id='acchead'
                              onChange={e => handleChange(e)}
                              value={formdata.acchead}
                            >
                              <option selected='NA'>--Select--</option>
                             
                                 {Acchead &&
                                Acchead.map((seldata, index) => {
                                  return (
                                    <option value={seldata.head_name}>
                                      {seldata.head_name}
                                    </option>
                                  )
                                })}
                            </select>
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Select Vendor
                            </label>
                            <select
                              className="form-control form-control-sm select2"
                              id="vendor_name"
                              onChange={(e) => handleChange1(e)}
                              value={formdata.vendor_name}
                            >
                              <option selected="NA">--Select--</option>
                              {VendorName &&
                                VendorName.map((seldata, index) => {
                                  return (
                                    <option value={seldata.company_name}>
                                      {seldata.company_name}/{seldata.gst}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                        {/* <div className="col-3">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Closing Balance
                             
                            </label>
                            <input
                              type="text"
                              required
                              disabled
                              className="form-control form-control-sm"
                              id="prev_balance1"
                              onChange={(e) => handleChange(e)}
                              value={
                                VenderData && VenderData ? VenderData.balance : "0"
                              }
                            />
                          </div>
                        </div> */}
                        <div className="col-3">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Credit(Payable Amount)
                            </label>
                            <input
                              type="text"
                              required
                              className="form-control form-control-sm"
                              id="credit"
                              onChange={(e) => handleChange(e)}
                              onKeyUp={(e) => cal(e)}
                              value={formdata.credit}
                            />
                          </div>
                        </div>
                        </div>
                        <div className="row">
                        <div className="col-3">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Debit
                            </label>
                            <input
                              type="text"
                              required
                              className="form-control form-control-sm"
                              id="amount"
                              onChange={(e) => handleChange(e)}
                              onKeyUp={(e) => cal(e)}
                              value={formdata.amount}
                            />
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Discount</label>
                            <input
                              type="number"
                              required
                              className="form-control form-control-sm"
                              id="disc"
                              onChange={(e) => handleChange(e)}
                              onKeyUp={(e) => cal(e)}
                              value={formdata.disc}
                            />
                          </div>
                        </div>
                       
                        {/* <div className="col-3">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Balance
                           
                            </label>
                            <input
                              type="number"
                              disabled
                              className="form-control form-control-sm"
                              id="balance"
                              onChange={(e) => handleChange(e)}
                              value={mybalance}
                            />
                          </div>
                        </div> */}
                        <div className="col-3">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Payment Mode
                            </label>
                            <select
                              className='form-control form-control-sm select2'
                              id='mode'
                              onChange={e => handleChange(e)}
                              value={formdata.mode}
                            >
                             <option selected value="na">--Select--</option>
                          {transaction &&
                            transaction.map((seldata, index) => {
                              return (
                                <option value={seldata.mode}>
                                  {seldata.mode}
                                </option>
                              );
                            })}
                            </select>

                          </div>
                        </div>
                       
                        <div className="col-3">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Remark</label>
                            <input
                              type="text"
                              required
                              className="form-control form-control-sm"
                              id="remark"
                              onChange={(e) => handleChange(e)}
                              value={formdata.remark}
                            />
                          </div>
                        </div>
                        <div className="col-3">
                          <button
                            type="submit"
                            class="btn btn-dark mt-4 float-right"
                          >
                            Save Data
                          </button>
                          <ToastContainer />
                        </div>
                      </div>
                    </div>
                    {/* /.card-body */}
                  </form>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                {/* general form elements */}
                <div className="card card-dark">
                  {/* <div className="card-body table-responsive p-0">
                    <table
                      className="table table-hover text-nowrap"
                      id="tbldata"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Date</th>
                          <th>Voucher No.</th>
                          <th>Vendor</th>
                          <th>ref</th>
                          <th>Credit</th>
                          <th>Tax</th>
                          <th>Disc</th>
                          <th>Debit</th>
                          <th>Remark</th>
                        </tr>
                      </thead>
                      <tbody>
                   
                        {data &&
                          data.map((user, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <div className="icheck-primary d-inline">
                                    <input
                                      type="checkbox"
                                      id={index}
                                      checked={isChecked}
                                      onChange={handleCheckboxChange}
                                      value={user.credit}
                                    />
                                   
                                  </div>
                                </td>
                                <td>{user.date} </td>
                                <td>{
                                user.purchase_id===0
                                  ?
                                  user.id  
                                  :
                                  user.purchase_id    
                                  
                                }
                                 </td>

                                <td>{user.vendor} </td>
                                <td>{user.ref} </td>
                                <td>{user.credit} </td>
                                <td>{user.tax} </td>
                                <td>{user.disc} </td>
                                <td>{user.debit} </td>
                                <td>{user.remark} </td>

                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div> */}

                  <FetchDataComponent
                    tblname="expenses"
                    setData={setData}
                    setColumns={setColumns}
                    id="id"
                  />

                  {/* This component fetches the data and determines columns */}
                  <Pagination
                    data={data}
                    columns={columns}
                    handleDelete={handleDelete}
                    itemsPerPage={itemsperpage}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}
    </>
  );
}
